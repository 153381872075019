import * as yup from 'yup'

export const addressSchema = yup.object().shape({
    addressName: yup.string().required("Ce champ est obligatoire")
})

export const addFormContact = yup.object().shape({
    contactEmail: yup.string().required("Ce champ est obligatoire").email("Email non valide"),
    contactFname: yup.string().required("Ce champ est obligatoire"),
    contactLname: yup.string().required("Ce champ est obligatoire")
});

export const addFormCompany = yup.object().shape({
    companyAddress: yup.string().required("Ce champ est obligatoire"),
    companyEmail: yup.string().email("Email non valide").required("Ce champ est obligatoire"),
    companyName: yup.string().required("Ce champ est obligatoire"),
});

export const addSouscriptionCompany = yup.object().shape({
    // code: yup.string().notRequired().test(
    //     "invalide",
    //     "le code est invalide",
    //     function(value){
    //         return value.startsWith("B-") || value.startsWith("S-")
    //     }
    //   ),
    companyAddress: yup.string().required("Ce champ est obligatoire"),
    companyEmail: yup.string().email("Email non valide").required("Ce champ est obligatoire"),
    companyName: yup.string().required("Ce champ est obligatoire"),
    companyCity: yup.string().required("Ce champ est obligatoire")
});

export const addFormPayment = yup.object().shape({
});

export const addFormAccount = yup.object().shape({
    email: yup.string().email("Email non valide"),
    fname: yup.string().required("Ce champ est obligatoire"),
    username: yup.string().required("Ce champ est obligatoire"),
    lname: yup.string().required("Ce champ est obligatoire"),
    //dob: yup.date().required("Ce champ est obligatoire"),
    password: yup.string().required("Ce champ est obligatoire").min(6, 'Minimum six(6) caractères'),
    confirmePassword: yup.string().oneOf([yup.ref('password'), null], 'Repéter le même mot de passe').required("Ce champ est obligatoire"),
});