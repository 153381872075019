import styled from 'styled-components';

export const PricingSection = styled.div`
	padding: 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	// background-color: #fff;
`;

export const PricingWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	//background-color: #000;

	@media screen and (max-width: 480px) {
		margin: 0px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

export const PricingContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-flow: wrap;
	> div {
		margin: 2rem 0.1rem;
	}

	@media screen and (max-width: 480px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		> div {
			width: 90%;
		}
	}
`;

export const PricingCard = styled.div`
	&:hover {
		transform: scale(1.06);
		transition: all 0.3s ease-out;
	}
`;

export const PricingCardInfo = styled.div`
	//background: rgb(29, 78, 216);
	//box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
	width: 270px;
	text-decoration: none;
	border-radius: 4px;
	height: 100%;

	//display: flex;
	//flex-direction: column;
	//justify-content: flex-start;
	//padding: 20px 10px;
	color: #fff;
	margin: auto;
	> button {
		margin-top: auto;

		&:hover {
			color: black;
			background: white;
			transition: background 0.3s ease;
		}
	}

	@media screen and (max-width: 270px) {
		width: 90%;

		&:hover {
			transform: none;
		}
	}
`;

export const PricingCardPlan = styled.h3`
	margin-bottom: 5px;
	font-size: 1.5rem;
`;

export const PricingCardCost = styled.h4`
	font-size: 1.2rem;
`;

export const PricingCardText = styled.p`
	font-size: 1rem;
	margin: 0.4rem 0 1.3rem;
	color: #a9b3c1;
`;

export const PricingCardFeatures = styled.ul`
	margin: 0px 0px 10px 0px;
	list-style: none;
	display: flex;
	flex-direction: column;
	color: #f1f5f9;
`;

export const PricingCardFeature = styled.li`
	margin-bottom: 0.2rem;
	display: flex;
	font-size: 1rem;
	&:before {
		content: '✅';
		margin-right: 0.2rem;
	}
`;
