import React from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { PRICING_PAGE } from '../../navigation/Routes';
import './Home.css';

const Home = () => {

  const navigate = useNavigate()

  return (
    <div name='home' className='w-full h-screen bg-gradient-to-r from-blue-800 to-blue-700'>
      {/* Container */}
      <div className='mx-auto flex flex-col justify-center items-start h-full px-4 sm:px-40'>
        <h1 className='text-4xl text-left sm:text-7xl font-bold text-white uppercase'>
          BÃNG-WELEGRE
        </h1>
        {<h2 className='text-4xl text-left sm:text-4xl font-bold text-orange-500 uppercase'>
          Gerez votre commerce en un clic !
        </h2>}
        <p className='text-gray-50 py-4 text-justify max-w-[700px] text-lg'>
          Bãng-welegre est un logiciel de point de vente innovant,
          développé par Nafann dans le but d’améliorer et faciliter la qualité
          de gestion des activités commerciales. Bãng-welegre permet
          aux commerçants d’avoir un contrôle absolu et permanent sur leurs
          commerces et de faire des économies en temps et en argent.
        </p>
        <div>
          <button onClick={() => navigate(PRICING_PAGE)} className='text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-orange-600 hover:border-orange-600'>
            Souscrire
            {/*<span className='group-hover:rotate-90 duration-300'>
              <HiArrowNarrowRight className='ml-3 ' />
            </span>*/}

          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
