import axios from "axios";
import { TOKEN_KEY } from "../storage/constants";
import { baseUrl } from "./config";

const axiosIntance = axios.create({
  baseURL: baseUrl,
  headers: {
     Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
   }
});

export default axiosIntance;
