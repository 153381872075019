import * as yup from 'yup'

export const loginSchema = yup.object().shape({
    email: yup.string().required("Ce champ est obligatoire"),
    password: yup.string().required("Ce champ est obligatoire")
})

export const resetPSchema = yup.object().shape({
    email: yup.string().required("Ce champ est obligatoire")
})

export const newPasswordSchema = yup.object().shape({
    password: yup.string().required("Ce champ est obligatoire").min(6, 'Minimum six(6) caractères'),
    repeatPassword: yup.string().oneOf([yup.ref('password'), null], 'Repéter le même mot de passe').required("Ce champ est obligatoire"),
})

export const registerDriverSchema = yup.object().shape({
    immatricutionNumber: yup.string().required("Ce champ est obligatoire"),
    driverMotivation: yup.string().required("Ce champ est obligatoire"),
    contactName: yup.string().required("Ce champ est obligatoire"),
    contactPhone: yup.string().required("Ce champ est obligatoire"),
    userFname: yup.string().required("Ce champ est obligatoire"),
    userLname: yup.string().required("Ce champ est obligatoire"),
    userMname: yup.string().required("Ce champ est obligatoire"),
    userEmail: yup.string().required("Ce champ est obligatoire").email("Entrer un email valide"),
    userPhone: yup.string().required("Ce champ est obligatoire"),
    userPassword: yup.string().required("Ce champ est obligatoire")
})

export const registerSellerSchema = yup.object().shape({
    contactName: yup.string().required("Ce champ est obligatoire"),
    contactPhone: yup.string().required("Ce champ est obligatoire"),
    userFname: yup.string().required("Ce champ est obligatoire"),
    userLname: yup.string().required("Ce champ est obligatoire"),
    userMname: yup.string().required("Ce champ est obligatoire"),
    userEmail: yup.string().required("Ce champ est obligatoire").email("Entrer un email valide"),
    userPhone: yup.string().required("Ce champ est obligatoire"),
    userPassword: yup.string().required("Ce champ est obligatoire"),
    storeName: yup.string().required("Ce champ est obligatoire"),
    storeLocation: yup.string().required("Ce champ est obligatoire"),
    businessName: yup.string().required("Ce champ est obligatoire"),
    businessTypeId: yup.string().required("Ce champ est obligatoire"),
    subscriptionTypteId: yup.string().required("Ce champ est obligatoire")
})

export const registerClientSchema = yup.object().shape({
    userFname: yup.string().required("Ce champ est obligatoire"),
    userLname: yup.string().required("Ce champ est obligatoire"),
    userMname: yup.string().required("Ce champ est obligatoire"),
    userEmail: yup.string().required("Ce champ est obligatoire").email("Entrer un email valide"),
    userPhone: yup.string().required("Ce champ est obligatoire"),
    userPassword: yup.string().required("Ce champ est obligatoire")
})

export const otpSchema = yup.object().shape({
    phoneNumber: yup.string().required("Ce champ est obligatoire"),
    otp: yup.string().required("Ce champ est obligatoire")
})



