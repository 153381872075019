import React from 'react';
import './MyPackageScreen.css';
import Footer from '../../components/Footer/Footer';
import GlobalStyle from '../../globalStyles';
import Navbar from '../../components/NavBar/NavBar';
import Home from '../../components/Home/Home';
import MyPlan from '../../components/MyPlan/MyPlan';

export default function MyPackageScreen() {
  return (
    <>
      <GlobalStyle />
      <Navbar />
      {/*<Home />*/}
        <MyPlan />
      <Footer />
    </>
  );
};

