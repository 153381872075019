import { axiosIntance as axiosI } from "../instances"; //Need to be changed when it wll be the turn for login
import { baseUrl, fail, handleResponse, success, userService } from "../config";
import { detroyAuthValue } from "../../storage/storage";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { axiosFiles } from "../axiosFiles";

export async function addManager(manager) {
  const response = await axiosI
    .post(`${userService}/user/add-manager`, manager)
    .then((result) => {
      return handleResponse({ data: result.data, status: success });
    })
    .catch((error) => {
      console.log(error);
      return handleResponse({ data: error, status: fail });
    });
  return response.data;
}

// export async function createFirstCompany(param) {
//   console.log("FORMDATA", param);
//   // console.log("COMPANY:::", param);
//   const company = await axios
//     .post(`${baseUrl}/${userService}/company`, param, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     })
//     .then((result) => {
//       return handleResponse({ data: result.data, status: success });
//     })
//     .catch((error) => {
//       return handleResponse({ data: error, status: fail });
//     });
//   return company;
// }

export async function createFirstCompany(formData) {
  console.log("sendData", formData.get("signature"));
  const axiosInst = await axiosFiles();
  return await axiosInst({
    method: "post",
    url: `${baseUrl}/${userService}/company`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data; boundary=someArbitraryUniqueString",
      "Access-Control-Allow-Origin": "*",
    },
    transformRequest: (data) => {
      return data;
    },
  })
    .then((res) => {
      console.log("RESPONSE:::", res);
      return handleResponse({ data: res.data, status: success });
    })
    .catch((error) => {
      console.log("ERROR:::", error);
      return error;
    });
}

export async function checkParrain(code) {
  console.log("Code:::", code);
  const company = await axios
    .get(`${baseUrl}/${userService}/company/find-parrain-name/${code}`)
    .then((result) => {
      return handleResponse({ data: result.data, status: success });
    })
    .catch((error) => {
      return handleResponse({ data: error, status: fail });
    });
  return company;
}

export async function addAdmin(idCompany, admin) {
  const response = await axiosI
    .post(`${userService}/user/add-admin/${idCompany}`, admin)
    .then((result) => {
      return handleResponse({ data: result.data, status: success });
    })
    .catch((error) => {
      console.log(error);
      return handleResponse({ data: error, status: fail });
    });
  return response.data;
}

export async function blockUser(userId) {
  const response = await axiosI
    .get(`${userService}/user/block-user/${userId}`)
    .then((result) => {
      return handleResponse({ data: result.data, status: success });
    })
    .catch((error) => {
      console.log(error);
      return handleResponse({ data: error, status: fail });
    });
  return response.data;
}

export async function activateUser(userId) {
  const response = await axiosI
    .get(`${userService}/user/activate-user/${userId}`)
    .then((result) => {
      return handleResponse({ data: result.data, status: success });
    })
    .catch((error) => {
      console.log(error);
      return handleResponse({ data: error, status: fail });
    });
  return response.data;
}

/**
 * List des admin et manager dune company */

export async function listOfManagerAdminEmployees(idCompany) {
  const response = await axiosI
    .get(`${userService}/user/company-users/${idCompany}`)
    .then((result) => {
      return handleResponse({ data: result.data, status: success });
    })
    .catch((error) => {
      console.log(error);
      return handleResponse({ data: error, status: fail });
    });
  return response.data;
}

/**
 * Find an admin by id
 * @param {*} idAdmin
 * @returns
 */
export async function findAdminById(idAdmin) {
  const response = await axiosI
    .get(`${userService}/user/admin/${idAdmin}`)
    .then((result) => {
      return handleResponse({ data: result.data, status: success });
    })
    .catch((error) => {
      console.log(error);
      return handleResponse({ data: error, status: fail });
    });
  return response.data;
}
/**
 * Find a maanger by id
 * @param {*} idManager
 * @returns
 */
export async function findManagerById(idManager) {
  const response = await axiosI
    .get(`${userService}/user/manager/${idManager}`)
    .then((result) => {
      return handleResponse({ data: result.data, status: success });
    })
    .catch((error) => {
      console.log(error);
      return handleResponse({ data: error, status: fail });
    });
  return response.data;
}

export async function updateManager(idManager, manager) {
  const response = await axiosI
    .put(`${userService}/user/manager/${idManager}`, manager)
    .then((result) => {
      return handleResponse({ data: result.data, status: success });
    })
    .catch((error) => {
      console.log(error);
      return handleResponse({ data: error, status: fail });
    });
  return response.data;
}

export async function updateAdmin(idAdmin, admin) {
  const response = await axiosI
    .put(`${userService}/user/admin/${idAdmin}`, admin)
    .then((result) => {
      return handleResponse({ data: result.data, status: success });
    })
    .catch((error) => {
      console.log(error);
      return handleResponse({ data: error, status: fail });
    });
  return response.data;
}

/**
 *
 * Assign a store to the manager
 * @param {*} idStore
 * @param {*} idManager
 * @returns
 */
export async function assignStoreToManager(idStore, idManager) {
  let response = await axiosI
    .get(`${userService}/stores/assign-store-manager/${idStore}/${idManager}`)
    .then((result) => {
      return handleResponse({ data: result.data, status: success });
    })
    .catch((error) => {
      console.log(error);
      return handleResponse({ data: error, status: fail });
    });
  return response.data;
}

export async function logout() {
  let response = await axiosI
    .get(`${baseUrl}/users-ws/v1/logout`)
    .then((res) => {
      console.log("lo OUT", res);
      return handleResponse({ data: res.data, status: success });
    })
    .catch((err) => {
      return handleResponse({ data: err.data, status: fail });
    });
  return response;
}

export async function getProfil() {
  let response = await axiosI
    .get(`${baseUrl}/users-ws/v1/user/profil`)
    .then((res) => {
      return handleResponse({ data: res.data, status: success });
    })
    .catch((err) => {
      return handleResponse({ data: err.data, status: fail });
    });
  return response.data;
}

export async function updateManagerProfil(userId, values) {
  let response = await axiosI
    .put(`${userService}/user/update-manager/${userId}`, values)
    .then((res) => {
      return handleResponse({ data: res.data, status: success });
    })
    .catch((err) => {
      return handleResponse({ data: err.data, status: fail });
    });
  return response.data;
}

export async function updateAdminProfil(userId, values) {
  let response = await axiosI
    .put(`${userService}/user/update-admin/${userId}`, values)
    .then((res) => {
      return handleResponse({ data: res.data, status: success });
    })
    .catch((err) => {
      return handleResponse({ data: err.data, status: fail });
    });
  return response.data;
}

/**
 * ACTIVATE A MANAGER
 * @param {} userId
 * @param {*} storeId
 * @returns
 */
export async function activateManager(userId, storeId) {
  const response = await axiosI
    .get(`${userService}/user/${storeId}/activate-manager/${userId}`)
    .then((result) => {
      return handleResponse({ data: result.data, status: success });
    })
    .catch((error) => {
      console.log(error);
      return handleResponse({ data: error, status: fail });
    });
  return response.data;
}

export async function blockManager(userId, storeId) {
  const response = await axiosI
    .get(`${userService}/user/${storeId}/block-manager/${userId}`)
    .then((result) => {
      return handleResponse({ data: result.data, status: success });
    })
    .catch((error) => {
      console.log(error);
      return handleResponse({ data: error, status: fail });
    });
  return response.data;
}
