const TOKEN_KEY = 'token';
const REFRESH_TOKEN = 'refresh_token';
const ROLE = 'role';
const PERMISSIONS = 'permissions';
const STORE_ID = 'storeId';
const STORE_NAME = 'storeName';
const COMPANY_ID = "companyId";
const STORES = "stores";
const IS_LOGGED = "isLogged";
const EXPIRE_IN = "expireIn";
const MESSAGE_SUCCESS = "Enregistré avec succès.";
const MESSAGE_ECHOUE = "Enregistrement échoué.";
const MESSAGE_DELETE = "Supprimé avec le succès.";
const MESSAGE_DELETE_ECHOUE = "Suppression échouée.";
const MESSAGE_UPDATE_REUSSI = "Mise à jour réussi.";
const MESSAGE_UPDATE_ECHOUE = "Mise à jour échouée.";
const MESSAGE_RETIRER_SUCCESS = "Retirer avec succès.";
const MESSAGE_RETIRER_ECHOUE = "Retrait échoué.";
const AFFECTATION_SUCCESS = "Affectation réussie.";
const AFFECTATION_ECHOUEE = "Affectation échouée."
const BOUTIQUE_LICENCE_ATTEINT="Le nombre de managers est atteint selon votre licence";
const REMISE_SUCCESS="Remise Effectuée avec succès";
const REMISE_ECHOUEE="Remise échouée";


export {
    AFFECTATION_SUCCESS,
    REMISE_SUCCESS,
    REMISE_ECHOUEE,
    BOUTIQUE_LICENCE_ATTEINT,
    AFFECTATION_ECHOUEE,
    TOKEN_KEY,
    REFRESH_TOKEN,
    ROLE,
    PERMISSIONS,
    STORE_ID,
    STORE_NAME,
    COMPANY_ID,
    STORES,
    IS_LOGGED,
    EXPIRE_IN,
    MESSAGE_DELETE,
    MESSAGE_DELETE_ECHOUE,
    MESSAGE_ECHOUE,
    MESSAGE_SUCCESS, MESSAGE_UPDATE_ECHOUE,
    MESSAGE_UPDATE_REUSSI,
    MESSAGE_RETIRER_SUCCESS,
    MESSAGE_RETIRER_ECHOUE
}