import React from 'react';

import { BsFillShieldLockFill, BsPencilSquare } from 'react-icons/bs';
import { IoIosOptions, IoMdRibbon } from 'react-icons/io';
import { AiFillGift, AiFillGold, AiFillSignal, AiOutlineCloudUpload, AiOutlineTags, AiOutlineUsergroupAdd } from 'react-icons/ai';
import { BiSupport, BiDollar, BiTransfer, BiLayerMinus } from 'react-icons/bi';
import { GrHostMaintenance } from 'react-icons/gr';
import { HiUserGroup } from 'react-icons/hi';
import { GiShakingHands, GiStairsGoal, GiTakeMyMoney } from 'react-icons/gi';
import { MdOutlineGpsFixed, MdOutlineNotificationsActive, MdOutlineSell, MdQrCode2 } from 'react-icons/md';
import { TbReceiptTax, TbTruckDelivery } from 'react-icons/tb';
import { RiFilePaper2Line } from 'react-icons/ri';
import { GoPackage } from 'react-icons/go';

import { FaPercent, FaStoreAlt } from 'react-icons/fa';

import {  } from "react-icons/io";
import { ImStatsDots, ImUsers } from 'react-icons/im';

const iconStyle = (Icon) => <Icon size="3rem" color="#FD841F" />;

export const featuresData = [
	{
		name: 'Gestion des clients',
		description: 'La fonctionnalité gestion de la relation client permet aux commerçants d’avoir une base de données contenant des informations utiles relatives aux clients. Cette fonctionnalité permet également aux commerçants d’établir et de maintenir avec eux une communication personnalisée et permanente afin de les fidéliser et de générer plus de ventes.',
		icon: iconStyle(HiUserGroup),
		imgClass: 'one',
	},
	{
		name: 'Gestion des ventes',
		description: 'La fonctionnalité gestion des ventes, permet aux commerçants et aux managers de toutes activités de commerce, d’effectuer un contrôle absolu sur ses caisses, d’avoir une idée des ventes effectuées et une analyse de ses ventes pour une amélioration de ses activités.',
		icon: iconStyle(AiOutlineTags),
		imgClass: 'two',
	},
	{
		name: 'Gestions des paiements',
		description: "Cette fonctionnalité permet aux commerçants d’accepter les différents systèmes de payements nationaux et internationaux comme orange money, Moov money, master card, carte visa et le payement en espèce dans leurs boutiques. Elle intègre également d’autres innovations en termes de technique de vente. La fonctionnalité est conçue pour permettre une liberté de paiement à tous les acheteurs et faciliter donc l’activité commerciale. Cela permet aux commerçants de garder leurs clientèles peu importe les différences des moyens de paiement.",
		icon: iconStyle(BiTransfer),
		imgClass: 'three',
	},
	{
		name: 'Gestion des employées',
		description: 'La fonctionnalité gestion des employés de Bãng-welegre, permet aux propriétaires de commerce d’effectuer un contrôle permanent des performances de ses employés n’importe où ils se trouvent et de pouvoir ainsi améliorer leur gestion clientèle et faire plus de vente.',
		icon: iconStyle(ImUsers),
		imgClass: 'one',
	},
	{
		name: 'Gestions des stores',
		description: 'La fonctionnalité gestion des boutiques fait référence à la gestion de la boutique. Elle permet aux commerçants détenteur de licences de les affecter à une de leur boutique et d’avoir une vision globale de celles-ci. Elle leur permet l’ajout de store et bien d’autres actions telles que l’archivage et/ou la suppression de licence de store déjà existante. Cette fonctionnalité donne la possibilité aux clients de visualiser en long et en large sa ou ses boutiques et toutes les activités qui s’y mènent. Ils peuvent avoir un rapport des activités du store que ce soit au niveau du contenu, des ventes, des dépenses etc.',
		icon: iconStyle(FaStoreAlt),
		imgClass: 'three',
	},
	{
		name: 'Gestions des articles',
		description: 'Grâce à la fonctionnalité gestion des articles, le propriétaire et/ou le manager à la possibilité d’enregistrer, modifier, retirer et/ou archiver l’ensemble des articles qu’il a dans son espace de vente et d’avoir un suivi clair des articles enregistrés.',
		icon: iconStyle(AiFillGold),
		imgClass: 'four',
	},
	{
		name: 'Gestion des promos',
		description: 'Avec cette fonctionnalité le commerçant peut créer, lancer et suivre une promotion qui concerne uniquement certains articles ou un ensemble d’article qu’il aura définis au préalable comme faisant partie de la promotion. Il peut également suivre l’évolution de cette promotion peu importe sa position.',
		icon: iconStyle(FaPercent),
		imgClass: 'six',
	},
	{
		name: 'Gestion des giftscards',
		description: 'Cette fonctionnalité est une innovation parmi tant d’autres de cette application. Avec la gestion des gifts cards, les commerçants élaborent et mettent en place des bons d’achats de sorte à diversifier leur technique de vente et d’approche auprès de leurs clients. Elle est adaptable en fonction du montant et un suivi de leur utilisation est possible. Les commerçants pourront grâce à cette fonctionnalité, offrir et assurer une bonne expérience clients à leurs visiteurs.',
		icon: iconStyle(AiFillGift),
		imgClass: 'one',
	},
	{
		name: 'Gestion des crédits',
		description: 'La fonctionnalité des crédits permet aux managers ou aux commerçants d’ouvrir une ligne de crédit électronique pour chaque client qui prend un produit à crédit et de suivre efficacement et sans risque de se tromper sur les fréquences de paiement du produit jusqu’à épuisement du crédit.',
		icon: iconStyle(IoIosOptions),
		imgClass: 'two',
	},
	{
		name: 'Gestion des discounts',
		description: 'Cette fonctionnalité permet aux commerçants de faire des rabais sur un ensemble de produits ou un produit spécifique pour les clients qui les achètent. La fonctionnalité permet aussi de programmer des rabais automatiques en fonction d’un nombre de produits payés. Ils peuvent donc par cette fonctionnalité fidéliser les clients et faire plus de ventes par la même occasion.',
		icon: iconStyle(BiLayerMinus),
		imgClass: 'three',
	},
	{
		name: 'Gestion des fidélisations',
		description: 'La fonctionnalité gestion des fidélisations de Bãng-welegre garantie aux managers et aux commerçants une bonne prise en charge de leurs clients, un suivi permanent et un management complet de la fidélisation de ces derniers. Savoir qui est votre client, quelles sont ses préférences d’achat, quel est son rythme d’achat, comment le fidéliser.',
		icon: iconStyle(GiShakingHands),
		imgClass: 'four',
	},
	{
		name: 'Gestion des administrateurs et managers',
		description: 'La fonctionnalité gestion des administrateurs et managers donne la possibilité aux propriétaires de commerce d’avoir une vue d’ensemble sur tout ce qui est lié à la gestion de leurs activités commerciales et de leurs boutiques. Cette fonctionnalité leur donne la possibilité d’agir, de contrôler et de manager toutes leurs boutiques et leurs commerces en générale peu importe le lieu où ils se trouvent.',
		icon: iconStyle(AiOutlineUsergroupAdd),
		imgClass: 'one',
	},
	{
		name: 'Gestion des licences',
		description: 'La fonctionnalité gestion des licences donne la possibilité aux commerçants d’effectuer des ajouts de licence pour une boutique déjà inscrite ou alors de demander et de procéder à la création d’une nouvelle boutique ou d’un nouveau profil à travers la demande d’ajout de licence. Cette fonctionnalité est directement rattachée à la page web de l’administrateur.',
		icon: iconStyle(IoMdRibbon),
		imgClass: 'two',
	},
	{
		name: 'Gestions des alertes',
		description: 'Avec la fonctionnalité gestion des alertes, les commerçants pourront avoir toutes les notifications nécessaires liées aux fonctionnalités de l’application pour leur signifier toutes informations importantes liées à leurs activités commerciales peu importe le lieu où ils se trouvent.',
		icon: iconStyle(MdOutlineNotificationsActive),
		imgClass: 'three',
	},
	{
		name: 'Gestion des Daily goals',
		description: 'La fonctionnalité gestion des Daily goals est relative à la détermination d’objectifs journaliers de vente. Cette fonctionnalité permet aux commerçants d’avoir une idée du nombre d’articles à vendre par jour, en tenant compte de plusieurs détails (charges, perspectives...) pris en compte dans le logiciel, pour rentrer dans leurs frais et atteindre leurs objectifs du mois. Les commerçants savent alors combien vendre par jour ou par semaine pour assurer la rentabilité de leurs commerces. Cette fonctionnalité permet d’établir une vision à long terme de l’activité commerciale.',
		icon: iconStyle(GiStairsGoal),
		imgClass: 'one',
	},
	{
		name: 'Gestions des prévisions de vente',
		description: 'Bãng-welegre permet aux managers ou aux commerçants de faire une prévision de vente en fonction des ventes précédentes et des tendances du marché pour une bonne projection de stock et donc de ventes. Les commerçants évitent grâce à cette fonctionnalité toute surprise désagréable.',
		icon: iconStyle(ImStatsDots),
		imgClass: 'three',
	},
	{
		name: 'Gestions des taxes',
		description: 'Les commerçants ont la possibilité avec la fonctionnalité gestion des taxes, d’enregistrer les différentes taxes des articles à vendre, de connaitre dans quel régime d’imposition se trouve leurs commerces et d’avoir une idée des taxes dont ils doivent s’acquitter. Cette fonctionnalité leur permet d\'être en règle vis-à-vis de la loi et d’être des citoyens modèle mais surtout de savoir combien payer, les possibilités de paiement et quand payer les taxes au service des impôts de son pays. Nul besoin d’avoir encore une comptabilité accrue.',
		icon: iconStyle(TbReceiptTax),
		imgClass: 'four',
	},
	{
		name: 'Gestion des codes QR',
		description: 'Cette fonctionnalité permet aux commerçants de générer des codes QR qu’ils pourront utiliser pour codifier l’ensemble des articles de leur boutique pour une meilleure traçabilité.',
		icon: iconStyle(MdQrCode2),
		imgClass: 'six',
	},
	{
		name: 'Gestion des dépenses',
		description: 'Cette fonctionnalité permet aux commerçants d’avoir un œil sur leurs dépenses ponctuelles et leurs charges fixes quotidiennes ou mensuelles. Elle leur permet alors de mieux contrôler ces dernières et d’effectuer des prévisions afin d’éviter tous types de dépenses abusives et de non-sens. Elle leurs donne la possibilité de mieux manager toutes les dépenses telles que les salaires, les frais d’entretiens de la boutique liées à leurs activités commerciales.',
		icon: iconStyle(RiFilePaper2Line),
		imgClass: 'one',
	},
	{
		name: 'Gestion et approvisionnement de stocks',
		description: 'Cette fonctionnalité permet aux commerçants d’avoir la maîtrise totale de chaque article qui se trouve dans leurs boutiques ou leurs magasins et de chaque article sorti, vendu, perdu ou détruit ; ainsi que des commandes d’approvisionnement en cours. La fonctionnalité leurs donne une vue claire de tous les produits ou articles de sa boutique, des dates d’approvisionnement passées et futures afin de leurs permettre d’anticiper sur les ruptures de stocks et de continuer à satisfaire leurs clientèles.',
		icon: iconStyle(TbTruckDelivery),
		imgClass: 'two',
	},
	{
		name: 'Gestion et suggestion des méthodes de vente',
		description: 'La gestion et suggestion des méthodes de vente est une autre fonctionnalité fare de ce logiciel qui permet aux commerçants de bénéficier de l’appui conseil automatique de l’application en termes de technique de vente amélioré en fonction des analyses de leurs états de ventes. Les commerçants bénéficient grâce à cette fonctionnalité d’une assistance afin de les diriger et leurs suggérer les méthodes propices de vente et les moments propices pour l’application de chaque méthode. Tout ceci est fait sur la base des données de l’application.',
		icon: iconStyle(BsPencilSquare),
		imgClass: 'three',
	},
	{
		name: 'Assistance à la fixation des prix',
		description: 'Par cette fonctionnalité, Bãng-welegre assiste les commerçants dans la fixation des prix unitaires de leurs produits en prenant en compte les charges relatives à leur commerce et les marges bénéficiaires établies pour le développement de leur activité commerciale. Elle leur permet malgré tous les charges relatives au produit ou à l’article de fixer des prix raisonnables et marketing afin de vendre mieux et vite.',
		icon: iconStyle(MdOutlineGpsFixed),
		imgClass: 'four',
	},

	{
		name: 'Gestion des rentabilités ou du profit',
		description: 'Cette fonctionnalité de Bãng-welegre permet aux commerçants d’établir la répartition claire de leurs bénéfices ou de leurs profits en fonction de toutes leurs charges fixes et/ou variables, afin de leur permettre une meilleure gestion du bénéfice réel que génère leur activité. Ils peuvent ainsi projeter l’amélioration de leurs boutiques en toute sérénité.',
		icon: iconStyle(GiTakeMyMoney),
		imgClass: 'three',
	},
	{
		name: 'Gestion des fournisseurs',
		description: 'Cette fonctionnalité permet aux commerçants de disposer de l’ensemble des contacts de leurs fournisseurs, de les évaluer également grâce au suivi des différentes dates de livraison de leurs commandes. Ils pourront ainsi classer leurs fournisseurs et établir des collaborations fructueuses avec ceux en tête de liste.',
		icon: iconStyle(GoPackage),
		imgClass: 'four',
	},

];
