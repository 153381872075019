import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Loading from '../../components/Loading/Loading';
import PricingLocal from '../../components/Pricing/PricingLocal';
import PricingSemiCloud from '../../components/Pricing/PricingSemiCloud';
import PricingCloud from '../../components/Pricing/PricingCloud';
import { useDispatch } from 'react-redux';
import { findAllLicenseTypes } from '../../api/licenses/license';
import { success } from '../../api/config';
import { FETCH } from '../../redux/pricing/pricingActions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function PricingTab2() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [isLoading, setLoading] = React.useState(true);
    const [licenseTypes, setLicenseTypes] = React.useState([]);
    const dispatch = useDispatch()

    const listLiscenseTypes = async () => {
        setLoading(true)
        const result = await findAllLicenseTypes();
        console.log("EUREKA:::::", result);
        if (result?.status == success) {
            console.log('OK', result.data);
            //await setLicenseTypes(result.data);
            await dispatch({ type: FETCH, payload: result.data })
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    React.useEffect(() => {
        listLiscenseTypes()
    }, [])


    return (

        !isLoading ? <div className="h-auto pt-24 text-center">
            <div className="mt-4 ">
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                            <Tab label="Cloud" {...a11yProps(0)} />
                            <Tab label="Semi-cloud" {...a11yProps(1)} />
                            <Tab label="Local" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <PricingCloud />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <PricingSemiCloud />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <PricingLocal />
                    </TabPanel>
                </Box>
            </div>
        </div> : <div className="pt-20"><Loading color='blue' className='my-5' /></div>
    );
}