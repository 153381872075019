import React, { useEffect, useState } from "react";
import { set } from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import 'react-tabs/style/react-tabs.css';
import { success } from "../../api/config";
import { findAllLicenseTypes } from "../../api/licenses/license";
import Loading from "../../components/Loading/Loading";
import Pricing from "../../components/Pricing/Pricing";
import { TextWrapper } from "../../globalStyles";
import { FETCH } from "../../redux/pricing/pricingActions";
import './PricingTabScreen.css';
import PricingLocal from "../../components/Pricing/PricingLocal";
import PricingSemiCloud from "../../components/Pricing/PricingSemiCloud";
import PricingCloud from "../../components/Pricing/PricingCloud";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const PricingTabUpgradeScreen = props => {
    console.log("ACTUAL PLAN", props?.licenseCat);

    const [isLoading, setLoading] = useState(true);
    const [licenseTypes, setLicenseTypes] = useState([]);
    const licenseTypeStore = useSelector((state) => state.pricing.licenseTypes);
    const dispatch = useDispatch()
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const listLiscenseTypes = async () => {
        setLoading(true)
        const result = await findAllLicenseTypes();
        console.log("EUREKA:::::", result);
        if (result?.status == success) {
            console.log('OK', result.data);
            //setLicenseTypes(result.data);
            await dispatch({ type: FETCH, payload: result.data })
            setLoading(false);

        } else {
            setLoading(false);
        }
    }

    function setNewData() {
        console.log(props);
        licenseTypeStore?.map((item, index) => {
            //console.log('licencCat',item?.licenseCategories);
            let licencess = [];
            item?.licenseCategories.map((item, index) => {
                item.licensePrice > props.licenseCat?.licensePrice && licencess.push(item);
            })

            licenseTypes.push({ ...item, 'licenseSubscribe': licencess });
        })

        console.log("After job", licenseTypeStore);

    }
    useEffect(() => {
        setNewData()
        listLiscenseTypes()
    }, [])


    //console.log('list', licenseTypes);

    const renderTab = (item, index) => <Tab label={`${item.licenseTypeName}`} {...a11yProps(index)} />

    const renderPricing = (item) => <TabPanel>
        {console.log(item.licenseSubscribe)}
        <TextWrapper
            className="sm:px-32 px-10 flex text-left"
            mt="2rem"
            weight="300"
            size="1.2rem"
            color="rgb(68, 64, 60)"
            align="justify"
        >{item?.licenseTypeDesc}
        </TextWrapper><Pricing item={item.licenseSubscribe} nom={item.licenseTypeName} />
    </TabPanel>

    return (
        !isLoading ? <div className="h-auto text-center">
            <div className="mt-4">
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                            {
                                //licenseTypeStore.map((item, index) => renderTab(item, index))
                            }
                            <Tab label="Cloud" {...a11yProps(0)} />
                            <Tab label="Semi-cloud" {...a11yProps(1)} />
                            <Tab label="Local" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <PricingCloud resubscribe={props.isResubscription} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <PricingSemiCloud resubscribe={props.isResubscription} />
                    </TabPanel>
                    <TabPanel value={value}  index={2}>
                        <PricingLocal resubscribe={props.isResubscription} />
                    </TabPanel>
                </Box>
                {/*<Tabs selectedTabClassName="border-b-2 font-semibold">
                    <TabList className='border-b-2 text-black text-xl uppercase'>

                    </TabList>
                    {
                        licenseTypes?.map(item => renderPricing(item))
                    }
                </Tabs>*/}
            </div>
        </div> : <div className="pt-20"><Loading color='blue' className='my-5' /></div>

    )
}
export default PricingTabUpgradeScreen;