import React from 'react';
import './MyCompanyScreen.css';
import Footer from '../../components/Footer/Footer';
import GlobalStyle from '../../globalStyles';
import Navbar from '../../components/NavBar/NavBar';
import Home from '../../components/Home/Home';
import MyCompany from '../../components/MyCompany/MyCompany';
import MyProfile from '../../components/MyProfile/MyProfile';

export default function MyCompanyScreen(){
  return (
    <>
        <GlobalStyle />
        <Navbar />
        {/*<Home />*/}
        <MyProfile />
        <Footer />
    </>
  );
};

