import axios from "axios";
import { success, fail, userService, handleResponse, baseUrl, adminService } from "../config";
// const company=config.userService+

export async function findAllLicenses(){
    const license=await axios.get(`${baseUrl}/${userService}/license-category`).then((result)=>{
        return handleResponse({ data: result.data, status: success });

    }).catch((error)=>{

        return handleResponse({ data: error, status: fail });
    });
    return license;
}

// export async function updateLicense(companyId, licenceCategoryId){
//     const license=await axios.get(`${baseUrl}/${userService}/company/update-user-licence/${companyId}/licence/${licenceCategoryId}`).then((result)=>{
//         return handleResponse({ data: result.data, status: success });
//     }).catch((error)=>{
//         return handleResponse({ data: error, status: fail });
//     });
//     return license;
// }


export async function updateLicense(values){
    
    const license=await axios.post(`${baseUrl}/${adminService}/subcribe/by-om`, values).then((result)=>{
        return handleResponse({ data: result.data, status: success });
    }).catch((error)=>{
        return handleResponse({ data: error, status: fail });
    });
    return license;
}


export async function findMyLicense(id){
    const license=await axios.get(`${baseUrl}/${userService}/license-category/my-license/${id}`).then((result)=>{
        return handleResponse({ data: result.data, status: success });

    }).catch((error)=>{

        return handleResponse({ data: error, status: fail });
    });
    return license;
}

export async function findMyStores(id){
    const license=await axios.get(`${baseUrl}/${userService}/stores/company-stores/${id}`).then((result)=>{
        return handleResponse({ data: result.data, status: success });

    }).catch((error)=>{

        return handleResponse({ data: error, status: fail });
    });
    return license;
}


export async function findAllLicenseTypes(){
    const license=await axios.get(`${baseUrl}/${userService}/license-type`).then((result)=>{
        return handleResponse({ data: result.data, status: success });

    }).catch((error)=>{

        return handleResponse({ data: error, status: fail });
    });
    return license;
}

export async function findLicenceById(id){
    const company=await axios.get(`${baseUrl}/${userService}/license-category/${id}`)
    .then((result)=>{
        return handleResponse({ data: result.data, status: success });
    }).catch((error)=>{
        return handleResponse({ data: error, status: fail });
    });
    return company;
}

export async function findLicenseFunc(id){
    const license=await axios.get(`${baseUrl}/${adminService}/licence-functionality/find-by-license-category/${id}`).then((result)=>{
        return handleResponse({ data: result.data, status: success });
    }).catch((error)=>{
        return handleResponse({ data: error, status: fail });
    });
    return license;
}


