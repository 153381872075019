import React, { useState } from 'react';
import {
    FaBars,
    FaTimes,
    FaLinkedin,
    FaFacebook,
    FaYoutube,
    FaFacebookMessenger,
    FaInstagram,
    FaWhatsapp,
    FaTwitter,
    FaLinkedinIn,
} from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import Logo from '../../assets/img/logo.png';
import { Link } from 'react-scroll';
import { CONTACT_PAGE, HOME_PAGE, LOGIN_PAGE, MY_COMPANY_PAGE, MY_PACKAGE_PAGE, PRICING_PAGE, REGISTER_PAGE } from '../../navigation/Routes';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { logout } from '../../api/users/users';
import { success } from '../../api/config';
import { openUrl } from '../../helper/utils';
import { detroyAuthValue } from '../../storage/storage';
import ButtonLoading from '../ButtonLoading/ButtonLoading';

const Navbar = () => {
    const [nav, setNav] = useState(false);
    const handleClick = () => setNav(!nav);
    const login = useSelector((state) => state.login.isLogged);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    async function userLogout() {
        setLoading(true);
        let result = await logout();
        console.log(result);
        if (result?.status == success) {
            detroyAuthValue();
            setLoading(false);

            navigate(HOME_PAGE);
        }
        setLoading(false);


    }
    return (
        <div className='fixed w-full h-[80px] flex justify-between items-center bg-gradient-to-r from-blue-800 to-blue-700 text-gray-300 z-50'>
            <div>
                <img src={Logo} alt='Logo Image' style={{ width: '70px' }} />
            </div>

            {/* menu */}
            <ul className='hidden md:flex'>
                <li>
                    <NavLink
                        to={HOME_PAGE}
                        smooth={'true'}
                        duration={500}
                        className={({ isActive }) => (isActive ? ' text-orange-500 font-semibold text-lg hover:text-orange-400' : 'text-white font-semibold text-lg hover:text-orange-400')}
                    >
                        Acceuil
                    </NavLink>
                </li>
                {!login&&<li>
                    <NavLink
                        className={({ isActive }) => (isActive ? ' text-orange-500 font-semibold text-lg hover:text-orange-400' : 'text-white font-semibold text-lg hover:text-orange-400')}
                        to={PRICING_PAGE}
                        smooth={'true'}
                        duration={500}
                    >
                        Forfaits
                    </NavLink>
                </li>}
                {/*<li>
                    <NavLink
                        to={CONTACT_PAGE}
                        smooth={'true'}
                        duration={500}
                        className={({ isActive }) => (isActive ? ' text-orange-500 font-semibold text-lg hover:text-orange-400' : 'text-white font-semibold text-lg hover:text-orange-400')} 
                    >
                        Contact
                    </NavLink>
                </li>*/}
                {login && <li>
                    <NavLink
                        to={MY_COMPANY_PAGE}
                        smooth={'true'}
                        duration={500}
                        className={({ isActive }) => (isActive ? ' text-orange-500 font-semibold text-lg hover:text-orange-400' : 'text-white font-semibold text-lg hover:text-orange-400')}
                    >
                        Ma compagnie
                    </NavLink>
                </li>}
                {login && <li>
                    <NavLink
                        to={MY_PACKAGE_PAGE}
                        smooth={'true'}
                        duration={500}
                        className={({ isActive }) => (isActive ? ' text-orange-500 font-semibold text-lg hover:text-orange-400' : 'text-white font-semibold text-lg hover:text-orange-400')}
                    >
                        Mon package
                    </NavLink>
                </li>}
                {login && <li>
                    <button
                        onClick={() => userLogout()}
                        smooth={'true'}
                        duration={500}
                        className='p-2 font-semibold text-lg bg-orange-600 rounded-lg drop-shadow-md hover:animate-bounce -mt-2'
                    >
                        {!loading?<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                        </svg> : <ButtonLoading />}
                    </button>
                </li>}
                {!login && <li>
                    <NavLink
                        to={LOGIN_PAGE}
                        smooth={'true'}
                        duration={500}
                        className='text-white font-semibold text-lg bg-orange-500 px-5 py-3 rounded'
                    >
                        Se connecter
                    </NavLink>
                </li>}
                {/*<li>
                    <NavLink
                        to={REGISTER_PAGE}
                        smooth={'true'}
                        duration={500}
                        className='text-white font-semibold text-lg bg-orange-500 px-5 py-3 rounded'
                    >
                        Inscription
                    </NavLink>
                </li>*/}
            </ul>

            {/* Hamburger */}
            <div onClick={handleClick} className='md:hidden z-10 mr-4'>
                {!nav ? <FaBars /> : <FaTimes />}
            </div>

            {/* Mobile menu */}
            <ul
                className={
                    !nav
                        ? 'hidden'
                        : 'absolute top-0 left-0 w-full h-screen bg-blue-700 flex flex-col justify-center items-center'
                }
            >
                <li className='py-6 text-4xl'>
                    <NavLink
                        onClick={handleClick}
                        to={HOME_PAGE}
                        smooth={'true'}
                        duration={500}
                        className={({ isActive }) => (isActive ? ' text-orange-500 font-semibold text-lg hover:text-orange-400' : 'text-white font-semibold text-lg hover:text-orange-400')}
                    >
                        Acceuil
                    </NavLink>
                </li>
                <li className='py-6 text-4xl'>
                    {' '}
                    <NavLink
                        onClick={handleClick}
                        to={PRICING_PAGE}
                        smooth={'true'}
                        duration={500}
                        className={({ isActive }) => (isActive ? ' text-orange-500 font-semibold text-lg hover:text-orange-400' : 'text-white font-semibold text-lg hover:text-orange-400')}
                    >
                        Forfaits
                    </NavLink>
                </li>
                <li className='py-6 text-4xl'>
                    {' '}
                    <NavLink

                        onClick={handleClick}
                        to={CONTACT_PAGE}
                        smooth={'true'}
                        duration={500}
                        className={({ isActive }) => (isActive ? ' text-orange-500 font-semibold text-lg hover:text-orange-400' : 'text-white font-semibold text-lg hover:text-orange-400')}
                    >
                        Contact
                    </NavLink>
                </li>
                {login && <li className='py-6 text-4xl'>
                    {' '}
                    <NavLink
                        onClick={handleClick}
                        to={MY_COMPANY_PAGE}
                        smooth={'true'}
                        duration={500}
                        className={({ isActive }) => (isActive ? ' text-orange-500 font-semibold text-lg hover:text-orange-400' : 'text-white font-semibold text-lg hover:text-orange-400')}
                    >
                        Ma compagnie
                    </NavLink>
                </li>}
                {login && <li className='py-6 text-4xl'>
                    {' '}
                    <NavLink
                        onClick={handleClick}
                        to={MY_PACKAGE_PAGE}
                        smooth={'true'}
                        duration={500}
                        className={({ isActive }) => (isActive ? ' text-orange-500 font-semibold text-lg hover:text-orange-400' : 'text-white font-semibold text-lg hover:text-orange-400')}
                    >
                        Mon package
                    </NavLink>
                </li>}
                {!login && <li className='py-6 text-2xl'>
                    <NavLink
                        to={LOGIN_PAGE}
                        smooth={'true'}
                        duration={500}
                        className='text-white  bg-orange-500 px-5 py-3 rounded'
                    >
                        Se connecter
                    </NavLink>
                </li>}
                {login && <li className='py-6 text-2xl'>
                    <button
                        onClick={() => userLogout()}
                        smooth={'true'}
                        duration={500}
                        className='text-white  bg-orange-500 px-5 py-3 rounded'
                    >
                        Se déconnecter
                    </button>
                </li>}

                {/*<li className='py-6 text-4xl'> 
                    <NavLink
                        to={REGISTER_PAGE}
                        smooth={'true'}
                        duration={500}
                        className='text-white  bg-orange-500 px-5 py-3 rounded'
                    >
                        Inscription
                    </NavLink>
                </li>*/}
            </ul>

            {/* Social icons */}
            <div className='hidden lg:flex fixed flex-col top-[35%] left-0'>
                <ul>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-gray-100'>
                        <a
                            className='flex justify-between items-center w-full text-red-500'
                            href='https://www.youtube.com/channel/UCC2S7IYxSMjvNk1k2JY9xYg'
                            target='_blank'
                        >
                            Youtube <FaYoutube size={35} />
                        </a>
                    </li>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-white'>
                        <a
                            className='flex justify-between items-center w-full text-blue-500'
                            href='https://www.linkedin.com/company/77682570/admin'
                            target='_blank'
                        >
                            Linkedin <FaLinkedin size={30} />
                        </a>
                    </li>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-700'>
                        <a
                            className='flex justify-between items-center w-full text-gray-50'
                            href='https://www.facebook.com/Nafann.LLC'
                            target='_blank'
                        >
                            Facebook <FaFacebook size={30} />
                        </a>
                    </li>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-pink-500'>
                        <a
                            className='flex justify-between items-center w-full text-gray-50'
                            href='https://www.instagram.com/nafann.llc/'
                            target='_blank'
                        >
                            Instagram <FaInstagram size={30} />
                        </a>
                    </li>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-green-600'>
                        <a
                            className='flex justify-between items-center w-full text-gray-50'
                            href='https://wa.me/message/MX32YVF2OBVCD1'
                            target='_blank'
                        >
                            Whatsapp <FaWhatsapp size={30} />
                        </a>
                    </li>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-500'>
                        <a
                            className='flex justify-between items-center w-full text-gray-50'
                            href='https://twitter.com/NafannLLC'
                            target='_blank'
                        >
                            Twitter <FaTwitter size={30} />
                        </a>
                    </li>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-gray-600'>
                        <a
                            className='flex justify-between items-center w-full text-gray-50'
                            target='_blank'
                            href='mailto:info@nafann.com'
                        >
                            Email <BsFillPersonLinesFill size={30} />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Navbar;
