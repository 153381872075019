import axios from "axios";
import AuthInstance from "./axios";
import { REFRESH_TOKEN, TOKEN_KEY } from "../storage/constants";
import { detroyAuthValue, saveRefreshAuthValue } from "../storage/storage";
import { logoutAction } from "../redux/actions/AppAction";
import { baseUrl } from "./config";
import { store } from "../redux/store";

let headers = {};



const axiosIntance = axios.create({
  baseURL: baseUrl,
  headers: {
     Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
   }
});


// Add authorization token in each request
axiosIntance.interceptors.request.use(config => {
  if (localStorage.getItem(TOKEN_KEY)?.length != 0) {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem(TOKEN_KEY)}`
  }
  return config;
}, error => {
  return Promise.reject(error)
})


// Intercept each user request resposne
axiosIntance.interceptors.response.use(res => {
  return res
}, error => {

  const originalRequest = error.config;
  // Check if it's refresh token that failed, that mean refresh token is also expired
  console.log("url", originalRequest.url)
  if ((error?.response?.status == 401 || error?.response?.status == 500) 
  && String(originalRequest.url).includes("/users-ws/v1/refresh-token")){
    console.log("fail during refreshing")
    store.dispatch(logoutAction())
    return Promise.reject(error);
  }

  // alert("ok");
  if (error?.response.status == 401 && !originalRequest._retry){
    originalRequest._retry = true;
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    console.log("token", refreshToken)

    return axios.post(`${baseUrl}/users-ws/v1/refresh-token`, {
      refreshToken: refreshToken
    }).then(res => {
        console.log("refresh successfull")
        saveRefreshAuthValue(res.data)
        axiosIntance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(TOKEN_KEY)}`

        // resend original request  
        // alert("relunch last orginal refresh")
        return axiosIntance(originalRequest);

    }).catch(err => {
      store.dispatch(logoutAction());
      detroyAuthValue();
      
    });

  }

  return Promise.reject(error);
})

export {
  axiosIntance, // not connected people
  AuthInstance, // connected people
};
