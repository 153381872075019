import { Navigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const PrivateRoutes = () => {

    const login = useSelector((state) => state.login.isLogged);
    //const role = useSelector((state) => state.login.role);
    
    return (
        login ? <Outlet /> : <Navigate to='/login' />
    )
}
export default PrivateRoutes;