import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LoginScreen from "../LoginScreen/LoginScreen";
import SouscriptionScreen from "./../Register/SouscriptionScreen";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { HOME_PAGE } from "./../../navigation/Routes";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AuthTabScreen2() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const [idLicence, setIdLicence] = useState(searchParams.get("id"));
  const [nameLicence, setNameLicence] = useState(searchParams.get("name"));
  console.log(`ID param: ${idLicence} name ${nameLicence} `);
  console.log(
    "Licence choix",
    nameLicence == null || nameLicence.toLowerCase() == "trial"
  );
  const navigate = useNavigate();

  React.useEffect(() => {
    !nameLicence ? setValue(0) : setValue(1);
  }, [nameLicence]);

  return (
    <div className="h-screen overflow-y-scroll text-center bg-blue-800">
      <div className="flex justify-center">
        <button onClick={() => navigate(HOME_PAGE)} className="group">
          <img
            src={require("../../assets/img/logo.png")}
            alt="Logo Image"
            className="w-44"
          />
        </button>
      </div>
      <div className="h-auto text-center">
        <div className="mt-0">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                centered
              >
                <Tab
                  label="Connexion"
                  style={{ color: "white" }}
                  {...a11yProps(0)}
                />
                <Tab
                  label="Souscription"
                  style={{ color: "white" }}
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <LoginScreen />
            </TabPanel>

            <TabPanel value={value} index={1}>
              <SouscriptionScreen licence={nameLicence} id={idLicence} />
            </TabPanel>
          </Box>
        </div>
      </div>
    </div>
  );
}
