import axios from "axios";
import { saveAuthValue } from "../../storage/storage";
import { baseUrl, fail, handleResponse, success } from "../config";
import { detroyAuthValue } from "../../storage/storage";
import { useDispatch } from "react-redux";

export function concactString(string1, string2) {
  if (
    string1 != null &&
    string1 != undefined &&
    string2 != null &&
    string2 != undefined
  ) {
    return `${string1} ${string2}`;
  }

  if (string1 != undefined && string1 != undefined) return string1;
  if (string2 != undefined && string2 != undefined) return string2;

  return "";
}

// const

export async function loginRequest(data) {
  return await axios
    .post(`${baseUrl}/users-ws/v1/login-admin`, data)
    .then((res) => {
      saveAuthValue(res.data);
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function checkEmailRequest(email) {
  let response = await axios
    .get(`${baseUrl}/users-ws/v1/company/send-mail-verify/${email}`)
    .then((res) => {
      return handleResponse({ data: res.data, status: success });
    })
    .catch((err) => {
      return handleResponse({ data: err.data, status: fail });
    });
  return response;
}

export async function resetPassworRequest(data) {
    let response = await axios
      .post(`${baseUrl}/users-ws/v1/user/update-password`, data)
      .then((res) => {
        return handleResponse({ data: res.data, status: success });
      })
      .catch((err) => {
        return handleResponse({ data: err.data, status: fail });
      });
    return response;
  }

export async function checkEmailTokenRequest(data) {
  let response = await axios
    .post(`${baseUrl}/users-ws/v1/password-reset/send-token`, data)
    .then((res) => {
      return handleResponse({ data: res.data, status: success });
    })
    .catch((err) => {
      return handleResponse({ data: err.data, status: fail });
    });
  return response;
}

export async function validateOtpRequest(email, code) {
  let response = await axios
    .get(`${baseUrl}/users-ws/v1/company/verify-mail/${email}/${code}`)
    .then((res) => {
      return handleResponse({ data: res.data, status: success });
    })
    .catch((err) => {
      return handleResponse({ data: err, status: fail });
    });
  return response;
}

export async function validateTokenRequest(token) {
  let response = await axios
    .get(`${baseUrl}/users-ws/v1/password-reset/check-token/${token}`)
    .then((res) => {
      return handleResponse({ data: res.data, status: success });
    })
    .catch((err) => {
      return handleResponse({ data: err, status: fail });
    });
  return response;
}

export async function loginRequestManager(email, password) {
  return await axios
    .post(`${baseUrl}/users-ws/v1/login-manager`, {
      email: email,
      password: password,
    })
    .then((res) => {
      console.log(res.data);

      saveAuthValue(res.data);

      return true;
    })
    .catch((err) => {
      return false;
    });
}
