import React, { useEffect, useState } from "react";
import './LoginScreen.css';
import { useDispatch } from "react-redux";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import { loginSchema } from "../../validations/authValidation";
import { useFormik } from "formik";
import { success } from "../../api/config";
import { loginRequest } from "../../api/utils/utils";
import { LOGIN, RESET_PASSWORD } from "../../redux/login/loginActions";
import { openUrl, openUrlInNewTab } from "../../helper/utils";
import { HOME_PAGE, RESETPASS_PAGE } from "../../navigation/Routes";
import { useNavigate } from "react-router-dom";
import ButtonLoading from "../../components/ButtonLoading/ButtonLoading";

const LoginScreen = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [userError, setUserError] = useState("")
    const [isSuccess, setIsSuccess] = useState(false);
    const [result, setResult] = useState([]);
    const [value, setValue] = useState();
    const [user, setUser] = useState();
    const [countFail, setCountFail] = useState(0);
    const [loading, setLoading] = useState(false);
    
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: loginSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values) => {
            let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            let userInfo = values.email;
            if(userInfo.includes('@')){
                if(userInfo.match(validRegex)){
                    sendData(values);
                    setUserError('')
                } else {
                    setUserError("Votre email est invalide / Votre nom d'utilisateur contient le caractère '@'")
                }
            } else {
                console.log("VALUES")
                setUserError('')
                sendData(values)
            }
            
        }
    });

    console.log(formik.errors);

    const sendData = async (values) => {
        setIsLoading(true);
        setLoading(true);

        let result = await loginRequest(values);
        console.log("LOGIN:", result.data);
        if (Number(result.status) == 200) { 
            await dispatch({type: LOGIN, payload: result.data})
            await setIsSuccess(true);
            setTimeout(() => {
                setIsSuccess(false);
            }, 2000);
            setLoading(false);
            navigate({
                pathname: HOME_PAGE
            });
        } else {
            await setIsError(true);
            setLoading(false);
            setCountFail(prev => prev + 1);
            setTimeout(() => {
                setIsError(false);
            }, 5000);
        }
        setIsLoading(false)
        setLoading(false);

    }

    const handleResetPassword = () => {
        if(formik.values.email !== ""){
            dispatch({type: RESET_PASSWORD, payload: formik.values.email})
            openUrlInNewTab(RESETPASS_PAGE)
        }
        
    }

    /* async function getCategories() {
         const res = await axios.get('http://192.168.1.47:65004/product-ws/v1/product-category').then((data) => {
             console.log("RES:::", data.data);
             return handleResponse({data: data.data, status: success});
         }).catch((error) => {
             return handleResponse({data: error, status: fail});
         });
         return res;
     }
     async function fetchData() {
         await setResult(["888","777"])
         setTimeout(() => {
             console.log("CAT::",result);
         }, 2000);
     }
 
     useEffect(() => {
         fetchData();
     }, [])
 */
    return (
        <div className="h-full mt-10 text-center">
            <div className="relative h-auto w-[400px] mx-5 sm:p-6 p-3 rounded-lg mt-2 sm:mx-auto shadow-lg justify-center items-center bg-gray-50">
                <div>
                    <h2 className="text-3xl font-extrabold text-center text-gray-900">Connectez-vous</h2>
                </div>

                {isError && <div id="alert-2" className="flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                    <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                    <span className="sr-only">Info</span>
                    <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                        Email et/ou mot de passe incorrects
                    </div>
                    <button type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                        <span className="sr-only">Close</span>
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                </div>}

                {isSuccess && <div id="alert-3" className="flex p-4 mb-4 bg-green-100 rounded-lg dark:bg-green-200" role="alert">
                    <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-green-700 dark:text-green-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                    <span className="sr-only">Info</span>
                    <div className="ml-3 text-sm font-medium text-green-700 dark:text-green-800">
                        Connexion reussie
                    </div>
                    <button type="button" className="ml-auto -mx-1.5 -my-1.5 bg-green-100 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-green-200 dark:text-green-600 dark:hover:bg-green-300" data-dismiss-target="#alert-3" aria-label="Close">
                        <span className="sr-only">Close</span>
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                </div>}

                <form className="mt-4 space-y-6 ">
                    <div className="space-y-3 shadow-sm columns-1">
                        <div className="flex flex-col space-y-1 rounded-md">
                            <label className="px-2 font-normal text-left text-md">Email <span className="text-red-500">*</span></label>
                            <input
                                name="userEmail"
                                type="email"
                                className="relative w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="Email"
                                value={formik.values.email}
                                onChange={formik.handleChange('email')}
                            />
                            {formik?.errors?.email && <span className="ml-4 text-sm text-left text-red-500">{formik.errors.email}</span>}
                            {userError!=='' && <span className="mx-4 text-sm text-left text-red-500">{userError}</span>}
                        </div>
                        <div className="flex flex-col space-y-1 rounded-md">
                            <label className="px-2 font-normal text-left text-md">Mot de passe <span className="text-red-500">*</span></label>
                            <input
                                name="pwd"
                                type="password"
                                className="relative w-full px-3 py-3 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="Mot de passe"
                            value={formik.values.password}
                            onChange={formik.handleChange('password')}
                            />
                            {formik?.errors?.password && <span className="ml-4 text-sm text-left text-red-500">{formik.errors.password}</span>}
                        </div>

                    </div>
                    {countFail !== 0 ? <div className="flex items-center justify-end px-2">
                        {/* <div className="flex items-center">
                            <input id="remember-me" name="remember-me" type="checkbox" className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500" />
                            <label htmlFor="remember-me" className="block ml-2 text-sm text-gray-900"> Se souvenir de moi </label>
                        </div> */}

                        <div className="text-sm">
                            <button
                                onClick={() => handleResetPassword()} 
                                className="text-sm font-medium text-gray-500 hover:text-indigo-500 hover:underline">Mot de passe oublié </button>
                        </div>
                    </div> : null}

                    <div>
                        <button type="submit"
                            onClick={formik.handleSubmit}
                            disabled={countFail===3 ? true : false}
                            className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${ loading ? " bg-orange-700 hover:bg-orange-800 " : " bg-orange-600 hover:bg-orange-700 "  }focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 `}>
                            {!loading?<span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg className="w-5 h-5 text-orange-500 group-hover:text-orange-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                                </svg>
                            </span> : <ButtonLoading />}
                            Se connecter
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default LoginScreen;