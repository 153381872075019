import Loader from "react-spinners/CircleLoader";

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

export default ({message = "", color='white'}) => (
    <div align="center" className="mx-3 my-1">
        {message && <h3>{message}</h3>}
        <Loader color={color} loading={true} css={override} size={10} />
    </div>
)