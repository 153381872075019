import React, { useEffect, useState } from "react";
import "./LoginScreen.css";
import { useDispatch, useSelector } from "react-redux";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import {
  loginSchema,
  newPasswordSchema,
  resetPSchema,
} from "../../validations/authValidation";
import { useFormik } from "formik";
import { baseUrlEmail, success } from "../../api/config";
import {
  checkEmailRequest,
  checkEmailTokenRequest,
  loginRequest,
  resetPassworRequest,
  validateTokenRequest,
} from "../../api/utils/utils";
import { LOGIN, MAIL_VERIF, NO_MAIL_VERIF } from "../../redux/login/loginActions";
import { openUrl } from "../../helper/utils";
import { HOME_PAGE, LOGIN_PAGE } from "../../navigation/Routes";
import { useNavigate, useSearchParams } from "react-router-dom";
import ButtonLoading from "../../components/ButtonLoading/ButtonLoading";
import { Alert } from "@mui/material";
import Swal from "sweetalert2";

const ResetpasswordScreen = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [userError, setUserError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [result, setResult] = useState([]);
  const [value, setValue] = useState();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [tokenValidate, setTokenValidate] = useState();
  const userEmail = useSelector((state) => state?.login.emailToReset);
  const [searchParams, setSearchParams] = useSearchParams();
  const [token, setToken] = useState(searchParams.get("token"));
  console.log("Email to reset", userEmail);
  console.log("My-token", token);
  const navigate = useNavigate();
  const stepMail = useSelector((state) => state.login.mailVerify);

  useEffect(() => {
    validateToken();
  }, [token]);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: resetPSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      sendData(values);
    },
  });

  const formikPassword = useFormik({
    initialValues: {
      password: "",
      repeatPassword: "",
    },
    validationSchema: newPasswordSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      sendDataNewPassword(values);
    },
  });

  console.log(formik.errors);

  const sendData = async (values) => {
    setIsSuccess(false);

    let result = await checkEmailRequest(values);

    if (true) {
      setIsSuccess(true);
    } else {
      Swal.fire({
        icon: "error",
        title: "Echec de la vérification",
        text: `Cet email n'existe pas.`,
      });
    }
  };

  const validateEmailByToken = async () => {
    setLoading(true);
    setIsSuccess(false);
    // console.log("Path", window.location.href);
    let val = { email: userEmail, path: baseUrlEmail };
    console.log("Final-->", val);
    let result = await checkEmailTokenRequest(val);

    if (result?.status == success) {
      setTimeout(() => {
        setLoading(false);
        setIsSuccess(true);
      }, 2000);
    } else {
      Swal.fire({
        icon: "error",
        title: "Echec de la vérification",
        text: `Cet email n'existe pas.`,
      });
    }
  };

  const validateToken = async () => {
    setTokenValidate(false);
    console.log("Token", token);
    if (token) {
      dispatch({ type: MAIL_VERIF });
      console.log("Final-->", token);
      let result = await validateTokenRequest(token);
      console.log("verify-token", result)
      if (result?.status == success) {
        setTokenValidate(true);
      } else {
        setTokenValidate(false);
      }
    }
  };

  const sendDataNewPassword = async (values) => {
    setLoading(true);
    let val = {
      password: values?.password,
      username: userEmail
    }
    let result = await resetPassworRequest(val);

    if (result?.status === success) {
      Swal.fire({
        icon: "success",
        title: "Votre mot de passe a été modifiée avec succès.",
        text: `Mise à jour réussie.`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          dispatch({type: NO_MAIL_VERIF})
          window.location.replace(LOGIN_PAGE)
        } 
      })
    } else {
      Swal.fire({
        icon: "error",
        title: "Echec de la vérification",
        text: `Cet email n'existe pas.`,
      });
    }
  };

  return (
    <div className="flex items-center justify-center h-screen text-center bg-blue-800">
      {!stepMail ? (
        <div className="h-auto w-[500px] mx-5 sm:p-6 p-3 rounded-lg mt-2 sm:mx-auto shadow-lg justify-center items-center bg-gray-50">
          <div>
            <h2 className="text-3xl font-bold text-center text-orange-600">
              Réinitialiser le mot de passe
            </h2>
          </div>

          {/* {isError && <div id="alert-2" className="flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                    <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                    <span className="sr-only">Info</span>
                    <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                        Email et/ou mot de passe incorrects
                    </div>
                    <button type="button" className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" data-dismiss-target="#alert-2" aria-label="Close">
                        <span className="sr-only">Close</span>
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                </div>}

                {isSuccess && <div id="alert-3" className="flex p-4 mb-4 bg-green-100 rounded-lg dark:bg-green-200" role="alert">
                    <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-green-700 dark:text-green-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                    <span className="sr-only">Info</span>
                    <div className="ml-3 text-sm font-medium text-green-700 dark:text-green-800">
                        Connexion reussie
                    </div>
                    <button type="button" className="ml-auto -mx-1.5 -my-1.5 bg-green-100 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-green-200 dark:text-green-600 dark:hover:bg-green-300" data-dismiss-target="#alert-3" aria-label="Close">
                        <span className="sr-only">Close</span>
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                </div>} */}

          <div className="mt-4 space-y-6 ">
            <Alert severity="warning">
              Les champs avec la mention (*) sont obligatoires
            </Alert>
            <div className="space-y-3 shadow-sm columns-1">
              <div className="flex flex-col space-y-1 rounded-md">
                <label className="px-2 font-normal text-left text-md">
                  Email <span className="text-red-500">*</span>
                </label>
                <input
                  name="userEmail"
                  type="email"
                  disabled={true}
                  className="relative w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none disabled:placeholder-gray-700 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email"
                  value={userEmail}
                  // onChange={formik.handleChange("email")}
                />
                {/* {formik?.errors?.email && (
                  <span className="ml-4 text-sm text-left text-red-500">
                    {formik.errors.email}
                  </span>
                )} */}
              </div>
            </div>
            {isSuccess ? (
              <button
                className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
                  loading
                    ? " bg-blue-700 hover:bg-blue-800 "
                    : " bg-blue-600 hover:bg-blue-700 "
                }focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 `}
                onClick={() => validateEmailByToken()}
              >
                {!loading ? <span>Renvoyer le mail</span> : <ButtonLoading />}
              </button>
            ) : null}
            {!isSuccess ? (
              <div>
                <button
                  onClick={() => validateEmailByToken()}
                  disabled={isSuccess}
                  className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
                    loading
                      ? " bg-blue-700 hover:bg-blue-800 "
                      : " bg-blue-600 hover:bg-blue-700 "
                  }focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 `}
                >
                  {!loading ? (
                    <span>Vérifier votre adresse électronique</span>
                  ) : (
                    <ButtonLoading />
                  )}
                </button>
              </div>
            ) : null}
          </div>

          {isSuccess ? (
            <div>
              <div className="flex flex-col items-center justify-center">
                <img
                  width={150}
                  src={require("../../assets/gif/58201-success-tick.gif")}
                  alt={"Verification reussie"}
                />
                <h4 className="px-5 font-semibold text-gray-600">
                  L'email a été envoyé avec succès, veuillez vérifier votre
                  boite mail.
                </h4>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="h-auto w-[510px] mx-5 sm:p-6 p-3 rounded-lg mt-2 sm:mx-auto shadow-lg justify-center items-center bg-gray-50">
          {!tokenValidate ? (
            <div>
              <div className="flex flex-col items-center justify-center">
                <p className="px-5 text-3xl font-extrabold text-gray-500">
                  Le token est déjà expiré !
                </p>
                <img
                  width={200}
                  src={require("../../assets/gif/90569-error.gif")}
                  alt={"Verification reussie"}
                />
              </div>
            </div>
          ) : (
            <div>
              <h2 className="text-3xl font-bold text-center text-orange-600">
                Nouveau mot de passe
              </h2>
              <div className="px-5 my-3 space-y-6">
                <Alert severity="warning">
                  Les champs avec la mention (*) sont obligatoires
                </Alert>
                <div className="space-y-3 shadow-sm columns-1">
                  <div className="flex flex-col space-y-1 rounded-md">
                    <label className="px-2 font-normal text-left text-md">
                      Mot de passe <span className="text-red-500">*</span>
                    </label>
                    <input
                      name="pwd"
                      type="password"
                      disabled={loading}
                      className="relative w-full px-3 py-3 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none disabled:bg-emerald-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="Mot de passe"
                      value={formikPassword.values.password}
                      onChange={formikPassword.handleChange("password")}
                    />
                    {formik?.errors?.password && (
                      <span className="ml-4 text-sm text-left text-red-500">
                        {formikPassword.errors.password}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col space-y-1 rounded-md">
                    <label className="px-2 font-normal text-left text-md">
                      Répéter le mot de passe{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      name="pwd"
                      type="password"
                      disabled={loading}
                      className="relative w-full px-3 py-3 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none disabled:bg-emerald-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="Répéter le mot de passe"
                      value={formikPassword.values.repeatPassword}
                      onChange={formikPassword.handleChange("repeatPassword")}
                    />
                    {formikPassword?.errors?.repeatPassword && (
                      <span className="ml-4 text-sm text-left text-red-500">
                        {formikPassword.errors.repeatPassword}
                      </span>
                    )}
                  </div>
                </div>

                <div>
                  <button
                    onClick={() => formikPassword.handleSubmit()}
                    className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
                      loading
                        ? " bg-blue-700 hover:bg-blue-800 "
                        : " bg-blue-600 hover:bg-blue-700 "
                    }focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 `}
                  >
                    {!loading ? <span>Enrégistrer</span> : <ButtonLoading />}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ResetpasswordScreen;
