import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";
import options from "../../assets/json/countries.json";
import "./RegisterScreen.css";
import PhoneInput from "react-phone-number-input";
import { HOME_PAGE, REGISTER_PAGE } from "../../navigation/Routes";
import { Country, State, City } from "country-state-city";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { checkParrain, createFirstCompany } from "../../api/users/users";
import { fail, success } from "../../api/config";
import { useFormik } from "formik";
import {
  addAccount,
  addCompany,
  addFormAccount,
  addFormCompany,
  addFormContact,
  addFormPayment,
  addSouscriptionCompany,
} from "../../validations/userValidation";
import OtpInput from "react-otp-input";
import CreditCardInput from "react-credit-card-input";
import Card from "react-credit-cards";
import PaymentForm from "../../components/CreditCard/CreditCard";
import "react-credit-cards/es/styles-compiled.css";
import {
  checkEmailRequest,
  checkOtpRequest,
  loginRequest,
} from "../../api/utils/utils";
import { useDispatch } from "react-redux";
import { LOGIN } from "../../redux/login/loginActions";
import { formatDate, openUrl, openUrlInNewTab } from "../../helper/utils";
import { useEffect } from "react";
import { findLicenceById } from "../../api/licenses/license";
import { set } from "react-ga";
import ButtonLoading from "../../components/ButtonLoading/ButtonLoading";
import AppTermsConditions from "../../assets/docs/Bang nwelgé app terms and conditions.pdf";
// import SignaturePad from "react-signature-canvas";
import SignatureCanvas from "react-signature-canvas";
import DropdownFlag from "../../components/DropdownFlag/DropdownFlag";
import {
  Alert,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  StepContent,
} from "@mui/material";
import Swal from "sweetalert2";
import { resetPSchema } from "../../validations/authValidation";
import moment from "moment/moment";

const steps = [
  "Informations de l'entreprise",
  "Informations du compte administration",
  "Choix du materiel",
  "Contrat de la souscription",
  "Paiement de la souscription",
];
const stepsCloud = [
  "Informations de l'entreprise",
  "Informations du compte administration",
  "Contrat de la souscription",
  "Paiement de la souscription",
];

const SouscriptionScreen = (props) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const location = useLocation();
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);
  const [step5, setStep5] = useState(false);
  const [codeOtp, setCodeOtp] = useState("");
  const [stepOtp, setStepOtp] = useState(false);
  const [pwd, setPwd] = useState("");
  const [repeatPwd, setRepeatPwd] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [checkOk, setCheckOk] = useState(false);
  const [cities, setCities] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [active, setActive] = useState("OM");
  const [telUser, setTelUser] = useState("");
  const [telCompany, setTelCompany] = useState("");
  const [telWCompany, setTelWCompany] = useState("");
  const [telContact, setTelContact] = useState("");
  const [telOtp, setTelOtp] = useState("");
  const [licenceId, setLicenceId] = useState(searchParams.get("id"));
  const [typeLic, setTypeLic] = useState(searchParams.get("type"));
  const [countryCompany, setCountryCompany] = useState("");
  const [cityCompany, setCityCompany] = useState("");
  const [emailAccount, setEmailAccount] = useState("");
  const [isSame, setIsSame] = useState(true);
  const [licence, setLicence] = useState();
  const [loading, setLoading] = useState(false);
  const [contrat, setContrat] = useState(false);
  const [disabledVal, setDisabledVal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [codeAff, setCodeAff] = useState("");
  const [codeAffCode, setCodeAffCode] = useState("B-");
  const [n_days, setN_days] = useState("");
  const [codeAffErr, setCodeAffErr] = useState(false);
  const [m_sous, setM_sous] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectAddon, setSelectAddon] = useState("E-ordinateur");
  const [total, setTotal] = useState();

  const sigCanvas = useRef();
  const sigCanvasMobil = useRef();
  const [openModel, setOpenModal] = useState(false);
  const [penColor, setPenColor] = useState("black");
  const colors = ["black", "blue"];
  const [imageURL, setImageURL] = useState(null);

  const create = () => {
    if (!sigCanvas.current.isEmpty()) {
      setDisabledVal(false);
      const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
      console.log("Signature", URL);
      setImageURL(URL);
      setOpenModal(false);
    } else {
      console.log("OK")
      setDisabledVal(true);
    }
  };

  const createMobil = () => {
    if (!sigCanvasMobil.current.isEmpty()) {
      setDisabledVal(false);
      const URL = sigCanvasMobil.current.getTrimmedCanvas().toDataURL("image/png");
      console.log("Signature", URL);
      setImageURL(URL);
      setOpenModal(false);
    } else {
      console.log("OK")
      setDisabledVal(true);
    }
  };

  const download = () => {
    const dlink = document.createElement("a");
    dlink.setAttribute("href", imageURL);
    dlink.setAttribute("download", "signature.png");
    dlink.click();
  };
  // const sigPad = useRef();

  // const create = () => {
  //   const URL = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
  //   setTrimmedDataURL(URL);
  // };

  const dispatch = useDispatch();

  const handleChange = (otp) => {
    setCodeOtp(otp);
    console.log(otp);
  };
  console.log("ID param", searchParams.get("id"));

  function addDays(days) {
    var date = new Date();
    date.setDate(date.getMonth() + days);
    // date.setDate(date.getDate() + 10);
    console.log(date);
    return date;
  }

  async function fetch(id) {
    let rs = await findLicenceById(id);
    console.log("Licence", rs);
    if (rs.status == success) {
      await setLicence(rs?.data);
      if (typeLic !== "cloud") {
        await setTotal(Number(rs?.data?.licensePrice) + Number("8335"));
      } else {
        await setTotal(Number(rs?.data?.licensePrice));
      }
      handleChangeDate(rs?.data?.validityInDays);
    }
  }

  function handleChangeDate(Ndays) {
    setN_days(Ndays);
    const inputDate = new Date();
    // setStartDate(formatDate(date));
    // setEndDate(formatDate(addDays(Ndays)));
    // console.log(startDate + " - " + endDate);
    let day1, month1, year1;
    day1 = inputDate.getDate();
    month1 = inputDate.getMonth() + 1;
    year1 = inputDate.getFullYear();
    var startdate = `${day1}.${month1}.${year1}`;
    var new_date = moment(startdate, "DD-MM-YYYY").add("days", Ndays);

    var day = new_date.format("DD");
    var month = new_date.format("MM");
    var year = new_date.format("YYYY");

    // alert(day + "." + month + "." + year);
    setStartDate(quickFormat(day1, month1, year1));
    setEndDate(`${day}/${month}/${year}`);
  }
  function quickFormat(d, m, y) {
    return `${d < 9 ? "0" + d : d}/${m < 9 ? "0" + m : m}/${y}`;
  }
  function calculMontant(nD) {
    let n_m = nD % 30;
    return;
  }

  useEffect(() => {
    fetch(searchParams.get("id"));
  }, []);

  function handlePaymentMethod(choose) {
    setActive(choose);
    console.log(active);
  }

  const formikAccount = useFormik({
    initialValues: {
      //dob: "",
      email: "",
      username: "",
      checked: true,
      fname: "",
      lname: "",
      password: "",
      confirmePassword: "",
      licenceCategoryId: 0,
    },
    validationSchema: addFormAccount,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      console.log("VALUES 1", values);
      if (telUser) {
        setActiveStep((prevStep) => prevStep + 1);
      }
    },
  });

  /*const formikContact = useFormik({
    initialValues: {
      contactEmail: "",
      contactFname: "",
      contactLname: "",
      contactMname: "",
    },
    validationSchema: addFormContact,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      console.log("VALUES 3", values);
      if (telContact) {
        handleSteps('4')
      }
    }
  });*/

  const formikCompany = useFormik({
    initialValues: {
      companyAddress: "",
      companyEmail: "",
      companyIfu: "",
      companyCity: "",
      companyName: "",
      // code: "",
    },
    validationSchema: addSouscriptionCompany,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      console.log("VALUES 2", values);

      if (countryCompany && telCompany && !codeAffErr) {
        setActiveStep((prevStep) => prevStep + 1);
      }
    },
  });

  const formikEmail = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: resetPSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      sendDataEmail(values);
    },
  });

  const sendDataEmail = async () => {
    // setCheckOk(false);

    let result = await checkEmailRequest();
    console.log(result);
    if (true) {
      // setCheckOk(true);

      Swal.fire({
        icon: "success",
        title: "Vérification réussie",
        text: `Mail confirmé.`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          setActiveStep((prevStep) => prevStep + 1);
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Echec de la vérification",
        text: `Cet otp n'est pas valide.`,
      });
    }
  };

  // async function checkCodeAff() {
  //   if (!codeAff) {
  //     setCodeAffErr(false);
  //   } else if (
  //     (codeAff.startsWith("B") || codeAff.startsWith("S")) &&
  //     codeAff[1] === "-" &&
  //     codeAff.length === 8
  //   ) {
  //     setCodeAffErr(false);
  //   } else {
  //     setCodeAffErr(true);
  //   }
  // }

  // useEffect(() => {
  //   checkCodeAff();
  // }, [codeAff]);

  async function handleCodeAff() {
    console.log(codeAff);
    if (!codeAff) {
      setCodeAffErr(false);
    } else if (Number(codeAff) && codeAff?.length === 6) {
      setCodeAffErr(false);

      let result = await checkParrain(`${codeAffCode}${codeAff}`);
      console.log("Check", result);
      if (result?.status === success) {
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: `Vous avez utilisé le code d'affiliation de ${result?.data}`,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Erreur",
          text: `Ce code d'affiliation n'existe pas.`,
        });
      } //implemente maybe useEffect to make one request
    } else {
      setCodeAffErr(true);
    }
  }

  useEffect(() => {
    handleCodeAff();
  }, [codeAff]);

  const formikPayment = useFormik({
    initialValues: {},
    validationSchema: addFormPayment,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      console.log("VALUES 4", values);
      if (licence?.licenseCategoryName?.toLowerCase() !== "trial") {
        if (codeOtp && telOtp) {
          sendData(values);
        }
      } else {
        sendData(values);
      }
    },
  });

  console.log("Account form", formikAccount.errors);
  //console.log("Contact form", formikContact.errors);
  console.log("Company form", formikCompany.errors);
  console.log("Payment form", formikPayment.errors);

  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(
      b64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
    );
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  function handleChangeMontant(e) {
    setSelectAddon(e);
    if (e !== "E-ordinateur") {
      setTotal(Number(licence?.licensePrice) + Number("4585"));
    } else {
      setTotal(Number(licence?.licensePrice) + Number("8335"));
    }
  }
  const sendData = async (values) => {
    setIsLoading(true);
    // console.log("CODE-->",codeAff);
    let val = {
      ...formikAccount.values,
      email: formikAccount.values.checked
        ? formikCompany.values.companyEmail
        : formikAccount.values.email,
      ...formikCompany.values,
      //...formikContact.values,
      contactPhone: telContact,
      companyPhoneNumber: telCompany,
      companyWhatsappNumber: telWCompany,
      companyCountry: countryCompany.label,
      // companyCity: cityCompany.label,
      phone: telUser,
      licenceCategoryId: licenceId,
      phoneNumber: telOtp,
      otp: codeOtp,
      code: codeAff && codeAffCode ? `${codeAffCode}${codeAff}` : null,
      addonId: selectAddon !== "E-ordinateur" ? 4 : 1,
    };
    setIsLoading(true);
    console.log("FINAL VALUES:", val);

    var bodyFormData = new FormData();

    bodyFormData.append("company", JSON.stringify(val));

    // const base64 = imageURL; // Place your base64 url here.
    // fetch(base64)
    //   .then((res) => res.blob())
    //   .then((blob) => {
    //     var file = new File([blob], "signature.jpeg");
    //     bodyFormData.append("signature", file);
    //   });
    const blob = b64toBlob(imageURL);
    var file = new File([blob], "signature.jpg");
    console.log("IMAGE", file);
    bodyFormData.append("signature", file);

    let result = await createFirstCompany(bodyFormData);
    console.log("REGISTER:", result?.response?.status === 409);

    if (result?.status == success) {
      // setIsSuccess(true);
      setIsLoading(false);
      Swal.fire({
        icon: "success",
        title: "Félicitation",
        text: "Opération réussie !",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          setActiveStep((prevStep) => prevStep + 1);
        }
      });
      //   // let res = await loginRequest({
      //   //   email: formikAccount.values?.email,
      //   //   password: formikAccount?.values?.password,
      //   // });
      //   // console.log("LOGIN:", res);
      //   // if (Number(res.status) == 200) {
      //   //   await dispatch({ type: LOGIN, payload: res.data });
      //   //   setLoading(false);
      //   //   openUrl(HOME_PAGE);
      //   // } else {
      //   //   await setIsError(true);
      //   //   setLoading(false);
      //   // }
      //   // setLoading(false);
    } else if (result?.response?.status === 409) {
      //modifier pour le code 419
      Swal.fire({
        icon: "error",
        title: "Erreur lors de la création du compte.",
        text: "Cet email est déjà utilisé pour une souscription.",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          setIsLoading(false);
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Erreur lors de la souscription",
        text: "L'opération a échoué, veuillez ré-essayer !",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          setIsLoading(false);
        }
      });
    }

    setIsLoading(false);
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleHome = () => {
    window.location.replace(HOME_PAGE);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function saveSignature() {
    setActiveStep((prevStep) => prevStep + 1);
  }

  function saveMateriel() {
    setActiveStep((prevStep) => prevStep + 1);
  }

  async function handleContrat() {
    window.open(AppTermsConditions);
  }

  return (
    <div className="h-screen pt-5 pb-5 bg-blue-800">
      <div className=" relative h-auto md:flex hidden sm:w-[100vh] md:w-[70vw] sm:p-6 p-4 rounded-lg mt-2 mx-auto shadow-lg justify-center items-center bg-white">
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {searchParams.get("type") === "cloud"
              ? stepsCloud.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  // if (isStepOptional(index)) {
                  //   labelProps.optional = (
                  //     <Typography variant="caption">Optional</Typography>
                  //   );
                  // }
                  // if (isStepSkipped(index)) {
                  //   stepProps.completed = false;
                  // }

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })
              : steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  // if (isStepOptional(index)) {
                  //   labelProps.optional = (
                  //     <Typography variant="caption">Optional</Typography>
                  //   );
                  // }
                  // if (isStepSkipped(index)) {
                  //   stepProps.completed = false;
                  // }

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
          </Stepper>

          {activeStep === steps.length ? (
            <React.Fragment>
              <h4 className="mt-10 text-center text-gray-600">
                Félicitations vous venez de souscrire à la licence de
                Bangwelegre avec succès !
              </h4>
              <div className="flex items-center justify-center">
                <img
                  width={350}
                  src={require("../../assets/gif/55867-congratulation.gif")}
                  alt="Congratulations"
                />
              </div>
              <a
                href="#"
                onClick={() => openUrlInNewTab("https://admin.bangwelegre.com")}
                className="text-center text-blue-400 underline"
              >
                Connectez vous à votre boutique maintenant.
              </a>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={() => handleHome()}>
                  Retourner à l'accueil
                </Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {searchParams.get("type") !== "cloud" ? (
                <div>
                  {activeStep === 0 ? (
                    <div className="px-10 pt-5 md:px-3">
                      <Alert severity="warning">
                        Les champs avec la mention (*) sont obligatoires
                      </Alert>
                      <div className="grid gap-3 space-y-3 md:grid-cols-2 xl:grid-cols-3 md:space-y-2 ">
                        {/* <div className="flex flex-col mt-3 space-y-1 rounded-md">
                      <label className="px-2 font-normal text-left text-md">
                        Code de parainnage
                      </label>
                      <input
                        name=""
                        type="text"
                        className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="code"
                        value={codeAff}
                        onChange={(e) => setCodeAff(e.target.value)}
                      />
                      {codeAffErr ? (
                        <span className="ml-4 text-sm text-left text-red-500">
                          Ce code est invalide
                        </span>
                      ) : null}
                    </div> */}

                        <div className="flex flex-col mt-3 space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Code d'affiliation
                          </label>
                          <div className="flex items-center border border-gray-300 rounded-lg jus">
                            <select
                              className="w-12 font-bold text-gray-500 appearance-none focus:apparence-none"
                              onChange={(e) => setCodeAffCode(e.target.value)}
                            >
                              <option
                                className="text-center"
                                value={"B-"}
                                defaultValue
                              >
                                B
                              </option>
                              <option className="text-center" value={"S-"}>
                                S
                              </option>
                            </select>
                            <input
                              name=""
                              type="text"
                              className="w-full px-3 py-2 font-semibold tracking-widest text-gray-500 appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              placeholder="Six (06) chiffres"
                              value={codeAff}
                              onChange={(e) => setCodeAff(e.target.value)}
                            />
                          </div>

                          {codeAffErr ? (
                            <span className="ml-4 text-sm text-left text-red-500">
                              Ce champ doit contenir six (06) chiffres
                            </span>
                          ) : null}
                        </div>

                        <div className="space-y-2 ">
                          <div className="flex flex-col w-full h-auto space-y-1 rounded-md">
                            <label className="px-2 font-normal text-left text-md">
                              Pays <span className="text-red-500">*</span>
                            </label>
                            <DropdownFlag
                              isSearchable
                              placeHolder="Pays ..."
                              options={options}
                              onChange={(value) => setCountryCompany(value)}
                            />
                            {!countryCompany ? (
                              <p className="px-2 text-sm text-left text-red-500">
                                Ce champ est obligatoire
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Ville <span className="text-red-500">*</span>
                          </label>
                          <input
                            name="companyCity"
                            type="text"
                            className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Ville"
                            value={formikCompany.values.companyCity}
                            onChange={formikCompany.handleChange("companyCity")}
                          />
                          {formikCompany?.errors?.companyCity && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              {formikCompany.errors.companyCity}
                            </span>
                          )}
                        </div>
                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Nom de l'entreprise{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <input
                            name="companyName"
                            type="text"
                            className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Nom"
                            value={formikCompany.values.companyName}
                            onChange={formikCompany.handleChange("companyName")}
                          />
                          {formikCompany?.errors?.companyName && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              {formikCompany.errors.companyName}
                            </span>
                          )}
                        </div>

                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Email <span className="text-red-500">*</span>
                          </label>
                          <input
                            name="companyEmail"
                            type="email"
                            className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Email"
                            value={formikCompany.values.companyEmail}
                            onChange={formikCompany.handleChange(
                              "companyEmail"
                            )}
                          />
                          {formikCompany?.errors?.companyEmail && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              {formikCompany.errors.companyEmail}
                            </span>
                          )}
                        </div>

                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Téléphone <span className="text-red-500">*</span>
                          </label>
                          <PhoneInput
                            className="w-full px-2 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Téléphone"
                            value={telCompany}
                            onChange={(e) => setTelCompany(e)}
                          />
                          {!telCompany && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              Ce champ est obligatoire
                            </span>
                          )}
                        </div>

                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Whatsapp
                          </label>
                          <PhoneInput
                            className="w-full px-2 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Whatsapp"
                            value={telWCompany}
                            onChange={(e) => setTelWCompany(e)}
                          />
                          {/*!telWCompany && <span className="ml-4 text-sm text-left text-red-500">Ce champ est obligatoire</span>*/}
                        </div>

                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Adresse <span className="text-red-500">*</span>
                          </label>
                          <input
                            name=""
                            type="text"
                            className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Adresse"
                            value={formikCompany.values.companyAddress}
                            onChange={formikCompany.handleChange(
                              "companyAddress"
                            )}
                          />
                          {formikCompany?.errors?.companyAddress && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              {formikCompany.errors.companyAddress}
                            </span>
                          )}
                        </div>

                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            IFU
                          </label>
                          <input
                            name=""
                            type="text"
                            className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="IFU"
                            value={formikCompany.values.companyIfu}
                            onChange={formikCompany.handleChange("companyIfu")}
                          />
                          {formikCompany?.errors?.companyIfu && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              {formikCompany.errors.companyIfu}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : activeStep === 1 ? (
                    <div className="px-10 pt-5">
                      <Alert severity="warning">
                        Les champs avec la mention (*) sont obligatoires
                      </Alert>
                      <div className="grid gap-3 space-y-3 md:grid-cols-2 xl:grid-cols-3">
                        <div className="flex flex-col mt-3 space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Nom <span className="text-red-500">*</span>
                          </label>
                          <input
                            name="lname"
                            type="text"
                            className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Nom"
                            value={formikAccount.values.lname}
                            onChange={formikAccount.handleChange("lname")}
                          />
                          {formikAccount?.errors?.lname && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              {formikAccount.errors.lname}
                            </span>
                          )}
                        </div>

                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Prénom <span className="text-red-500">*</span>
                          </label>
                          <input
                            name="fname"
                            type="text"
                            className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Prénom"
                            value={formikAccount.values.fname}
                            onChange={formikAccount.handleChange("fname")}
                          />
                          {formikAccount?.errors?.fname && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              {formikAccount.errors.fname}
                            </span>
                          )}
                        </div>

                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Nom d'utilisateur{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <input
                            name="username"
                            type="text"
                            className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Nom d'utilisateur"
                            value={formikAccount.values.username}
                            onChange={formikAccount.handleChange("username")}
                          />
                          {formikAccount?.errors?.username && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              {formikAccount.errors.username}
                            </span>
                          )}
                        </div>

                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Téléphone <span className="text-red-500">*</span>
                          </label>
                          <PhoneInput
                            className="w-full px-2 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Téléphone"
                            value={telUser}
                            onChange={(e) => setTelUser(e)}
                          />
                          {!telUser && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              Ce champ est obligatoire
                            </span>
                          )}
                        </div>

                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Mot de passe <span className="text-red-500">*</span>
                          </label>
                          <input
                            name="password"
                            type="password"
                            className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Mot de passe"
                            value={formikAccount.values.password}
                            onChange={formikAccount.handleChange("password")}
                          />
                          {formikAccount?.errors?.password && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              {formikAccount.errors.password}
                            </span>
                          )}
                        </div>

                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Répéter le mot de passe{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <input
                            name="repeatPwd"
                            type="password"
                            className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Répéter le mot de passe"
                            value={formikAccount.values.confirmePassword}
                            onChange={formikAccount.handleChange(
                              "confirmePassword"
                            )}
                          />
                          {formikAccount?.errors?.confirmePassword && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              {formikAccount.errors.confirmePassword}
                            </span>
                          )}
                        </div>

                        <div className="px-2 pt-5 space-y-2 text-left">
                          <input
                            checked={formikAccount.values.checked}
                            onChange={() =>
                              formikAccount.setFieldValue(
                                "checked",
                                !formikAccount.values.checked
                              )
                            }
                            id="checked-checkbox"
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            for="checked-checkbox"
                            className="px-2 font-normal text-left text-md"
                          >
                            Utilisez l'adresse éléctronique de l'entreprise
                          </label>
                        </div>

                        {!formikAccount.values.checked && (
                          <div className="space-y-2 shadow-sm columns-1">
                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Email <span className="text-red-500">*</span>
                              </label>
                              <input
                                name="userEmail"
                                type="email"
                                className="relative w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="Email"
                                value={formikAccount.values.email}
                                onChange={formikAccount.handleChange("email")}
                              />
                              {formikAccount?.errors?.email && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  {formikAccount.errors.email}
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : activeStep === 2 ? (
                    <div className="px-10 pt-5">
                      <Alert severity="warning">
                        Les champs avec la mention (*) sont obligatoires
                      </Alert>
                      <div className="flex flex-col items-center mt-5">
                        {/* <div className="flex flex-wrap items-baseline justify-center p-2 space-x-4 space-y-2">
                      <button
                        onClick={() => handlePaymentMethod("OM")}
                        className={`${
                          active == "OM"
                            ? "border-orange-700 border-4 "
                            : "border-gray-200 "
                        } "w-40 h-28 group flex flex-col space-x-1 justify-center items-center border-2  p-2 cursor-pointer hover:bg-gray-200 rounded-md"`}
                      >
                        <img
                          className="w-28 h-14"
                          src={require("../../assets/img/orange-money.png")}
                          alt="Orange money"
                        />
                        <p className="group-hover:font-semibold group-hover:underline">
                          Orange money
                        </p>
                      </button>
                      <button
                        onClick={() => handlePaymentMethod("OM")}
                        className={`${
                          active == "OM"
                            ? "border-orange-700 border-4 "
                            : "border-gray-200 "
                        } "w-40 h-28 group flex flex-col space-x-1 justify-center items-center border-2  p-2 cursor-pointer hover:bg-gray-200 rounded-md"`}
                      >
                        <img
                          className="w-28 h-14"
                          src={require("../../assets/img/orange-money.png")}
                          alt="Orange money"
                        />
                        <p className="group-hover:font-semibold group-hover:underline">
                          Orange money
                        </p>
                      </button>
                    </div> */}
                        <FormControl>
                          {/* <FormLabel id="demo-row-radio-buttons-group-label">
                        Choisissez le materiel qui vous convient
                      </FormLabel> */}
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(e) =>
                              handleChangeMontant(e.target.value)
                            }
                          >
                            <FormControlLabel
                              value="E-ordinateur"
                              control={
                                <Radio
                                  checked={selectAddon === "E-ordinateur"}
                                />
                              }
                              label="E-ordinateur"
                            />
                            <FormControlLabel
                              value="E-telephone"
                              control={
                                <Radio
                                  checked={selectAddon === "E-telephone"}
                                />
                              }
                              label="E-téléphone"
                            />
                          </RadioGroup>
                        </FormControl>
                        <div className="space-y-3">
                          {/* <p>
                        <span>Routeur</span>
                        <span>prix</span>
                        <span>Offert</span>
                      </p>
                      <p>
                        <span>Serveur</span>
                        <span>prix</span>
                        <span>Offert</span>
                      </p> */}
                          <p className="text-lg font-semibold text-gray-700">
                            Resumé de la souscription
                          </p>
                          <div className="flex flex-row items-center justify-center px-10 py-4 space-x-5 bg-white border-2 border-blue-800 rounded">
                            <p>
                              <span className="text-lg font-extrabold text-gray-700 capitalize">
                                {typeLic} - {licence?.licenseCategoryName}
                              </span>
                            </p>
                            <p className="">
                              {/* <span className="text-sm text-gray-600 uppercase">
                           Prix :
                         </span>{" "} */}
                              <span className="font-bold text-gray-600">
                                {licence?.licensePrice} F CFA
                              </span>
                            </p>
                          </div>
                          {selectAddon === "E-ordinateur" ? (
                            <div className="flex flex-row items-center justify-center px-10 py-4 space-x-5 bg-white border-2 border-blue-800 rounded">
                              <div className="border-gray-100 b-2">
                                <img
                                  width={150}
                                  height={150}
                                  className="rounded"
                                  src={require("../../assets/img/e-ordi.jpg")}
                                  alt="photo-eordi"
                                />
                              </div>
                              <div className="space-y-2 text-left">
                                <p className="text-lg font-extrabold text-gray-700 capitalize">
                                  E-ordinateur
                                </p>
                                <p className="text-sm text-gray-600">
                                  Description appareil
                                </p>
                                <p className="">
                                  <span className="text-sm text-gray-600 uppercase">
                                    Prix :
                                  </span>{" "}
                                  <span className="font-bold text-gray-600">
                                    8335 F CFA
                                  </span>
                                </p>
                              </div>
                            </div>
                          ) : null}

                          {selectAddon === "E-telephone" ? (
                            <div className="flex flex-row items-center justify-center px-10 py-4 space-x-5 bg-white border-2 border-blue-800 rounded">
                              <div className="border-gray-100 b-2">
                                <img
                                  width={150}
                                  height={150}
                                  className="rounded"
                                  src={require("../../assets/img/e-tel.jpg")}
                                  alt="photo-eordi"
                                />
                              </div>
                              <div className="space-y-2 text-left">
                                <p className="text-lg font-extrabold text-gray-700 capitalize">
                                  E-téléphone
                                </p>
                                <p className="text-sm text-gray-600">
                                  Description appareil
                                </p>
                                <p className="">
                                  <span className="text-sm text-gray-600 uppercase">
                                    Prix :
                                  </span>{" "}
                                  <span className="font-bold text-gray-600">
                                    4585 F CFA
                                  </span>
                                </p>
                              </div>
                            </div>
                          ) : null}

                          <div className="flex flex-row items-center justify-center px-10 py-4 space-x-5 text-white bg-orange-500 rounded">
                            <p className="text-lg font-extrabold uppercase">
                              Total
                            </p>
                            <p className="">
                              {/* <span className="text-sm text-gray-600 uppercase">
                           Prix :
                         </span>{" "} */}
                              <span className="text-lg font-extrabold">
                                {total} F CFA
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : activeStep === 3 ? (
                    <div className="px-10 pt-5">
                      <Alert severity="warning">
                        Les champs avec la mention (*) sont obligatoires
                      </Alert>
                      <div className="mt-5">
                        <p className="text-left text-gray-700 indent-10">
                          Je soussigné(e), Monsieur / Madame{" "}
                          <span className="font-semibold capitalize">{`${formikAccount.values.lname} ${formikAccount.values.fname}`}</span>{" "}
                          confirme ma souscription pour la licence{" "}
                          <span className="font-semibold">
                            {licence?.licenseCategoryName}
                          </span>{" "}
                          dans la catégorie{" "}
                          <span className="font-semibold capitalize">
                            {typeLic}
                          </span>{" "}
                          pour un montant{" "}
                          <span className="font-semibold">{total} F CFA</span>{" "}
                          pour une{" "}
                          {licence?.validityInDays !== -1 ? (
                            <span>
                              période allant du{" "}
                              <span className="font-semibold">{startDate}</span>{" "}
                              au{" "}
                              <span className="font-semibold">{endDate}</span> .{" "}
                            </span>
                          ) : (
                            <span className="font-semibold">
                              durée illimitée .{" "}
                            </span>
                          )}
                          {/* <span className="space-x-1 text-sm">
                        <label
                          htmlFor="select-duree"
                          className="text-sm font-semibold text-gray-500 hover:text-blue-500 "
                        >
                          Changer la durée :
                        </label>
                        <select
                          id="select-duree"
                          name="select-duree"
                          className="w-auto p-1 font-bold text-gray-500 appearance-none focus:apparence-none"
                          onChange={(e) => handleChangeDate(e.target.value)}
                        >
                          <option
                            className="text-sm font-semibold text-gray-500 hover:text-blue-500"
                            value={30}
                            defaultValue
                          >
                            un (01) mois
                          </option>
                          <option
                            className="text-sm font-semibold text-gray-500 hover:text-blue-500"
                            value={90}
                          >
                            trois (03) mois
                          </option>
                          <option
                            className="text-sm font-semibold text-gray-500 hover:text-blue-500"
                            value={180}
                          >
                            six (06) mois
                          </option>
                          <option
                            className="text-sm font-semibold text-gray-500 hover:text-blue-500"
                            value={360}
                          >
                            un (01) an
                          </option>
                        </select>
                      </span> */}
                        </p>
                      </div>
                      <div className="mt-5">
                        <p className="text-left text-gray-700">
                          En cliquant cette case vous indiquez que vous avez lu
                          et accepter{" "}
                          <a
                            href={"#"}
                            onClick={() => handleContrat()}
                            className="text-blue-500 underline apparence-none"
                          >
                            les termes et conditions du contrat
                          </a>
                          .
                        </p>
                      </div>
                      <div className="mt-5">
                        <p className="text-left text-gray-700">
                          Cliquez ici pour voir le{" "}
                          <a
                            href="#"
                            onClick={() => handleContrat()}
                            className="text-blue-500 underline apparence-none"
                          >
                            contrat
                          </a>
                          .
                        </p>
                      </div>
                      <div className="px-10 mt-5 space-y-2 text-left">
                        <input
                          checked={contrat}
                          onChange={() => {
                            setContrat(!contrat);
                            contrat === false
                              ? setOpenModal(true)
                              : setImageURL(null);
                          }}
                          id="checked-checkbox"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for="checked-checkbox"
                          className="px-2 font-normal text-left text-gray-700 text-md"
                        >
                          J'ai lu et j'accepte
                        </label>
                      </div>
                      <div className="flex flex-col items-center justify-center">
                        <br />
                        <div>
                          <p className="font-semibold underline">Signature</p>
                          {/* <button
                        className="flex items-center mt-2 space-x-2 rounded-lg"
                        onClick={() => sigCanvas.current.clear()}
                      >
                        <span className="text-gray-700">Effacer</span>
                      </button> */}
                        </div>

                        {imageURL && (
                          <>
                            <img
                              src={imageURL}
                              alt="signature"
                              className="signature"
                            />
                          </>
                        )}
                      </div>

                      <div className="pad">
                        {/* <button onClick={() => setOpenModal(true)}>Signer</button>
                  <br /> */}

                        {openModel && (
                          <div className="modalContainer">
                            <div className="modal">
                              <h4 className="text-2xl font-semibold text-center">
                                Signature
                              </h4>
                              {disabledVal && (
                                <p className="text-center text-red-500">
                                  Veuillez signer le contrat svp pour passer
                                  cette étape
                                </p>
                              )}
                              <div className="sigPad__penColors">
                                <p>Couleurs :</p>
                                {colors.map((color) => (
                                  <span
                                    key={color}
                                    style={{
                                      backgroundColor: color,
                                      border: `${
                                        color === penColor
                                          ? `2px solid ${color}`
                                          : ""
                                      }`,
                                    }}
                                    onClick={() => setPenColor(color)}
                                  ></span>
                                ))}
                              </div>
                              <div className="sigPadContainer">
                                <SignatureCanvas
                                  penColor={penColor}
                                  canvasProps={{ className: "sigCanvas" }}
                                  ref={sigCanvas}
                                />
                              </div>

                              <button
                                className="flex items-center mt-2 space-x-2 rounded-lg"
                                onClick={() => sigCanvas.current.clear()}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-4 h-4 text-gray-700"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z"
                                  />
                                </svg>
                                <span className="text-gray-700">Effacer</span>
                              </button>
                              <div className="flex justify-end space-x-4 modal__bottom">
                                <button
                                  className="border border-gray-500 rounded-lg"
                                  onClick={() => {
                                    imageURL
                                      ? setOpenModal(false)
                                      : setContrat(false);
                                    setOpenModal(false);
                                  }}
                                >
                                  Annuler
                                </button>
                                <br />
                                {/* <button
                            onClick={download}
                            style={{ padding: "5px", marginTop: "5px" }}
                          >
                            Télécharger
                          </button> */}
                                <button
                                  className={`" bg-orange-600 border border-orange-700 "} rounded-lg text-white`}
                                  onClick={create}
                                >
                                  <span className="px-2">Valider</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : activeStep === 4 ? (
                    licence?.licenseCategoryName?.toLowerCase() !== "trial" ? (
                      <div className="p-20 pt-5">
                        <Alert severity="warning">
                          Les champs avec la mention (*) sont obligatoires
                        </Alert>
                        <div className="flex flex-col items-center justify-center w-full">
                          <div className="space-y-4 w-[70vw] mt-10 flex flex-col justify-center items-center">
                            <div className="flex flex-wrap items-baseline justify-center p-2 space-x-4 space-y-2">
                              <button
                                onClick={() => handlePaymentMethod("OM")}
                                className={`${
                                  active == "OM"
                                    ? "border-orange-700 border-4 "
                                    : "border-gray-200 "
                                } "w-40 h-28 group flex flex-col space-x-1 justify-center items-center border-2  p-2 cursor-pointer hover:bg-gray-200 rounded-md"`}
                              >
                                <img
                                  className="w-28 h-14"
                                  src={require("../../assets/img/orange-money.png")}
                                  alt="Orange money"
                                />
                                <p className="group-hover:font-semibold group-hover:underline">
                                  Orange money
                                </p>
                              </button>
                              <button
                                //onClick={() => handlePaymentMethod('MV')}
                                className={`${
                                  active == "MV"
                                    ? "border-orange-700 border-4 "
                                    : "border-gray-200 bg-gray-300 "
                                } "w-40 h-28 group flex flex-col space-x-1 justify-center items-center border-2 border-gray-200 p-2 cursor-not-allowed hover:bg-gray-200 rounded-md"`}
                              >
                                <img
                                  className="w-28 h-14 brightness-75"
                                  src={require("../../assets/img/moov-money.png")}
                                  alt="Orange money"
                                />
                                <p className="">Indisponible</p>
                              </button>
                              <button
                                //onClick={() => handlePaymentMethod('CARTE')}
                                className={`${
                                  active == "CARTE"
                                    ? "border-orange-700 border-4 "
                                    : "border-gray-200 bg-gray-300 "
                                }"w-40 h-28 group flex flex-col space-x-1 justify-center items-center border-2 border-gray-200 p-2 cursor-not-allowed hover:bg-gray-200 rounded-md"`}
                              >
                                <img
                                  className="w-28 h-14 brightness-75"
                                  src={require("../../assets/img/mastercard-visa.png")}
                                  alt="Orange money"
                                />
                                <p className="">Indisponible</p>
                              </button>
                            </div>
                            <div className="flex flex-col items-center justify-center w-full h-auto p-2 ">
                              {(active == "OM" || active == "MV") && (
                                <div className="space-y-3 shadow-sm columns-1 md:space-y-2 sm:columns-2 md:columns-1 lg:columns-1 xl:columns-1 2xl:columns-1">
                                  {licence ? (
                                    <h5 className="text-sm">
                                      Veuillez composer
                                      <span className="font-semibold">
                                        *144*4*6*{total}#
                                      </span>
                                      pour générer le OTP
                                    </h5>
                                  ) : null}
                                  <div className="flex flex-col space-y-1 rounded-md">
                                    <label className="px-2 font-normal text-left text-md">
                                      Téléphone{" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <PhoneInput
                                      className="relative w-full px-2 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                      placeholder="Téléphone"
                                      value={telOtp}
                                      onChange={(e) => setTelOtp(e)}
                                    />
                                    {!telOtp && (
                                      <span className="ml-4 text-sm text-left text-red-500">
                                        Ce champ est obligatoire
                                      </span>
                                    )}
                                  </div>

                                  <div className="flex flex-col space-y-1">
                                    <label className="px-2 font-normal text-left text-md">
                                      Code OTP{" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <OtpInput
                                      value={codeOtp}
                                      onChange={(e) => handleChange(e)}
                                      numInputs={6}
                                      separator={<span>-</span>}
                                      shouldAutoFocus
                                      isInputNum={true}
                                      isInputSecure={true}
                                      inputStyle="inputStyle"
                                    />
                                    {!codeOtp && (
                                      <span className="ml-4 text-sm text-left text-red-500">
                                        Ce champ est obligatoire
                                      </span>
                                    )}
                                  </div>
                                </div>
                              )}

                              {active == "CARTE" && (
                                <div className="space-y-3 shadow-sm columns-1 md:space-y-2 sm:columns-2 md:columns-1 lg:columns-1 xl:columns-1 2xl:columns-1">
                                  <PaymentForm />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="text-center">
                        <h1 className="m-20 font-extrabold text-7xl">
                          Gratuit
                        </h1>
                      </div>
                    )
                  ) : null}
                </div>
              ) : (
                <div>
                  {activeStep === 0 ? (
                    <div className="px-10 pt-5">
                      <Alert severity="warning">
                        Les champs avec la mention (*) sont obligatoires
                      </Alert>
                      <div className="grid gap-3 space-y-3 md:grid-cols-2 xl:grid-cols-3 ">
                        {/* <div className="flex flex-col mt-3 space-y-1 rounded-md">
                      <label className="px-2 font-normal text-left text-md">
                        Code de parainnage
                      </label>
                      <input
                        name=""
                        type="text"
                        className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="code"
                        value={codeAff}
                        onChange={(e) => setCodeAff(e.target.value)}
                      />
                      {codeAffErr ? (
                        <span className="ml-4 text-sm text-left text-red-500">
                          Ce code est invalide
                        </span>
                      ) : null}
                    </div> */}

                        <div className="flex flex-col mt-3 space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Code d'affiliation
                          </label>
                          <div className="flex items-center border border-gray-300 rounded-lg jus">
                            <select
                              className="w-12 font-bold text-gray-500 appearance-none focus:apparence-none"
                              onChange={(e) => setCodeAffCode(e.target.value)}
                            >
                              <option
                                className="text-center"
                                value={"B-"}
                                defaultValue
                              >
                                B
                              </option>
                              <option className="text-center" value={"S-"}>
                                S
                              </option>
                            </select>
                            <input
                              name=""
                              type="text"
                              className="w-full px-3 py-2 font-semibold tracking-widest text-gray-500 appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              placeholder="Six (06) chiffres"
                              value={codeAff}
                              onChange={(e) => setCodeAff(e.target.value)}
                            />
                          </div>

                          {codeAffErr ? (
                            <span className="ml-4 text-sm text-left text-red-500">
                              Ce champ doit contenir six (06) chiffres
                            </span>
                          ) : null}
                        </div>

                        <div className="space-y-2 ">
                          <div className="flex flex-col w-full h-auto space-y-1 rounded-md">
                            <label className="px-2 font-normal text-left text-md">
                              Pays <span className="text-red-500">*</span>
                            </label>
                            <DropdownFlag
                              isSearchable
                              placeHolder="Pays ..."
                              options={options}
                              onChange={(value) => setCountryCompany(value)}
                            />
                            {!countryCompany ? (
                              <p className="px-2 text-sm text-left text-red-500">
                                Ce champ est obligatoire
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Ville <span className="text-red-500">*</span>
                          </label>
                          <input
                            name="companyCity"
                            type="text"
                            className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Ville"
                            value={formikCompany.values.companyCity}
                            onChange={formikCompany.handleChange("companyCity")}
                          />
                          {formikCompany?.errors?.companyCity && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              {formikCompany.errors.companyCity}
                            </span>
                          )}
                        </div>
                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Nom de l'entreprise{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <input
                            name="companyName"
                            type="text"
                            className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Nom"
                            value={formikCompany.values.companyName}
                            onChange={formikCompany.handleChange("companyName")}
                          />
                          {formikCompany?.errors?.companyName && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              {formikCompany.errors.companyName}
                            </span>
                          )}
                        </div>

                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Email <span className="text-red-500">*</span>
                          </label>
                          <input
                            name="companyEmail"
                            type="email"
                            className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Email"
                            value={formikCompany.values.companyEmail}
                            onChange={formikCompany.handleChange(
                              "companyEmail"
                            )}
                          />
                          {formikCompany?.errors?.companyEmail && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              {formikCompany.errors.companyEmail}
                            </span>
                          )}
                        </div>

                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Téléphone <span className="text-red-500">*</span>
                          </label>
                          <PhoneInput
                            className="w-full px-2 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Téléphone"
                            value={telCompany}
                            onChange={(e) => setTelCompany(e)}
                          />
                          {!telCompany && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              Ce champ est obligatoire
                            </span>
                          )}
                        </div>

                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Whatsapp
                          </label>
                          <PhoneInput
                            className="w-full px-2 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Whatsapp"
                            value={telWCompany}
                            onChange={(e) => setTelWCompany(e)}
                          />
                          {/*!telWCompany && <span className="ml-4 text-sm text-left text-red-500">Ce champ est obligatoire</span>*/}
                        </div>

                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Adresse <span className="text-red-500">*</span>
                          </label>
                          <input
                            name=""
                            type="text"
                            className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Adresse"
                            value={formikCompany.values.companyAddress}
                            onChange={formikCompany.handleChange(
                              "companyAddress"
                            )}
                          />
                          {formikCompany?.errors?.companyAddress && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              {formikCompany.errors.companyAddress}
                            </span>
                          )}
                        </div>

                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            IFU
                          </label>
                          <input
                            name=""
                            type="text"
                            className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="IFU"
                            value={formikCompany.values.companyIfu}
                            onChange={formikCompany.handleChange("companyIfu")}
                          />
                          {formikCompany?.errors?.companyIfu && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              {formikCompany.errors.companyIfu}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : activeStep === 1 ? (
                    <div className="px-10 pt-5">
                      <Alert severity="warning">
                        Les champs avec la mention (*) sont obligatoires
                      </Alert>
                      <div className="grid gap-3 space-y-3 md:grid-cols-2 xl:grid-cols-3">
                        <div className="flex flex-col mt-3 space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Nom <span className="text-red-500">*</span>
                          </label>
                          <input
                            name="lname"
                            type="text"
                            className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Nom"
                            value={formikAccount.values.lname}
                            onChange={formikAccount.handleChange("lname")}
                          />
                          {formikAccount?.errors?.lname && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              {formikAccount.errors.lname}
                            </span>
                          )}
                        </div>

                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Prénom <span className="text-red-500">*</span>
                          </label>
                          <input
                            name="fname"
                            type="text"
                            className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Prénom"
                            value={formikAccount.values.fname}
                            onChange={formikAccount.handleChange("fname")}
                          />
                          {formikAccount?.errors?.fname && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              {formikAccount.errors.fname}
                            </span>
                          )}
                        </div>

                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Nom d'utilisateur{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <input
                            name="username"
                            type="text"
                            className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Nom d'utilisateur"
                            value={formikAccount.values.username}
                            onChange={formikAccount.handleChange("username")}
                          />
                          {formikAccount?.errors?.username && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              {formikAccount.errors.username}
                            </span>
                          )}
                        </div>

                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Téléphone <span className="text-red-500">*</span>
                          </label>
                          <PhoneInput
                            className="w-full px-2 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Téléphone"
                            value={telUser}
                            onChange={(e) => setTelUser(e)}
                          />
                          {!telUser && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              Ce champ est obligatoire
                            </span>
                          )}
                        </div>

                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Mot de passe <span className="text-red-500">*</span>
                          </label>
                          <input
                            name="password"
                            type="password"
                            className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Mot de passe"
                            value={formikAccount.values.password}
                            onChange={formikAccount.handleChange("password")}
                          />
                          {formikAccount?.errors?.password && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              {formikAccount.errors.password}
                            </span>
                          )}
                        </div>

                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="px-2 font-normal text-left text-md">
                            Répéter le mot de passe{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <input
                            name="repeatPwd"
                            type="password"
                            className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Répéter le mot de passe"
                            value={formikAccount.values.confirmePassword}
                            onChange={formikAccount.handleChange(
                              "confirmePassword"
                            )}
                          />
                          {formikAccount?.errors?.confirmePassword && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              {formikAccount.errors.confirmePassword}
                            </span>
                          )}
                        </div>

                        <div className="px-2 pt-5 space-y-2 text-left">
                          <input
                            checked={formikAccount.values.checked}
                            onChange={() =>
                              formikAccount.setFieldValue(
                                "checked",
                                !formikAccount.values.checked
                              )
                            }
                            id="checked-checkbox"
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            for="checked-checkbox"
                            className="px-2 font-normal text-left text-md"
                          >
                            Utilisez l'adresse éléctronique de l'entreprise
                          </label>
                        </div>

                        {!formikAccount.values.checked && (
                          <div className="space-y-2 shadow-sm columns-1">
                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Email <span className="text-red-500">*</span>
                              </label>
                              <input
                                name="userEmail"
                                type="email"
                                className="relative w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="Email"
                                value={formikAccount.values.email}
                                onChange={formikAccount.handleChange("email")}
                              />
                              {formikAccount?.errors?.email && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  {formikAccount.errors.email}
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : activeStep === 2 ? (
                    <div className="px-10 pt-5">
                      <Alert severity="warning">
                        Les champs avec la mention (*) sont obligatoires
                      </Alert>
                      <div className="mt-5">
                        <p className="text-left text-gray-700 indent-10">
                          Je soussigné(e), Monsieur / Madame{" "}
                          <span className="font-semibold capitalize">{`${formikAccount.values.lname} ${formikAccount.values.fname}`}</span>{" "}
                          confirme ma souscription pour la licence{" "}
                          <span className="font-semibold">
                            {licence?.licenseCategoryName}
                          </span>{" "}
                          dans la categorie{" "}
                          <span className="font-semibold capitalize">
                            {typeLic}
                          </span>{" "}
                          pour un montant{" "}
                          <span className="font-semibold">
                            {licence?.licensePrice}
                          </span>{" "}
                          FCFA pour une{" "}
                          {licence?.validityInDays !== -1 ? (
                            <span>
                              période allant du{" "}
                              <span className="font-semibold">{startDate}</span>{" "}
                              au{" "}
                              <span className="font-semibold">{endDate}</span> .{" "}
                            </span>
                          ) : (
                            <span className="font-semibold">
                              durée illimitée .{" "}
                            </span>
                          )}
                          {/* <span className="space-x-1 text-sm">
                        <label
                          htmlFor="select-duree"
                          className="text-sm font-semibold text-gray-500 hover:text-blue-500 "
                        >
                          Changer la durée :
                        </label>
                        <select
                          id="select-duree"
                          name="select-duree"
                          className="w-auto p-1 font-bold text-gray-500 appearance-none focus:apparence-none"
                          onChange={(e) => handleChangeDate(e.target.value)}
                        >
                          <option
                            className="text-sm font-semibold text-gray-500 hover:text-blue-500"
                            value={30}
                            defaultValue
                          >
                            un (01) mois
                          </option>
                          <option
                            className="text-sm font-semibold text-gray-500 hover:text-blue-500"
                            value={90}
                          >
                            trois (03) mois
                          </option>
                          <option
                            className="text-sm font-semibold text-gray-500 hover:text-blue-500"
                            value={180}
                          >
                            six (06) mois
                          </option>
                          <option
                            className="text-sm font-semibold text-gray-500 hover:text-blue-500"
                            value={360}
                          >
                            un (01) an
                          </option>
                        </select>
                      </span> */}
                        </p>
                      </div>
                      <div className="mt-5">
                        <p className="text-left text-gray-700">
                          En cliquant cette case vous indiquez que vous avez lu
                          et accepter{" "}
                          <a
                            href={"#"}
                            onClick={() => handleContrat()}
                            className="text-blue-500 underline apparence-none"
                          >
                            les termes et conditions du contrat
                          </a>
                          .
                        </p>
                      </div>
                      <div className="mt-5">
                        <p className="text-left text-gray-700">
                          Cliquez ici pour voir le{" "}
                          <a
                            href="#"
                            onClick={() => handleContrat()}
                            className="text-blue-500 underline apparence-none"
                          >
                            contrat
                          </a>
                          .
                        </p>
                      </div>
                      <div className="px-10 mt-5 space-y-2 text-left">
                        <input
                          checked={contrat}
                          onChange={() => {
                            setContrat(!contrat);
                            contrat === false
                              ? setOpenModal(true)
                              : setImageURL(null);
                          }}
                          id="checked-checkbox"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for="checked-checkbox"
                          className="px-2 font-normal text-left text-gray-700 text-md"
                        >
                          J'ai lu et j'accepte
                        </label>
                      </div>
                      <div className="flex flex-col items-center justify-center">
                        <br />
                        <div>
                          <p className="font-semibold underline">Signature</p>
                          {/* <button
                        className="flex items-center mt-2 space-x-2 rounded-lg"
                        onClick={() => sigCanvas.current.clear()}
                      >
                        <span className="text-gray-700">Effacer</span>
                      </button> */}
                        </div>

                        {imageURL && (
                          <>
                            <img
                              src={imageURL}
                              alt="signature"
                              className="signature"
                            />
                          </>
                        )}
                      </div>

                      <div className="pad">
                        {/* <button onClick={() => setOpenModal(true)}>Signer</button>
                  <br /> */}

                        {openModel && (
                          <div className="modalContainer">
                            <div className="modal">
                              <h4 className="text-2xl font-semibold text-center">
                                Signature
                              </h4>
                              {disabledVal && (
                                <p className="text-center text-red-500">
                                  Veuillez signer le contrat svp pour passer
                                  cette étape
                                </p>
                              )}
                              <div className="sigPad__penColors">
                                <p>Couleurs :</p>
                                {colors.map((color) => (
                                  <span
                                    key={color}
                                    style={{
                                      backgroundColor: color,
                                      border: `${
                                        color === penColor
                                          ? `2px solid ${color}`
                                          : ""
                                      }`,
                                    }}
                                    onClick={() => setPenColor(color)}
                                  ></span>
                                ))}
                              </div>
                              <div className="sigPadContainer">
                                <SignatureCanvas
                                  penColor={penColor}
                                  canvasProps={{ className: "sigCanvas" }}
                                  ref={sigCanvas}
                                />
                              </div>

                              <button
                                className="flex items-center mt-2 space-x-2 rounded-lg"
                                onClick={() => sigCanvas.current.clear()}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-4 h-4 text-gray-700"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z"
                                  />
                                </svg>
                                <span className="text-gray-700">Effacer</span>
                              </button>
                              <div className="flex justify-end space-x-4 modal__bottom">
                                <button
                                  className="border border-gray-500 rounded-lg"
                                  onClick={() => {
                                    imageURL
                                      ? setOpenModal(false)
                                      : setContrat(false);
                                    setOpenModal(false);
                                  }}
                                >
                                  Annuler
                                </button>
                                <br />
                                {/* <button
                            onClick={download}
                            style={{ padding: "5px", marginTop: "5px" }}
                          >
                            Télécharger
                          </button> */}
                                <button
                                  className={`" bg-orange-600 border border-orange-700 "} rounded-lg text-white`}
                                  onClick={create}
                                >
                                  <span className="px-2">Valider</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : activeStep === 3 ? (
                    licence?.licenseCategoryName?.toLowerCase() !== "trial" ? (
                      <div className="p-20 pt-5">
                        <Alert severity="warning">
                          Les champs avec la mention (*) sont obligatoires
                        </Alert>
                        <div className="flex flex-col items-center justify-center w-full">
                          <div className="space-y-4 w-[70vw] mt-10 flex flex-col justify-center items-center">
                            <div className="flex flex-wrap items-baseline justify-center p-2 space-x-4 space-y-2">
                              <button
                                onClick={() => handlePaymentMethod("OM")}
                                className={`${
                                  active == "OM"
                                    ? "border-orange-700 border-4 "
                                    : "border-gray-200 "
                                } "w-40 h-28 group flex flex-col space-x-1 justify-center items-center border-2  p-2 cursor-pointer hover:bg-gray-200 rounded-md"`}
                              >
                                <img
                                  className="w-28 h-14"
                                  src={require("../../assets/img/orange-money.png")}
                                  alt="Orange money"
                                />
                                <p className="group-hover:font-semibold group-hover:underline">
                                  Orange money
                                </p>
                              </button>
                              <button
                                //onClick={() => handlePaymentMethod('MV')}
                                className={`${
                                  active == "MV"
                                    ? "border-orange-700 border-4 "
                                    : "border-gray-200 bg-gray-300 "
                                } "w-40 h-28 group flex flex-col space-x-1 justify-center items-center border-2 border-gray-200 p-2 cursor-not-allowed hover:bg-gray-200 rounded-md"`}
                              >
                                <img
                                  className="w-28 h-14 brightness-75"
                                  src={require("../../assets/img/moov-money.png")}
                                  alt="Orange money"
                                />
                                <p className="">Indisponible</p>
                              </button>
                              <button
                                //onClick={() => handlePaymentMethod('CARTE')}
                                className={`${
                                  active == "CARTE"
                                    ? "border-orange-700 border-4 "
                                    : "border-gray-200 bg-gray-300 "
                                }"w-40 h-28 group flex flex-col space-x-1 justify-center items-center border-2 border-gray-200 p-2 cursor-not-allowed hover:bg-gray-200 rounded-md"`}
                              >
                                <img
                                  className="w-28 h-14 brightness-75"
                                  src={require("../../assets/img/mastercard-visa.png")}
                                  alt="Orange money"
                                />
                                <p className="">Indisponible</p>
                              </button>
                            </div>
                            <div className="flex flex-col items-center justify-center w-full h-auto p-2 ">
                              {(active == "OM" || active == "MV") && (
                                <div className="space-y-3 shadow-sm columns-1 md:space-y-2 sm:columns-2 md:columns-1 lg:columns-1 xl:columns-1 2xl:columns-1">
                                  {licence ? (
                                    <h5 className="text-sm">
                                      Veuillez composer
                                      <span className="font-semibold">
                                        *144*4*6*{total}#
                                      </span>
                                      pour générer le OTP
                                    </h5>
                                  ) : null}
                                  <div className="flex flex-col space-y-1 rounded-md">
                                    <label className="px-2 font-normal text-left text-md">
                                      Téléphone{" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <PhoneInput
                                      className="relative w-full px-2 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                      placeholder="Téléphone"
                                      value={telOtp}
                                      onChange={(e) => setTelOtp(e)}
                                    />
                                    {!telOtp && (
                                      <span className="ml-4 text-sm text-left text-red-500">
                                        Ce champ est obligatoire
                                      </span>
                                    )}
                                  </div>

                                  <div className="flex flex-col space-y-1">
                                    <label className="px-2 font-normal text-left text-md">
                                      Code OTP{" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <OtpInput
                                      value={codeOtp}
                                      onChange={(e) => handleChange(e)}
                                      numInputs={6}
                                      separator={<span>-</span>}
                                      shouldAutoFocus
                                      isInputNum={true}
                                      isInputSecure={true}
                                      inputStyle="inputStyle"
                                    />
                                    {!codeOtp && (
                                      <span className="ml-4 text-sm text-left text-red-500">
                                        Ce champ est obligatoire
                                      </span>
                                    )}
                                  </div>
                                </div>
                              )}

                              {active == "CARTE" && (
                                <div className="space-y-3 shadow-sm columns-1 md:space-y-2 sm:columns-2 md:columns-1 lg:columns-1 xl:columns-1 2xl:columns-1">
                                  <PaymentForm />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="text-center">
                        <h1 className="m-20 font-extrabold text-7xl">
                          Gratuit
                        </h1>
                      </div>
                    )
                  ) : null}
                </div>
              )}

              {searchParams.get("type") !== "cloud" ? (
                <Box
                  sx={{ display: "flex", flexDirection: "row", pt: 4, px: 5 }}
                >
                  {activeStep === 0 ? (
                    <NavLink
                      className="text-white cursor-pointer bg-gray-400 hover:text-gray-600 hover:font-semibold hover:bg-gray-200 font-semibold focus:ring-4 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                      to={HOME_PAGE}
                    >
                      Accueil
                    </NavLink>
                  ) : (
                    <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
                      Retour
                    </Button>
                  )}
                  <Box sx={{ flex: "1 1 auto" }} />
                  {/* {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )} */}

                  {activeStep === steps.length - 1 ? (
                    !isLoading ? (
                      <Button onClick={() => formikPayment.handleSubmit()}>
                        Enrégistrer
                      </Button>
                    ) : (
                      <div className="flex items-center mt-3 font-semibold text-gray-500">
                        <span className="animate-pulse">En cours</span>
                        <ButtonLoading color="blue" />
                      </div>
                    )
                  ) : activeStep === 0 ? (
                    <Button
                      className="capitalize"
                      onClick={() => formikCompany.handleSubmit()}
                    >
                      Suivant
                    </Button>
                  ) : activeStep === 1 ? (
                    <Button
                      className="capitalize"
                      onClick={() => formikAccount.handleSubmit()}
                    >
                      Suivant
                    </Button>
                  ) : activeStep === 2 ? (
                    // : activeStep === 2 ? (
                    //   <Button
                    //     className="capitalize"
                    //     onClick={() => sendDataEmail()}
                    //   >
                    //     Vérifier
                    //   </Button>
                    // )
                    <Button onClick={() => saveMateriel()}>Suivant</Button>
                  ) : (
                    <Button
                      disabled={!imageURL}
                      onClick={() => saveSignature()}
                    >
                      Suivant
                    </Button>
                  )}
                </Box>
              ) : (
                <Box
                  sx={{ display: "flex", flexDirection: "row", pt: 4, px: 5 }}
                >
                  {activeStep === 0 ? (
                    <NavLink
                      className="text-white cursor-pointer bg-gray-400 hover:text-gray-600 hover:font-semibold hover:bg-gray-200 font-semibold focus:ring-4 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                      to={HOME_PAGE}
                    >
                      Accueil
                    </NavLink>
                  ) : (
                    <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
                      Retour
                    </Button>
                  )}
                  <Box sx={{ flex: "1 1 auto" }} />
                  {/* {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )} */}

                  {activeStep === stepsCloud.length - 1 ? (
                    !isLoading ? (
                      <Button onClick={() => formikPayment.handleSubmit()}>
                        Enrégistrer
                      </Button>
                    ) : (
                      <div className="flex items-center mt-3 font-semibold text-gray-500">
                        <span className="animate-pulse">En cours</span>
                        <ButtonLoading color="blue" />
                      </div>
                    )
                  ) : activeStep === 0 ? (
                    <Button
                      className="capitalize"
                      onClick={() => formikCompany.handleSubmit()}
                    >
                      Suivant
                    </Button>
                  ) : activeStep === 1 ? (
                    <Button
                      className="capitalize"
                      onClick={() => formikAccount.handleSubmit()}
                    >
                      Suivant
                    </Button>
                  ) : (
                    <Button
                      disabled={!imageURL}
                      onClick={() => saveSignature()}
                    >
                      Suivant
                    </Button>
                  )}
                </Box>
              )}
            </React.Fragment>
          )}
        </Box>
      </div>

      <div className="flex items-center justify-center h-auto p-4 mx-auto bg-white rounded-lg shadow-lg md:hidden">
        <Box sx={{ maxWidth: 480 }}>
          {searchParams.get("type") !== "cloud" ? (
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step}>
                  <StepLabel>{step}</StepLabel>
                  <StepContent>
                    {/* Content Stepper */}

                    <div>
                      {activeStep === 0 ? (
                        <div className="">
                          <Alert severity="warning">
                            Les champs avec la mention (*) sont obligatoires
                          </Alert>
                          <div className="grid grid-cols-1 gap-1 space-y-1 ">
                            {/* <div className="flex flex-col mt-3 space-y-1 rounded-md">
                      <label className="px-2 font-normal text-left text-md">
                        Code de parainnage
                      </label>
                      <input
                        name=""
                        type="text"
                        className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="code"
                        value={codeAff}
                        onChange={(e) => setCodeAff(e.target.value)}
                      />
                      {codeAffErr ? (
                        <span className="ml-4 text-sm text-left text-red-500">
                          Ce code est invalide
                        </span>
                      ) : null}
                    </div> */}

                            <div className="flex flex-col mt-3 space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Code d'affiliation
                              </label>
                              <div className="flex items-center border border-gray-300 rounded-lg jus">
                                <select
                                  className="w-12 font-bold text-gray-500 appearance-none focus:apparence-none"
                                  onChange={(e) =>
                                    setCodeAffCode(e.target.value)
                                  }
                                >
                                  <option
                                    className="text-center"
                                    value={"B-"}
                                    defaultValue
                                  >
                                    B
                                  </option>
                                  <option className="text-center" value={"S-"}>
                                    S
                                  </option>
                                </select>
                                <input
                                  name=""
                                  type="text"
                                  className="w-full px-3 py-2 font-semibold tracking-widest text-gray-500 appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  placeholder="Six (06) chiffres"
                                  value={codeAff}
                                  onChange={(e) => setCodeAff(e.target.value)}
                                />
                              </div>

                              {codeAffErr ? (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  Ce champ doit contenir six (06) chiffres
                                </span>
                              ) : null}
                            </div>

                            <div className="space-y-2 ">
                              <div className="flex flex-col w-full h-auto space-y-1 rounded-md">
                                <label className="px-2 font-normal text-left text-md">
                                  Pays <span className="text-red-500">*</span>
                                </label>
                                <DropdownFlag
                                  isSearchable
                                  placeHolder="Pays ..."
                                  options={options}
                                  onChange={(value) => setCountryCompany(value)}
                                />
                                {!countryCompany ? (
                                  <p className="px-2 text-sm text-left text-red-500">
                                    Ce champ est obligatoire
                                  </p>
                                ) : null}
                              </div>
                            </div>

                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Ville <span className="text-red-500">*</span>
                              </label>
                              <input
                                name="companyCity"
                                type="text"
                                className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Ville"
                                value={formikCompany.values.companyCity}
                                onChange={formikCompany.handleChange(
                                  "companyCity"
                                )}
                              />
                              {formikCompany?.errors?.companyCity && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  {formikCompany.errors.companyCity}
                                </span>
                              )}
                            </div>
                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Nom de l'entreprise{" "}
                                <span className="text-red-500">*</span>
                              </label>
                              <input
                                name="companyName"
                                type="text"
                                className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Nom"
                                value={formikCompany.values.companyName}
                                onChange={formikCompany.handleChange(
                                  "companyName"
                                )}
                              />
                              {formikCompany?.errors?.companyName && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  {formikCompany.errors.companyName}
                                </span>
                              )}
                            </div>

                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Email <span className="text-red-500">*</span>
                              </label>
                              <input
                                name="companyEmail"
                                type="email"
                                className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Email"
                                value={formikCompany.values.companyEmail}
                                onChange={formikCompany.handleChange(
                                  "companyEmail"
                                )}
                              />
                              {formikCompany?.errors?.companyEmail && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  {formikCompany.errors.companyEmail}
                                </span>
                              )}
                            </div>

                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Téléphone{" "}
                                <span className="text-red-500">*</span>
                              </label>
                              <PhoneInput
                                className="w-full px-2 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Téléphone"
                                value={telCompany}
                                onChange={(e) => setTelCompany(e)}
                              />
                              {!telCompany && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  Ce champ est obligatoire
                                </span>
                              )}
                            </div>

                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Whatsapp
                              </label>
                              <PhoneInput
                                className="w-full px-2 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Whatsapp"
                                value={telWCompany}
                                onChange={(e) => setTelWCompany(e)}
                              />
                              {/*!telWCompany && <span className="ml-4 text-sm text-left text-red-500">Ce champ est obligatoire</span>*/}
                            </div>

                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Adresse <span className="text-red-500">*</span>
                              </label>
                              <input
                                name=""
                                type="text"
                                className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Adresse"
                                value={formikCompany.values.companyAddress}
                                onChange={formikCompany.handleChange(
                                  "companyAddress"
                                )}
                              />
                              {formikCompany?.errors?.companyAddress && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  {formikCompany.errors.companyAddress}
                                </span>
                              )}
                            </div>

                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                IFU
                              </label>
                              <input
                                name=""
                                type="text"
                                className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="IFU"
                                value={formikCompany.values.companyIfu}
                                onChange={formikCompany.handleChange(
                                  "companyIfu"
                                )}
                              />
                              {formikCompany?.errors?.companyIfu && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  {formikCompany.errors.companyIfu}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : activeStep === 1 ? (
                        <div className="">
                          <Alert severity="warning">
                            Les champs avec la mention (*) sont obligatoires
                          </Alert>
                          <div className="grid grid-cols-1 gap-1 space-y-1">
                            <div className="flex flex-col mt-3 space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Nom <span className="text-red-500">*</span>
                              </label>
                              <input
                                name="lname"
                                type="text"
                                className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Nom"
                                value={formikAccount.values.lname}
                                onChange={formikAccount.handleChange("lname")}
                              />
                              {formikAccount?.errors?.lname && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  {formikAccount.errors.lname}
                                </span>
                              )}
                            </div>

                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Prénom <span className="text-red-500">*</span>
                              </label>
                              <input
                                name="fname"
                                type="text"
                                className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Prénom"
                                value={formikAccount.values.fname}
                                onChange={formikAccount.handleChange("fname")}
                              />
                              {formikAccount?.errors?.fname && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  {formikAccount.errors.fname}
                                </span>
                              )}
                            </div>

                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Nom d'utilisateur{" "}
                                <span className="text-red-500">*</span>
                              </label>
                              <input
                                name="username"
                                type="text"
                                className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Nom d'utilisateur"
                                value={formikAccount.values.username}
                                onChange={formikAccount.handleChange(
                                  "username"
                                )}
                              />
                              {formikAccount?.errors?.username && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  {formikAccount.errors.username}
                                </span>
                              )}
                            </div>

                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Téléphone{" "}
                                <span className="text-red-500">*</span>
                              </label>
                              <PhoneInput
                                className="w-full px-2 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Téléphone"
                                value={telUser}
                                onChange={(e) => setTelUser(e)}
                              />
                              {!telUser && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  Ce champ est obligatoire
                                </span>
                              )}
                            </div>

                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Mot de passe{" "}
                                <span className="text-red-500">*</span>
                              </label>
                              <input
                                name="password"
                                type="password"
                                className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Mot de passe"
                                value={formikAccount.values.password}
                                onChange={formikAccount.handleChange(
                                  "password"
                                )}
                              />
                              {formikAccount?.errors?.password && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  {formikAccount.errors.password}
                                </span>
                              )}
                            </div>

                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Répéter le mot de passe{" "}
                                <span className="text-red-500">*</span>
                              </label>
                              <input
                                name="repeatPwd"
                                type="password"
                                className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Répéter le mot de passe"
                                value={formikAccount.values.confirmePassword}
                                onChange={formikAccount.handleChange(
                                  "confirmePassword"
                                )}
                              />
                              {formikAccount?.errors?.confirmePassword && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  {formikAccount.errors.confirmePassword}
                                </span>
                              )}
                            </div>

                            <div className="px-2 pt-5 space-y-2 text-left">
                              <input
                                checked={formikAccount.values.checked}
                                onChange={() =>
                                  formikAccount.setFieldValue(
                                    "checked",
                                    !formikAccount.values.checked
                                  )
                                }
                                id="checked-checkbox"
                                type="checkbox"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                for="checked-checkbox"
                                className="px-2 font-normal text-left text-md"
                              >
                                Utilisez l'adresse éléctronique de l'entreprise
                              </label>
                            </div>

                            {!formikAccount.values.checked && (
                              <div className="space-y-2 shadow-sm columns-1">
                                <div className="flex flex-col space-y-1 rounded-md">
                                  <label className="px-2 font-normal text-left text-md">
                                    Email{" "}
                                    <span className="text-red-500">*</span>
                                  </label>
                                  <input
                                    name="userEmail"
                                    type="email"
                                    className="relative w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="Email"
                                    value={formikAccount.values.email}
                                    onChange={formikAccount.handleChange(
                                      "email"
                                    )}
                                  />
                                  {formikAccount?.errors?.email && (
                                    <span className="ml-4 text-sm text-left text-red-500">
                                      {formikAccount.errors.email}
                                    </span>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : activeStep === 2 ? (
                        <div className="">
                          <Alert severity="warning">
                            Les champs avec la mention (*) sont obligatoires
                          </Alert>
                          <div className="flex flex-col items-center mt-5">
                            {/* <div className="flex flex-wrap items-baseline justify-center p-2 space-x-4 space-y-2">
                      <button
                        onClick={() => handlePaymentMethod("OM")}
                        className={`${
                          active == "OM"
                            ? "border-orange-700 border-4 "
                            : "border-gray-200 "
                        } "w-40 h-28 group flex flex-col space-x-1 justify-center items-center border-2  p-2 cursor-pointer hover:bg-gray-200 rounded-md"`}
                      >
                        <img
                          className="w-28 h-14"
                          src={require("../../assets/img/orange-money.png")}
                          alt="Orange money"
                        />
                        <p className="group-hover:font-semibold group-hover:underline">
                          Orange money
                        </p>
                      </button>
                      <button
                        onClick={() => handlePaymentMethod("OM")}
                        className={`${
                          active == "OM"
                            ? "border-orange-700 border-4 "
                            : "border-gray-200 "
                        } "w-40 h-28 group flex flex-col space-x-1 justify-center items-center border-2  p-2 cursor-pointer hover:bg-gray-200 rounded-md"`}
                      >
                        <img
                          className="w-28 h-14"
                          src={require("../../assets/img/orange-money.png")}
                          alt="Orange money"
                        />
                        <p className="group-hover:font-semibold group-hover:underline">
                          Orange money
                        </p>
                      </button>
                    </div> */}
                            <FormControl>
                              {/* <FormLabel id="demo-row-radio-buttons-group-label">
                        Choisissez le materiel qui vous convient
                      </FormLabel> */}
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={(e) =>
                                  handleChangeMontant(e.target.value)
                                }
                              >
                                <FormControlLabel
                                  value="E-ordinateur"
                                  control={
                                    <Radio
                                      checked={selectAddon === "E-ordinateur"}
                                    />
                                  }
                                  label="E-ordinateur"
                                />
                                <FormControlLabel
                                  value="E-telephone"
                                  control={
                                    <Radio
                                      checked={selectAddon === "E-telephone"}
                                    />
                                  }
                                  label="E-téléphone"
                                />
                              </RadioGroup>
                            </FormControl>
                            <div className="space-y-3">
                              {/* <p>
                        <span>Routeur</span>
                        <span>prix</span>
                        <span>Offert</span>
                      </p>
                      <p>
                        <span>Serveur</span>
                        <span>prix</span>
                        <span>Offert</span>
                      </p> */}
                              <p className="text-lg font-semibold text-gray-700">
                                Resumé de la souscription
                              </p>
                              <div className="flex flex-row items-center justify-center px-10 py-4 space-x-5 bg-white border-2 border-blue-800 rounded">
                                <p>
                                  <span className="text-lg font-extrabold text-gray-700 capitalize">
                                    {typeLic} - {licence?.licenseCategoryName}
                                  </span>
                                </p>
                                <p className="">
                                  {/* <span className="text-sm text-gray-600 uppercase">
                           Prix :
                         </span>{" "} */}
                                  <span className="font-bold text-gray-600">
                                    {licence?.licensePrice} F CFA
                                  </span>
                                </p>
                              </div>
                              {selectAddon === "E-ordinateur" ? (
                                <div className="flex flex-row items-center justify-center px-10 py-4 space-x-5 bg-white border-2 border-blue-800 rounded">
                                  <div className="border-gray-100 b-2">
                                    <img
                                      width={150}
                                      height={150}
                                      className="rounded"
                                      src={require("../../assets/img/e-ordi.jpg")}
                                      alt="photo-eordi"
                                    />
                                  </div>
                                  <div className="space-y-2 text-left">
                                    <p className="text-lg font-extrabold text-gray-700 capitalize">
                                      E-ordinateur
                                    </p>
                                    <p className="text-sm text-gray-600">
                                      Description appareil
                                    </p>
                                    <p className="">
                                      <span className="text-sm text-gray-600 uppercase">
                                        Prix :
                                      </span>{" "}
                                      <span className="font-bold text-gray-600">
                                        8335 F CFA
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              ) : null}

                              {selectAddon === "E-telephone" ? (
                                <div className="flex flex-row items-center justify-center px-10 py-4 space-x-5 bg-white border-2 border-blue-800 rounded">
                                  <div className="border-gray-100 b-2">
                                    <img
                                      width={150}
                                      height={150}
                                      className="rounded"
                                      src={require("../../assets/img/e-tel.jpg")}
                                      alt="photo-eordi"
                                    />
                                  </div>
                                  <div className="space-y-2 text-left">
                                    <p className="text-lg font-extrabold text-gray-700 capitalize">
                                      E-téléphone
                                    </p>
                                    <p className="text-sm text-gray-600">
                                      Description appareil
                                    </p>
                                    <p className="">
                                      <span className="text-sm text-gray-600 uppercase">
                                        Prix :
                                      </span>{" "}
                                      <span className="font-bold text-gray-600">
                                        4585 F CFA
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              ) : null}

                              <div className="flex flex-row items-center justify-center px-10 py-4 space-x-5 text-white bg-orange-500 rounded">
                                <p className="text-lg font-extrabold uppercase">
                                  Total
                                </p>
                                <p className="">
                                  {/* <span className="text-sm text-gray-600 uppercase">
                           Prix :
                         </span>{" "} */}
                                  <span className="text-lg font-extrabold">
                                    {total} F CFA
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : activeStep === 3 ? (
                        <div className="">
                          <Alert severity="warning">
                            Les champs avec la mention (*) sont obligatoires
                          </Alert>
                          <div className="mt-5">
                            <p className="text-left text-gray-700 indent-10">
                              Je soussigné(e), Monsieur / Madame{" "}
                              <span className="font-semibold capitalize">{`${formikAccount.values.lname} ${formikAccount.values.fname}`}</span>{" "}
                              confirme ma souscription pour la licence{" "}
                              <span className="font-semibold">
                                {licence?.licenseCategoryName}
                              </span>{" "}
                              dans la catégorie{" "}
                              <span className="font-semibold capitalize">
                                {typeLic}
                              </span>{" "}
                              pour un montant{" "}
                              <span className="font-semibold">
                                {total} F CFA
                              </span>{" "}
                              pour une{" "}
                              {licence?.validityInDays !== -1 ? (
                                <span>
                                  période allant du{" "}
                                  <span className="font-semibold">
                                    {startDate}
                                  </span>{" "}
                                  au{" "}
                                  <span className="font-semibold">
                                    {endDate}
                                  </span>{" "}
                                  .{" "}
                                </span>
                              ) : (
                                <span className="font-semibold">
                                  durée illimitée .{" "}
                                </span>
                              )}
                              {/* <span className="space-x-1 text-sm">
                        <label
                          htmlFor="select-duree"
                          className="text-sm font-semibold text-gray-500 hover:text-blue-500 "
                        >
                          Changer la durée :
                        </label>
                        <select
                          id="select-duree"
                          name="select-duree"
                          className="w-auto p-1 font-bold text-gray-500 appearance-none focus:apparence-none"
                          onChange={(e) => handleChangeDate(e.target.value)}
                        >
                          <option
                            className="text-sm font-semibold text-gray-500 hover:text-blue-500"
                            value={30}
                            defaultValue
                          >
                            un (01) mois
                          </option>
                          <option
                            className="text-sm font-semibold text-gray-500 hover:text-blue-500"
                            value={90}
                          >
                            trois (03) mois
                          </option>
                          <option
                            className="text-sm font-semibold text-gray-500 hover:text-blue-500"
                            value={180}
                          >
                            six (06) mois
                          </option>
                          <option
                            className="text-sm font-semibold text-gray-500 hover:text-blue-500"
                            value={360}
                          >
                            un (01) an
                          </option>
                        </select>
                      </span> */}
                            </p>
                          </div>
                          <div className="mt-5">
                            <p className="text-left text-gray-700">
                              En cliquant cette case vous indiquez que vous avez
                              lu et accepter{" "}
                              <a
                                href={"#"}
                                onClick={() => handleContrat()}
                                className="text-blue-500 underline apparence-none"
                              >
                                les termes et conditions du contrat
                              </a>
                              .
                            </p>
                          </div>
                          <div className="mt-5">
                            <p className="text-left text-gray-700">
                              Cliquez ici pour voir le{" "}
                              <a
                                href="#"
                                onClick={() => handleContrat()}
                                className="text-blue-500 underline apparence-none"
                              >
                                contrat
                              </a>
                              .
                            </p>
                          </div>
                          <div className="px-10 mt-5 space-y-2 text-left">
                            <input
                              checked={contrat}
                              onChange={() => {
                                setContrat(!contrat);
                                contrat === false
                                  ? setOpenModal(true)
                                  : setImageURL(null);
                              }}
                              id="checked-checkbox"
                              type="checkbox"
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              for="checked-checkbox"
                              className="px-2 font-normal text-left text-gray-700 text-md"
                            >
                              J'ai lu et j'accepte
                            </label>
                          </div>
                          <div className="flex flex-col items-center justify-center">
                            <br />
                            <div>
                              <p className="font-semibold underline">
                                Signature
                              </p>
                              {/* <button
                        className="flex items-center mt-2 space-x-2 rounded-lg"
                        onClick={() => sigCanvas.current.clear()}
                      >
                        <span className="text-gray-700">Effacer</span>
                      </button> */}
                            </div>

                            {imageURL && (
                              <>
                                <img
                                  src={imageURL}
                                  alt="signature"
                                  className="signature"
                                />
                              </>
                            )}
                          </div>

                          <div className="pad">
                            {/* <button onClick={() => setOpenModal(true)}>Signer</button>
                  <br /> */}

                            {openModel && (
                              <div className="modalContainer">
                                <div className="modal">
                                  <h4 className="text-2xl font-semibold text-center">
                                    Signature
                                  </h4>
                                  {disabledVal && (
                                    <p className="text-center text-red-500">
                                      Veuillez signer le contrat svp pour passer
                                      cette étape
                                    </p>
                                  )}
                                  <div className="sigPad__penColors">
                                    <p>Couleurs :</p>
                                    {colors.map((color) => (
                                      <span
                                        key={color}
                                        style={{
                                          backgroundColor: color,
                                          border: `${
                                            color === penColor
                                              ? `2px solid ${color}`
                                              : ""
                                          }`,
                                        }}
                                        onClick={() => setPenColor(color)}
                                      ></span>
                                    ))}
                                  </div>
                                  <div className="sigPadContainer">
                                    <SignatureCanvas
                                      penColor={penColor}
                                      canvasProps={{ className: "sigCanvas" }}
                                      ref={sigCanvasMobil}
                                    />
                                  </div>

                                  <button
                                    className="flex items-center mt-2 space-x-2 rounded-lg"
                                    onClick={() => sigCanvasMobil.current.clear()}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="w-4 h-4 text-gray-700"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z"
                                      />
                                    </svg>
                                    <span className="text-gray-700">
                                      Effacer
                                    </span>
                                  </button>
                                  <div className="flex justify-end space-x-4 modal__bottom">
                                    <button
                                      className="border border-gray-500 rounded-lg"
                                      onClick={() => {
                                        imageURL
                                          ? setOpenModal(false)
                                          : setContrat(false);
                                        setOpenModal(false);
                                      }}
                                    >
                                      Annuler
                                    </button>
                                    <br />
                                    {/* <button
                            onClick={download}
                            style={{ padding: "5px", marginTop: "5px" }}
                          >
                            Télécharger
                          </button> */}
                                    <button
                                      className={`" bg-orange-600 border border-orange-700 "} rounded-lg text-white`}
                                      onClick={createMobil}
                                    >
                                      <span className="px-2">Valider</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : activeStep === 4 ? (
                        licence?.licenseCategoryName?.toLowerCase() !==
                        "trial" ? (
                          <div className="">
                            <Alert severity="warning">
                              Les champs avec la mention (*) sont obligatoires
                            </Alert>
                            <div className="flex flex-col items-center justify-center w-full">
                              <div className="space-y-4 w-[70vw] mt- flex flex-col justify-center items-center">
                                <div className="flex flex-wrap items-baseline justify-center p-2 space-x-4 space-y-2">
                                  <button
                                    onClick={() => handlePaymentMethod("OM")}
                                    className={`${
                                      active == "OM"
                                        ? "border-orange-700 border-4 "
                                        : "border-gray-200 "
                                    } "w-20 h-14 group flex flex-col space-x-1 justify-center items-center border-2  p-2 cursor-pointer hover:bg-gray-200 rounded-md"`}
                                  >
                                    <img
                                      className="w-14 h-7"
                                      src={require("../../assets/img/orange-money.png")}
                                      alt="Orange money"
                                    />
                                    {/* <p className="group-hover:font-semibold group-hover:underline">
                                  Orange money
                                </p> */}
                                  </button>
                                  <button
                                    //onClick={() => handlePaymentMethod('MV')}
                                    className={`${
                                      active == "MV"
                                        ? "border-orange-700 border-4 "
                                        : "border-gray-200 bg-gray-300 "
                                    } "w-20 h-14 group flex flex-col space-x-1 justify-center items-center border-2 border-gray-200 p-2 cursor-not-allowed hover:bg-gray-200 rounded-md"`}
                                  >
                                    <img
                                      className="w-14 h-7 brightness-75"
                                      src={require("../../assets/img/moov-money.png")}
                                      alt="Orange money"
                                    />
                                    {/* <p className="">Indisponible</p> */}
                                  </button>
                                  <button
                                    //onClick={() => handlePaymentMethod('CARTE')}
                                    className={`${
                                      active == "CARTE"
                                        ? "border-orange-700 border-4 "
                                        : "border-gray-200 bg-gray-300 "
                                    }"w-20 h-14 group flex flex-col space-x-1 justify-center items-center border-2 border-gray-200 p-2 cursor-not-allowed hover:bg-gray-200 rounded-md"`}
                                  >
                                    <img
                                      className="w-14 h-7 brightness-75"
                                      src={require("../../assets/img/mastercard-visa.png")}
                                      alt="Orange money"
                                    />
                                    {/* <p className="">Indisponible</p> */}
                                  </button>
                                </div>
                                <div className="flex flex-col items-center justify-center h-auto ">
                                  {(active == "OM" || active == "MV") && (
                                    <div className="space-y-3 shadow-sm columns-1 md:space-y-2 sm:columns-1 md:columns-1 lg:columns-1 xl:columns-1 2xl:columns-1">
                                      {licence ? (
                                        <h5 className="text-sm">
                                          Veuillez composer
                                          <span className="font-semibold">
                                            *144*4*6*{total}#
                                          </span>
                                          pour générer le OTP
                                        </h5>
                                      ) : null}
                                      <div className="flex flex-col items-start space-y-1 rounded-md">
                                        <label className="px-2 text-sm font-normal text-left">
                                          Téléphone{" "}
                                          <span className="text-red-500">
                                            *
                                          </span>
                                        </label>
                                        <PhoneInput
                                          className="w-full px-2 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                          placeholder="Téléphone"
                                          value={telOtp}
                                          onChange={(e) => setTelOtp(e)}
                                        />
                                        {!telOtp && (
                                          <span className="ml-4 text-sm text-left text-red-500">
                                            Ce champ est obligatoire
                                          </span>
                                        )}
                                      </div>

                                      <div className="flex flex-col space-y-1">
                                        <label className="px-2 text-sm font-normal text-left">
                                          Code OTP{" "}
                                          <span className="text-red-500">
                                            *
                                          </span>
                                        </label>
                                        <OtpInput
                                          value={codeOtp}
                                          onChange={(e) => handleChange(e)}
                                          numInputs={6}
                                          separator={<span>-</span>}
                                          shouldAutoFocus
                                          isInputNum={true}
                                          isInputSecure={true}
                                          inputStyle="inputStyle"
                                        />
                                        {!codeOtp && (
                                          <span className="ml-4 text-sm text-left text-red-500">
                                            Ce champ est obligatoire
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  )}

                                  {active == "CARTE" && (
                                    <div className="space-y-3 shadow-sm columns-1 md:space-y-2 sm:columns-2 md:columns-1 lg:columns-1 xl:columns-1 2xl:columns-1">
                                      <PaymentForm />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="text-center">
                            <h1 className="m-20 font-extrabold text-7xl">
                              Gratuit
                            </h1>
                          </div>
                        )
                      ) : null}
                    </div>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        pt: 4,
                        px: 5,
                      }}
                    >
                      {activeStep === 0 ? (
                        <NavLink
                          className="text-white cursor-pointer bg-gray-400 hover:text-gray-600 hover:font-semibold hover:bg-gray-200 font-semibold focus:ring-4 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                          to={HOME_PAGE}
                        >
                          Accueil
                        </NavLink>
                      ) : (
                        <Button
                          color="inherit"
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Retour
                        </Button>
                      )}
                      <Box sx={{ flex: "1 1 auto" }} />
                      {/* {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )} */}

                      {activeStep === steps.length - 1 ? (
                        !isLoading ? (
                          <Button onClick={() => formikPayment.handleSubmit()}>
                            Enrégistrer
                          </Button>
                        ) : (
                          <div className="flex items-center mt-3 font-semibold text-gray-500">
                            <span className="animate-pulse">En cours</span>
                            <ButtonLoading color="blue" />
                          </div>
                        )
                      ) : activeStep === 0 ? (
                        <Button
                          className="capitalize"
                          onClick={() => formikCompany.handleSubmit()}
                        >
                          Suivant
                        </Button>
                      ) : activeStep === 1 ? (
                        <Button
                          className="capitalize"
                          onClick={() => formikAccount.handleSubmit()}
                        >
                          Suivant
                        </Button>
                      ) : activeStep === 2 ? (
                        // : activeStep === 2 ? (
                        //   <Button
                        //     className="capitalize"
                        //     onClick={() => sendDataEmail()}
                        //   >
                        //     Vérifier
                        //   </Button>
                        // )
                        <Button onClick={() => saveMateriel()}>Suivant</Button>
                      ) : (
                        <Button
                          disabled={!imageURL}
                          onClick={() => saveSignature()}
                        >
                          Suivant
                        </Button>
                      )}
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          ) : (
            <Stepper activeStep={activeStep} orientation="vertical">
              {stepsCloud.map((step, index) => (
                <Step key={step}>
                  <StepLabel>{step}</StepLabel>
                  <StepContent>
                    {/* Content Stepper */}

                    <div>
                      {activeStep === 0 ? (
                        <div className="">
                          <Alert severity="warning">
                            Les champs avec la mention (*) sont obligatoires
                          </Alert>
                          <div className="grid grid-cols-1 gap-1 space-y-1 ">
                            {/* <div className="flex flex-col mt-3 space-y-1 rounded-md">
                      <label className="px-2 font-normal text-left text-md">
                        Code de parainnage
                      </label>
                      <input
                        name=""
                        type="text"
                        className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="code"
                        value={codeAff}
                        onChange={(e) => setCodeAff(e.target.value)}
                      />
                      {codeAffErr ? (
                        <span className="ml-4 text-sm text-left text-red-500">
                          Ce code est invalide
                        </span>
                      ) : null}
                    </div> */}

                            <div className="flex flex-col mt-3 space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Code d'affiliation
                              </label>
                              <div className="flex items-center border border-gray-300 rounded-lg jus">
                                <select
                                  className="w-12 font-bold text-gray-500 appearance-none focus:apparence-none"
                                  onChange={(e) =>
                                    setCodeAffCode(e.target.value)
                                  }
                                >
                                  <option
                                    className="text-center"
                                    value={"B-"}
                                    defaultValue
                                  >
                                    B
                                  </option>
                                  <option className="text-center" value={"S-"}>
                                    S
                                  </option>
                                </select>
                                <input
                                  name=""
                                  type="text"
                                  className="w-full px-3 py-2 font-semibold tracking-widest text-gray-500 appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  placeholder="Six (06) chiffres"
                                  value={codeAff}
                                  onChange={(e) => setCodeAff(e.target.value)}
                                />
                              </div>

                              {codeAffErr ? (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  Ce champ doit contenir six (06) chiffres
                                </span>
                              ) : null}
                            </div>

                            <div className="space-y-2 ">
                              <div className="flex flex-col w-full h-auto space-y-1 rounded-md">
                                <label className="px-2 font-normal text-left text-md">
                                  Pays <span className="text-red-500">*</span>
                                </label>
                                <DropdownFlag
                                  isSearchable
                                  placeHolder="Pays ..."
                                  options={options}
                                  onChange={(value) => setCountryCompany(value)}
                                />
                                {!countryCompany ? (
                                  <p className="px-2 text-sm text-left text-red-500">
                                    Ce champ est obligatoire
                                  </p>
                                ) : null}
                              </div>
                            </div>

                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Ville <span className="text-red-500">*</span>
                              </label>
                              <input
                                name="companyCity"
                                type="text"
                                className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Ville"
                                value={formikCompany.values.companyCity}
                                onChange={formikCompany.handleChange(
                                  "companyCity"
                                )}
                              />
                              {formikCompany?.errors?.companyCity && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  {formikCompany.errors.companyCity}
                                </span>
                              )}
                            </div>
                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Nom de l'entreprise{" "}
                                <span className="text-red-500">*</span>
                              </label>
                              <input
                                name="companyName"
                                type="text"
                                className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Nom"
                                value={formikCompany.values.companyName}
                                onChange={formikCompany.handleChange(
                                  "companyName"
                                )}
                              />
                              {formikCompany?.errors?.companyName && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  {formikCompany.errors.companyName}
                                </span>
                              )}
                            </div>

                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Email <span className="text-red-500">*</span>
                              </label>
                              <input
                                name="companyEmail"
                                type="email"
                                className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Email"
                                value={formikCompany.values.companyEmail}
                                onChange={formikCompany.handleChange(
                                  "companyEmail"
                                )}
                              />
                              {formikCompany?.errors?.companyEmail && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  {formikCompany.errors.companyEmail}
                                </span>
                              )}
                            </div>

                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Téléphone{" "}
                                <span className="text-red-500">*</span>
                              </label>
                              <PhoneInput
                                className="w-full px-2 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Téléphone"
                                value={telCompany}
                                onChange={(e) => setTelCompany(e)}
                              />
                              {!telCompany && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  Ce champ est obligatoire
                                </span>
                              )}
                            </div>

                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Whatsapp
                              </label>
                              <PhoneInput
                                className="w-full px-2 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Whatsapp"
                                value={telWCompany}
                                onChange={(e) => setTelWCompany(e)}
                              />
                              {/*!telWCompany && <span className="ml-4 text-sm text-left text-red-500">Ce champ est obligatoire</span>*/}
                            </div>

                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Adresse <span className="text-red-500">*</span>
                              </label>
                              <input
                                name=""
                                type="text"
                                className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Adresse"
                                value={formikCompany.values.companyAddress}
                                onChange={formikCompany.handleChange(
                                  "companyAddress"
                                )}
                              />
                              {formikCompany?.errors?.companyAddress && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  {formikCompany.errors.companyAddress}
                                </span>
                              )}
                            </div>

                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                IFU
                              </label>
                              <input
                                name=""
                                type="text"
                                className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="IFU"
                                value={formikCompany.values.companyIfu}
                                onChange={formikCompany.handleChange(
                                  "companyIfu"
                                )}
                              />
                              {formikCompany?.errors?.companyIfu && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  {formikCompany.errors.companyIfu}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : activeStep === 1 ? (
                        <div className="">
                          <Alert severity="warning">
                            Les champs avec la mention (*) sont obligatoires
                          </Alert>
                          <div className="grid grid-cols-1 gap-1 space-y-1">
                            <div className="flex flex-col mt-3 space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Nom <span className="text-red-500">*</span>
                              </label>
                              <input
                                name="lname"
                                type="text"
                                className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Nom"
                                value={formikAccount.values.lname}
                                onChange={formikAccount.handleChange("lname")}
                              />
                              {formikAccount?.errors?.lname && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  {formikAccount.errors.lname}
                                </span>
                              )}
                            </div>

                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Prénom <span className="text-red-500">*</span>
                              </label>
                              <input
                                name="fname"
                                type="text"
                                className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Prénom"
                                value={formikAccount.values.fname}
                                onChange={formikAccount.handleChange("fname")}
                              />
                              {formikAccount?.errors?.fname && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  {formikAccount.errors.fname}
                                </span>
                              )}
                            </div>

                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Nom d'utilisateur{" "}
                                <span className="text-red-500">*</span>
                              </label>
                              <input
                                name="username"
                                type="text"
                                className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Nom d'utilisateur"
                                value={formikAccount.values.username}
                                onChange={formikAccount.handleChange(
                                  "username"
                                )}
                              />
                              {formikAccount?.errors?.username && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  {formikAccount.errors.username}
                                </span>
                              )}
                            </div>

                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Téléphone{" "}
                                <span className="text-red-500">*</span>
                              </label>
                              <PhoneInput
                                className="w-full px-2 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Téléphone"
                                value={telUser}
                                onChange={(e) => setTelUser(e)}
                              />
                              {!telUser && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  Ce champ est obligatoire
                                </span>
                              )}
                            </div>

                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Mot de passe{" "}
                                <span className="text-red-500">*</span>
                              </label>
                              <input
                                name="password"
                                type="password"
                                className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Mot de passe"
                                value={formikAccount.values.password}
                                onChange={formikAccount.handleChange(
                                  "password"
                                )}
                              />
                              {formikAccount?.errors?.password && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  {formikAccount.errors.password}
                                </span>
                              )}
                            </div>

                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Répéter le mot de passe{" "}
                                <span className="text-red-500">*</span>
                              </label>
                              <input
                                name="repeatPwd"
                                type="password"
                                className="w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Répéter le mot de passe"
                                value={formikAccount.values.confirmePassword}
                                onChange={formikAccount.handleChange(
                                  "confirmePassword"
                                )}
                              />
                              {formikAccount?.errors?.confirmePassword && (
                                <span className="ml-4 text-sm text-left text-red-500">
                                  {formikAccount.errors.confirmePassword}
                                </span>
                              )}
                            </div>

                            <div className="px-2 pt-5 space-y-2 text-left">
                              <input
                                checked={formikAccount.values.checked}
                                onChange={() =>
                                  formikAccount.setFieldValue(
                                    "checked",
                                    !formikAccount.values.checked
                                  )
                                }
                                id="checked-checkbox"
                                type="checkbox"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                for="checked-checkbox"
                                className="px-2 font-normal text-left text-md"
                              >
                                Utilisez l'adresse éléctronique de l'entreprise
                              </label>
                            </div>

                            {!formikAccount.values.checked && (
                              <div className="space-y-2 shadow-sm columns-1">
                                <div className="flex flex-col space-y-1 rounded-md">
                                  <label className="px-2 font-normal text-left text-md">
                                    Email{" "}
                                    <span className="text-red-500">*</span>
                                  </label>
                                  <input
                                    name="userEmail"
                                    type="email"
                                    className="relative w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="Email"
                                    value={formikAccount.values.email}
                                    onChange={formikAccount.handleChange(
                                      "email"
                                    )}
                                  />
                                  {formikAccount?.errors?.email && (
                                    <span className="ml-4 text-sm text-left text-red-500">
                                      {formikAccount.errors.email}
                                    </span>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : activeStep === 2 ? (
                        <div className="">
                          <Alert severity="warning">
                            Les champs avec la mention (*) sont obligatoires
                          </Alert>
                          <div className="mt-2">
                            <p className="text-left text-gray-700 indent-10">
                              Je soussigné(e), Monsieur / Madame{" "}
                              <span className="font-semibold capitalize">{`${formikAccount.values.lname} ${formikAccount.values.fname}`}</span>{" "}
                              confirme ma souscription pour la licence{" "}
                              <span className="font-semibold">
                                {licence?.licenseCategoryName}
                              </span>{" "}
                              dans la categorie{" "}
                              <span className="font-semibold capitalize">
                                {typeLic}
                              </span>{" "}
                              pour un montant{" "}
                              <span className="font-semibold">
                                {licence?.licensePrice}
                              </span>{" "}
                              FCFA pour une{" "}
                              {licence?.validityInDays !== -1 ? (
                                <span>
                                  période allant du{" "}
                                  <span className="font-semibold">
                                    {startDate}
                                  </span>{" "}
                                  au{" "}
                                  <span className="font-semibold">
                                    {endDate}
                                  </span>{" "}
                                  .{" "}
                                </span>
                              ) : (
                                <span className="font-semibold">
                                  durée illimitée .{" "}
                                </span>
                              )}
                              {/* <span className="space-x-1 text-sm">
                        <label
                          htmlFor="select-duree"
                          className="text-sm font-semibold text-gray-500 hover:text-blue-500 "
                        >
                          Changer la durée :
                        </label>
                        <select
                          id="select-duree"
                          name="select-duree"
                          className="w-auto p-1 font-bold text-gray-500 appearance-none focus:apparence-none"
                          onChange={(e) => handleChangeDate(e.target.value)}
                        >
                          <option
                            className="text-sm font-semibold text-gray-500 hover:text-blue-500"
                            value={30}
                            defaultValue
                          >
                            un (01) mois
                          </option>
                          <option
                            className="text-sm font-semibold text-gray-500 hover:text-blue-500"
                            value={90}
                          >
                            trois (03) mois
                          </option>
                          <option
                            className="text-sm font-semibold text-gray-500 hover:text-blue-500"
                            value={180}
                          >
                            six (06) mois
                          </option>
                          <option
                            className="text-sm font-semibold text-gray-500 hover:text-blue-500"
                            value={360}
                          >
                            un (01) an
                          </option>
                        </select>
                      </span> */}
                            </p>
                          </div>
                          <div className="mt-5">
                            <p className="text-left text-gray-700">
                              En cliquant cette case vous indiquez que vous avez
                              lu et accepter{" "}
                              <a
                                href={"#"}
                                onClick={() => handleContrat()}
                                className="text-blue-500 underline apparence-none"
                              >
                                les termes et conditions du contrat
                              </a>
                              .
                            </p>
                          </div>
                          <div className="mt-5">
                            <p className="text-left text-gray-700">
                              Cliquez ici pour voir le{" "}
                              <a
                                href="#"
                                onClick={() => handleContrat()}
                                className="text-blue-500 underline apparence-none"
                              >
                                contrat
                              </a>
                              .
                            </p>
                          </div>
                          <div className="px-10 mt-5 space-y-2 text-left">
                            <input
                              checked={contrat}
                              onChange={() => {
                                setContrat(!contrat);
                                contrat === false
                                  ? setOpenModal(true)
                                  : setImageURL(null);
                              }}
                              id="checked-checkbox"
                              type="checkbox"
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              for="checked-checkbox"
                              className="px-2 font-normal text-left text-gray-700 text-md"
                            >
                              J'ai lu et j'accepte
                            </label>
                          </div>
                          <div className="flex flex-col items-center justify-center">
                            <br />
                            <div>
                              <p className="font-semibold underline">
                                Signature
                              </p>
                              {/* <button
                        className="flex items-center mt-2 space-x-2 rounded-lg"
                        onClick={() => sigCanvas.current.clear()}
                      >
                        <span className="text-gray-700">Effacer</span>
                      </button> */}
                            </div>

                            {imageURL && (
                              <>
                                <img
                                  src={imageURL}
                                  alt="signature"
                                  className="signature"
                                />
                              </>
                            )}
                          </div>

                          <div className="pad">
                            {/* <button onClick={() => setOpenModal(true)}>Signer</button>
                  <br /> */}

                            {openModel && (
                              <div className="modalContainer">
                                <div className="modal">
                                  <h4 className="text-2xl font-semibold text-center">
                                    Signature
                                  </h4>
                                  {disabledVal && (
                                    <p className="text-center text-red-500">
                                      Veuillez signer le contrat svp pour passer
                                      cette étape
                                    </p>
                                  )}
                                  <div className="sigPad__penColors">
                                    <p>Couleurs :</p>
                                    {colors.map((color) => (
                                      <span
                                        key={color}
                                        style={{
                                          backgroundColor: color,
                                          border: `${
                                            color === penColor
                                              ? `2px solid ${color}`
                                              : ""
                                          }`,
                                        }}
                                        onClick={() => setPenColor(color)}
                                      ></span>
                                    ))}
                                  </div>
                                  <div className="sigPadContainer">
                                    <SignatureCanvas
                                      penColor={penColor}
                                      canvasProps={{ className: "sigCanvas" }}
                                      ref={sigCanvasMobil}
                                    />
                                  </div>

                                  <button
                                    className="flex items-center mt-2 space-x-2 rounded-lg"
                                    onClick={() => sigCanvasMobil.current.clear()}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="w-4 h-4 text-gray-700"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z"
                                      />
                                    </svg>
                                    <span className="text-gray-700">
                                      Effacer
                                    </span>
                                  </button>
                                  <div className="flex justify-end space-x-4 modal__bottom">
                                    <button
                                      className="border border-gray-500 rounded-lg"
                                      onClick={() => {
                                        imageURL
                                          ? setOpenModal(false)
                                          : setContrat(false);
                                        setOpenModal(false);
                                      }}
                                    >
                                      Annuler
                                    </button>
                                    <br />
                                    {/* <button
                            onClick={download}
                            style={{ padding: "5px", marginTop: "5px" }}
                          >
                            Télécharger
                          </button> */}
                                    <button
                                      className={`" bg-orange-600 border border-orange-700 "} rounded-lg text-white`}
                                      onClick={createMobil}
                                    >
                                      <span className="px-2">Valider</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : activeStep === 3 ? (
                        licence?.licenseCategoryName?.toLowerCase() !==
                        "trial" ? (
                          <div className="">
                            <Alert severity="warning">
                              Les champs avec la mention (*) sont obligatoires
                            </Alert>
                            <div className="flex flex-col items-center justify-center w-full">
                              <div className="space-y-4 w-[70vw] mt- flex flex-col justify-center items-center">
                                <div className="flex flex-wrap items-baseline justify-center p-2 space-x-4 space-y-2">
                                  <button
                                    onClick={() => handlePaymentMethod("OM")}
                                    className={`${
                                      active == "OM"
                                        ? "border-orange-700 border-4 "
                                        : "border-gray-200 "
                                    } "w-20 h-14 group flex flex-col space-x-1 justify-center items-center border-2  p-2 cursor-pointer hover:bg-gray-200 rounded-md"`}
                                  >
                                    <img
                                      className="w-14 h-7"
                                      src={require("../../assets/img/orange-money.png")}
                                      alt="Orange money"
                                    />
                                    {/* <p className="group-hover:font-semibold group-hover:underline">
                                  Orange money
                                </p> */}
                                  </button>
                                  <button
                                    //onClick={() => handlePaymentMethod('MV')}
                                    className={`${
                                      active == "MV"
                                        ? "border-orange-700 border-4 "
                                        : "border-gray-200 bg-gray-300 "
                                    } "w-20 h-14 group flex flex-col space-x-1 justify-center items-center border-2 border-gray-200 p-2 cursor-not-allowed hover:bg-gray-200 rounded-md"`}
                                  >
                                    <img
                                      className="w-14 h-7 brightness-75"
                                      src={require("../../assets/img/moov-money.png")}
                                      alt="Orange money"
                                    />
                                    {/* <p className="">Indisponible</p> */}
                                  </button>
                                  <button
                                    //onClick={() => handlePaymentMethod('CARTE')}
                                    className={`${
                                      active == "CARTE"
                                        ? "border-orange-700 border-4 "
                                        : "border-gray-200 bg-gray-300 "
                                    }"w-20 h-14 group flex flex-col space-x-1 justify-center items-center border-2 border-gray-200 p-2 cursor-not-allowed hover:bg-gray-200 rounded-md"`}
                                  >
                                    <img
                                      className="w-14 h-7 brightness-75"
                                      src={require("../../assets/img/mastercard-visa.png")}
                                      alt="Orange money"
                                    />
                                    {/* <p className="">Indisponible</p> */}
                                  </button>
                                </div>
                                <div className="flex flex-col items-center justify-center h-auto ">
                                  {(active == "OM" || active == "MV") && (
                                    <div className="space-y-3 shadow-sm columns-1 md:space-y-2 sm:columns-1 md:columns-1 lg:columns-1 xl:columns-1 2xl:columns-1">
                                      {licence ? (
                                        <h5 className="text-sm">
                                          Veuillez composer
                                          <span className="font-semibold">
                                            *144*4*6*{total}#
                                          </span>
                                          pour générer le OTP
                                        </h5>
                                      ) : null}
                                      <div className="flex flex-col items-start space-y-1 rounded-md">
                                        <label className="px-2 text-sm font-normal text-left">
                                          Téléphone{" "}
                                          <span className="text-red-500">
                                            *
                                          </span>
                                        </label>
                                        <PhoneInput
                                          className="w-full px-2 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                          placeholder="Téléphone"
                                          value={telOtp}
                                          onChange={(e) => setTelOtp(e)}
                                        />
                                        {!telOtp && (
                                          <span className="ml-4 text-sm text-left text-red-500">
                                            Ce champ est obligatoire
                                          </span>
                                        )}
                                      </div>

                                      <div className="flex flex-col space-y-1">
                                        <label className="px-2 text-sm font-normal text-left">
                                          Code OTP{" "}
                                          <span className="text-red-500">
                                            *
                                          </span>
                                        </label>
                                        <OtpInput
                                          value={codeOtp}
                                          onChange={(e) => handleChange(e)}
                                          numInputs={6}
                                          separator={<span>-</span>}
                                          shouldAutoFocus
                                          isInputNum={true}
                                          isInputSecure={true}
                                          inputStyle="inputStyle"
                                        />
                                        {!codeOtp && (
                                          <span className="ml-4 text-sm text-left text-red-500">
                                            Ce champ est obligatoire
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  )}

                                  {active == "CARTE" && (
                                    <div className="space-y-3 shadow-sm columns-1 md:space-y-2 sm:columns-2 md:columns-1 lg:columns-1 xl:columns-1 2xl:columns-1">
                                      <PaymentForm />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <h1 className="mt-3 text-4xl font-extrabold text-center">
                            Gratuit
                          </h1>
                        )
                      ) : null}
                    </div>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        pt: 4,
                        px: 5,
                      }}
                    >
                      {activeStep === 0 ? (
                        <NavLink
                          className="text-white cursor-pointer bg-gray-400 hover:text-gray-600 hover:font-semibold hover:bg-gray-200 font-semibold focus:ring-4 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                          to={HOME_PAGE}
                        >
                          Accueil
                        </NavLink>
                      ) : (
                        <Button
                          color="inherit"
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Retour
                        </Button>
                      )}
                      <Box sx={{ flex: "1 1 auto" }} />
                      {/* {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )} */}

                      {activeStep === stepsCloud.length - 1 ? (
                        !isLoading ? (
                          <Button onClick={() => formikPayment.handleSubmit()}>
                            Enrégistrer
                          </Button>
                        ) : (
                          <div className="flex items-center mt-3 font-semibold text-gray-500">
                            <span className="animate-pulse">En cours</span>
                            <ButtonLoading color="blue" />
                          </div>
                        )
                      ) : activeStep === 0 ? (
                        <Button
                          className="capitalize"
                          onClick={() => formikCompany.handleSubmit()}
                        >
                          Suivant
                        </Button>
                      ) : activeStep === 1 ? (
                        <Button
                          className="capitalize"
                          onClick={() => formikAccount.handleSubmit()}
                        >
                          Suivant
                        </Button>
                      ) : (
                        <Button
                          disabled={!imageURL}
                          onClick={() => saveSignature()}
                        >
                          Suivant
                        </Button>
                      )}
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          )}
          {activeStep === steps.length && (
            <React.Fragment>
              <h4 className="px-5 mt-2 text-sm text-gray-600 tex-center">
                Félicitations vous venez de souscrire à la licence de
                Bangwelegre avec succès !
              </h4>
              <div className="flex items-center justify-center">
                <img
                  width={250}
                  src={require("../../assets/gif/55867-congratulation.gif")}
                  alt="Congratulations"
                />
              </div>
              <a
                href="#"
                onClick={() => openUrlInNewTab("https://admin.bangwelegre.com")}
                className="text-center text-blue-400 underline"
              >
                Connectez vous à votre boutique maintenant.
              </a>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={() => handleHome()}>
                  Retourner à l'accueil
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </div>
    </div>
  );
};

export default SouscriptionScreen;
