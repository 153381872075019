export const heroOne = {
	reverse: true,
	inverse: true,
	topLine: {
		text: '',
	},
	headline: "BÃNG-WELEGRE : L’INNOVATION",
	description: 'Bãng-welegre est une application conçue avec les meilleurs outils informatiques de conception de logiciel. Basée sur le cloud, ce logiciel est accessible et adaptable selon le type d’activité commerciale et selon votre situation géographique. Incluant en outre des innovations majeurs de méthodes et techniques de vente et de gestion. Couplé d’appareils de pointe, ce logiciel permet une intégration continue de solutions améliorées adaptés aux besoins de sa cible.',
	buttonLabel: 'Voir plus',
	imgStart: 'start',
	img: './assets/svg/Deal.svg',
	start: 'true',
};

export const heroTwo = {
	reverse: false,
	inverse: false,
	topLine: {
		text: '',
	},
	headline: 'TEAM CONCEPTEUR/QUI PEUT UTILISER L’APPLICATION',
	description: 'Dynamique, expérimentée, rigoureuse, jeune et passionnée, sont les qualificatifs qui désignent l’équipe conceptrice de cette solution informatique : Bãng-welegre. Cette équipe est composée de développeurs, et d’ingénieurs, tous provenant de Nafann SARL, l’initiatrice de cette solution cloud. Tout propriétaire d’une activité commerciale peu importe la nature de cette activité, désireux d’innover, de faire la différence, peut utiliser Bãng-welegre, logiciel de gestion de point de vente.',
	buttonLabel: 'View Project',

	linkTo: '/more',
	imgStart: 'start',
	img: './assets/svg/Connection.svg',
	start: 'true',
};

export const heroThree = {
	reverse: true,
	inverse: true,
	topLine: {
		text: '',
	},
	headline: 'SOLUTIONS ET BIENFAITS OFFERT PAR L’APPLICATION',
	description: 'Bãng-welegre n’est pas qu’une simple application ou logiciel de vente. Le contrôle permanent et assidu du stock, les différents méthodes et techniques de ventes, la détermination d’objectif de vente journalier afin de rentrer dans ses charges, la prise en compte des charges présentes et future pour une meilleure gestion du bénéfice sont une partie des possibilités que vous offre cette innovation. En plus de cela elle vous donne une projection d’amélioration de vos activités, la fixation réfléchit et adaptée des prix de vos articles, une meilleure gestion de votre clientèle avec une variété de méthodes de paiement, un contrôle permanent de toute votre activité ou que vous soyez, et bien d’autres innovations. L’application Bãng-welegre vous permet une synchronisation permanente et sécurisée de vos données. Pour permettre une belle et inoubliable expérience client, vous pouvez avoir accès à cette solution gratuitement en téléchargeant notre application.',
	buttonLabel: 'View Project',

	linkTo: '/download',
	imgStart: '',
	img: './assets/svg/ChartUp.svg',
	start: 'true',
};
