import React from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoutes from "./ProtectedRoutes";
import HomeScreen from "../pages/HomeScreen/HomeScreen";
import { CONTACT_PAGE, HOME_PAGE, LOGIN_PAGE, MY_COMPANY_PAGE, MY_PACKAGE_PAGE, PRICING_PAGE, REGISTER_PAGE, RESETPASS_PAGE } from "./Routes";
import PricingScreen from "../pages/PriceScreen/PricingScreen";
import AuthTabScreen from '../pages/AuthTabScreen/AuthTabScreen'
import ContactScreen from "../pages/ContactScreen/ContactScreen";
import RegisterScreen from "../pages/Register/RegisterScreen";
import MyCompanyScreen from "../pages/MyCompanyScreen/MyCompanyScreen";
import MyPackageScreen from "../pages/MyPackageScreen/MyPackageScreen";
import { useEffect } from "react";
import ReactGA from 'react-ga';
import AuthTabScreen2 from "../pages/AuthTabScreen/AuthTabScreen2";
import ResetpasswordScreen from './../pages/LoginScreen/ResetpasswordScreen';


const SiteRoutes = () => {
    const TRACKING_ID = "UA-211996035-3"; // OUR_TRACKING_ID
    ReactGA.initialize(TRACKING_ID);
    
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    console.log(window.location.pathname + window.location.search);
    return (
        <Routes>
            <Route element={<PrivateRoutes />}>
                <Route path={MY_COMPANY_PAGE} exact element={<MyCompanyScreen />} />
                <Route path={MY_PACKAGE_PAGE} exact element={<MyPackageScreen />} />
            </Route>
            <Route path={LOGIN_PAGE} exact element={<AuthTabScreen2 />} />
            <Route path={HOME_PAGE} exact element={<HomeScreen />} />
            <Route path={PRICING_PAGE} exact element={<PricingScreen />} />
            <Route path={RESETPASS_PAGE} exact element={<ResetpasswordScreen />} />
            {/*<Route path={CONTACT_PAGE} exact element={<ContactScreen />} />*/}
        </Routes>
    )
}

export default SiteRoutes;