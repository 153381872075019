import {
  PERMISSIONS,
  REFRESH_TOKEN,
  ROLE,
  STORE_ID,
  STORE_NAME,
  TOKEN_KEY,
  COMPANY_ID,
  STORES,
  IS_LOGGED,
  EXPIRE_IN
} from "./constants";

export function saveAuthValue(values) {
  localStorage.setItem(TOKEN_KEY, values.token);
  localStorage.setItem(REFRESH_TOKEN, values.refreshToken);
  localStorage.setItem(PERMISSIONS, JSON.stringify(values.permissions));
  localStorage.setItem(ROLE, values.role);
  localStorage.setItem(COMPANY_ID, values?.companyId);
  localStorage.setItem(STORES, JSON.stringify(values?.stores));
  //localStorage.setItem(STORE_ID, values.stores[0].storeId);
  //localStorage.setItem(STORE_NAME, values.stores[0].storeName);
  localStorage.setItem(IS_LOGGED, true);
  localStorage.setItem(EXPIRE_IN,values?.expiresIn);
}

export function saveRefreshAuthValue(values) {
  console.log("new token", values.token)
  localStorage.setItem(TOKEN_KEY, values.token);
  localStorage.setItem(REFRESH_TOKEN, values.refreshToken);
  localStorage.setItem(EXPIRE_IN,values?.expiresIn);
}

export function detroyAuthValue() {
  localStorage.clear();
}
