import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Button, Heading, TextWrapper } from "../../globalStyles";
import { IconContext } from "react-icons/lib";
import {
  PricingSection,
  PricingWrapper,
  PricingContainer,
  PricingCardInfo,
  PricingCardPlan,
  PricingCardCost,
  PricingCardFeatures,
  PricingCardText,
  PricingCardFeature,
  PricingCard,
} from "./PricingStyles";
import { pricingData } from "../../data/PricingData";
import { numStr, openUrl } from "../../helper/utils";
import { LOGIN_PAGE, MY_PACKAGE_PAGE } from "../../navigation/Routes";
import { useNavigate } from "react-router-dom";
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";
import { useEffect } from "react";
import Loading from "../Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import { BiListPlus, BiPlus } from "react-icons/bi";
import {
  BsCartCheck,
  BsCheck,
  BsCheck2,
  BsCheckCircle,
  BsCircle,
  BsPlusSquareDotted,
} from "react-icons/bs";
import { updateLicense } from "../../api/licenses/license";
import { success } from "../../api/config";
import { GO_PAYMENT } from "../../redux/login/loginActions";
import { FaMinus } from "react-icons/fa";
import { HiMinus, HiMinusSm } from "react-icons/hi";
import PricingLocal from "./PricingLocal";

const PricingCloud = (props) => {
  const gaEventTracker = useAnalyticsEventTracker("Souscription");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const login = useSelector((state) => state.login.isLogged);
  const companyId = useSelector((state) => state.login.companyId);
  const userLicence = useSelector((state) => state.login.userLicence);
  const [licenceCategoryId, setLicenceCategoryId] = useState();
  const [step, setStep] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  console.log("USER CONNECTED LICENCE", userLicence);

  const handleSubscription = (id, name) => {
    gaEventTracker(`${name}`);
    navigate({
      pathname: LOGIN_PAGE,
      search: `?id=${id}&&type=${"cloud"}&&name=${name}`,
    });
  };

  function handlePayment(id) {
    //gaEventTracker(`${name}`)
    dispatch({ type: GO_PAYMENT, payload: { licenseCategoryId: id } });
    console.log("OK---->");
    //setLicenceCategoryId(id);
    //setStep(true);
  }
  async function handleUpgradeSubscription() {
    let res = await updateLicense(companyId, licenceCategoryId);

    if (res.status === success) {
      console.log(res.data);
    } else {
      console.log(res);
    }
  }

  function fetchFunc(pricing) {
    console.log("PRICING", pricing);
    if (pricing) {
      for (let index = 0; index < pricing.length; index++) {
        const prev = pricing[index - 1];
        console.log("prev", prev);
        const curr = pricing[index];
        console.log("curr", curr);
        let prevFunc = [];
        prev?.functionalities.map((item) =>
          prevFunc.push(item?.functionality?.functionalityName)
        );
        let currFunc = [];

        curr?.functionalities.map((item) => {
          console.log(
            "Boolean",
            prevFunc.includes(item?.functionality?.functionalityName)
          );
          if (
            prevFunc.includes(item?.functionality?.functionalityName) == false
          ) {
            currFunc.push(item?.functionality?.functionalityName);
          }
        });

        prev && currFunc?.unshift(prev?.licenseCategoryName);
        console.log(currFunc);
        console.log("Old pricing", pricing[index].functionalities);
        pricing[index] = { ...pricing[index], functions: currFunc };
        console.log("New pricing", pricing[index].functions);
      }
      return pricing;
    }
  }

  useEffect(() => {
    //setData(fetchFunc(pricing))
  }, []);

  return (
    <IconContext.Provider value={{ color: "#a9b3c1", size: "1rem" }}>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 h-full"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-800 bg-opacity-40" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 min-w-[80vw]">
                  <div className="float-right pt-3 pr-3">
                    <button onClick={() => setOpen(false)}>
                      <svg
                        class="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                    </button>
                  </div>

                  <div className="flex flex-col items-center w-full h-full px-10 pb-10 bg-white">
                    <h4 className="text-xl font-extrabold text-gray-600 uppercase">
                      Tableau de comparaison
                    </h4>
                    <table className="w-full table-auto">
                      <thead className="border-gray-300 border-b-1">
                        <th className="p-5 font-light text-gray-700">Plans</th>
                        <th className="p-5 text-xl font-extrabold text-gray-600 ">
                          Trial
                        </th>
                        <th className="p-5 text-xl font-extrabold text-gray-600 ">
                          Standard
                        </th>
                        <th className="p-5 text-xl font-extrabold text-gray-600 ">
                          Premium
                        </th>
                        <th className="p-5 text-xl font-extrabold text-gray-600 ">
                          Business
                        </th>
                      </thead>
                      <tbody>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">Prix</td>
                          <td className="px-5 py-3 text-sm">Gratuit</td>
                          <td className="px-5 py-3 text-sm">
                            A partir de 5 999 XOF / mois
                          </td>
                          <td className="px-5 py-3 text-sm">
                            A partir de 10 999 XOF / mois
                          </td>
                          <td className="px-5 py-3 text-sm">
                            A partir de 14 999 XOF / mois
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td
                            colSpan={5}
                            className="px-5 py-3 text-sm font-semibold bg-gray-100 rounded-full"
                          >
                            Fonctionnalités
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">
                            Gestion de stock
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">Vente</td>
                          <td className="px-5 py-3 text-sm">
                            {/*<HiMinus color='gray' size={20} />*/}
                            Cinquante(50) / mois
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>

                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">
                            Gestion des objectifs journaliers
                          </td>
                          <td className="px-5 py-3 text-sm">
                            deux (02) mois d'essai
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">
                            Gestion des dépenses
                          </td>
                          <td className="px-5 py-3 text-sm">
                            deux (02) mois d'essai
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">
                            Gestion et analyse des rapports et statistiques
                          </td>
                          <td className="px-5 py-3 text-sm">
                            deux (02) mois d'essai
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">
                            Gestion des rentabilités ou du profit
                          </td>
                          <td className="px-5 py-3 text-sm">
                            deux (02) mois d'essai
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">
                            Gestion des promotions
                          </td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">
                            Gestion des fidélisations
                          </td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">
                            Gestion des alertes
                          </td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">
                            Gestion des taxes
                          </td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">
                            Pas de déclaration de l'état fiscal
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">
                            Gestion des clients
                          </td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">
                            Pas de relance des clients
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">
                            Prévision des ventes
                          </td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">
                            Gestion et suggestion des techniques de vente
                          </td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">
                            Assistance à la fixation des prix
                          </td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">
                            Prévision des ventes
                          </td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">
                            Gestion des fournisseurs
                          </td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">
                            Pas de suivi et évaluation
                            <br /> des fournisseurs
                          </td>
                          <td className="px-5 py-3 text-sm">
                            Pas de suivi et évaluation
                            <br /> des fournisseurs
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">QR code</td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td
                            colSpan={5}
                            className="px-5 py-3 text-sm font-semibold bg-gray-100 rounded-full"
                          >
                            Utilisateurs
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">Administrateur</td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">Manager</td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">Employés</td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td
                            colSpan={5}
                            className="px-5 py-3 text-sm font-semibold bg-gray-100 rounded-full"
                          >
                            Méthodes de paiement
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">Espèce</td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">Orange money</td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">Remise</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">Une (01) / mois</td>
                          <td className="px-5 py-3 text-sm">
                            Nombre illimité.
                            <br />
                            Pas d'automatisation de rabais
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">Gift card</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">
                            Trois (03) / mois
                          </td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">Crédit</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">
                            <BsCheck2 color="green" size={20} />
                          </td>
                        </tr>

                        <tr className="border-gray-300 border-y-1">
                          <td
                            colSpan={5}
                            className="px-5 py-3 text-sm font-semibold bg-gray-100 rounded-full"
                          >
                            Matériels et outils
                          </td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">E-ordinateur</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">A la demande</td>
                          <td className="px-5 py-3 text-sm">A la demande</td>
                          <td className="px-5 py-3 text-sm">A la demande</td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">E-téléphone</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">A la demande</td>
                          <td className="px-5 py-3 text-sm">A la demande</td>
                          <td className="px-5 py-3 text-sm">A la demande</td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">Serveur</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">A la demande</td>
                          <td className="px-5 py-3 text-sm">A la demande</td>
                          <td className="px-5 py-3 text-sm">A la demande</td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">Routeur</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">A la demande</td>
                          <td className="px-5 py-3 text-sm">A la demande</td>
                          <td className="px-5 py-3 text-sm">A la demande</td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">Imprimante</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">A la demande</td>
                          <td className="px-5 py-3 text-sm">A la demande</td>
                          <td className="px-5 py-3 text-sm">A la demande</td>
                        </tr>
                        <tr className="border-gray-300 border-y-1">
                          <td className="px-5 py-3 text-sm">Scanner</td>
                          <td className="px-5 py-3 text-sm">Non</td>
                          <td className="px-5 py-3 text-sm">A la demande</td>
                          <td className="px-5 py-3 text-sm">A la demande</td>
                          <td className="px-5 py-3 text-sm">A la demande</td>
                        </tr>
                      </tbody>
                    </table>
                    <button
                      onClick={() => setOpen(false)}
                      className="px-10 py-2 my-2 text-gray-600 underline border-2 border-gray-300 rounded-full text-md"
                    >
                      Fermer
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <PricingSection id="pricing">
        <PricingWrapper>
          <Heading>Nos souscriptions</Heading>
          <PricingContainer className="duration-500">
            {userLicence?.licensePrice !== 0 && !props.resubscribe && (
              <PricingCard>
                <PricingCardInfo className="bg-white border-2 border-gray-100 px-7">
                  <PricingCardPlan className="font-semibold text-gray-800">
                    Trial
                  </PricingCardPlan>
                  <p className="my-4 text-4xl font-extrabold text-gray-600 uppercase py-3.5">
                    Gratuit
                  </p>
                  <button
                    className="py-2 font-bold bg-orange-700 w-44"
                    onClick={() =>
                      !login ? handleSubscription(1, "Trial") : handlePayment(1)
                    }
                  >
                    {props?.resubscribe ? "Ré-abonnner" : "Souscrire"}
                  </button>
                  <div className="flex flex-col items-start w-full mt-2 pl-7">
                    <p className="my-1 text-sm font-normal text-gray-600">
                      Nombre de vente :{" "}
                      <span className="font-semibold">50 / mois</span>
                    </p>
                    <p className="text-sm font-semibold text-gray-600">
                      Méthodes de paiement
                    </p>
                    <ul className="flex flex-col font-normal text-left text-gray-500 ">
                      <li className="flex flex-row items-center space-x-2 text-sm">
                        <BsCheck color="gray" />
                        <p>pas de gift cards</p>
                      </li>
                      <li className="flex flex-row items-center space-x-2 text-sm">
                        <BsCheck color="gray" />
                        <p>pas de credit</p>
                      </li>
                      <li className="flex flex-row items-center space-x-2 text-sm">
                        <BsCheck color="gray" />
                        <p>pas de remise</p>
                      </li>
                    </ul>
                  </div>

                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des objectifs journaliers :{" "}
                    <span className="font-normal">02 mois d'essai</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion et analyse des rapports et statistiques :{" "}
                    <span className="font-normal">02 mois d'essai</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des dépenses :{" "}
                    <span className="font-normal">02 mois d'essai</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des rentabilités ou du profit :{" "}
                    <span className="font-normal">02 mois d'essai</span>
                  </p>
                </PricingCardInfo>
              </PricingCard>
            )}

            {userLicence?.licensePrice !== 5999 && (
              <PricingCard>
                <PricingCardInfo className="bg-white border-2 border-gray-100 px-7">
                  <PricingCardPlan className="font-semibold text-gray-800 ">
                    Standard
                  </PricingCardPlan>

                  <div className="flex flex-col my-1">
                    {/* <p className="text-lg font-bold text-gray-600">
                      A partir de
                    </p> */}
                    <p className="my-3 text-4xl font-extrabold text-gray-600 uppercase ">
                      5 999 XOF
                    </p>
                    <p className="font-bold text-gray-600 text-md">/ mois</p>
                  </div>

                  <button
                    className="py-2 font-bold bg-orange-700 w-44"
                    onClick={() =>
                      !login
                        ? handleSubscription(5, "Standard")
                        : handlePayment(5)
                    }
                  >
                    {props?.resubscribe ? "Ré-abonnner" : "Souscrire"}
                  </button>

                  <div className="flex flex-col items-start w-full pl-10 mt-2">
                    <p className="my-1 text-sm font-normal text-gray-600">
                      Nombre de vente :{" "}
                      <span className="font-semibold">illimité</span>
                    </p>
                    <p className="text-sm font-semibold text-gray-600">
                      Méthodes de paiement
                    </p>
                    <ul className="flex flex-col font-normal text-left text-gray-500 ">
                      <li className="flex flex-row items-center space-x-2 text-sm">
                        <BsCheck color="gray" />
                        <p>pas de gift cards</p>
                      </li>
                      <li className="flex flex-row items-center space-x-2 text-sm">
                        <BsCheck color="gray" />
                        <p>pas de credit</p>
                      </li>
                      <li className="flex flex-row items-center space-x-2 text-sm">
                        <BsCheck color="gray" />
                        <p>remise (limité)</p>
                      </li>
                    </ul>

                    <p className="mt-2 text-sm font-semibold text-gray-600">
                      Matériels au choix
                    </p>
                    <ul className="flex flex-col font-normal text-left text-gray-500">
                      <li className="flex flex-row items-center space-x-2 text-sm">
                        <p>E-ordinateur :</p>
                        <span className="font-semibold">01</span>
                      </li>
                      <li className="flex flex-row items-center space-x-2 text-sm">
                        <p>E-téléphone :</p>
                        <span className="font-semibold">01</span>
                      </li>
                    </ul>
                  </div>

                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des objectifs journaliers :{" "}
                    <span className="font-semibold">illimitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion et analyse des rapports et statistiques :{" "}
                    <span className="font-semibold">illimitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des dépenses :{" "}
                    <span className="font-semibold">illimitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des rentabilités ou du profit :{" "}
                    <span className="font-semibold">illimitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des fournisseurs :{" "}
                    <span className="font-semibold">limitée</span>
                  </p>
                </PricingCardInfo>
              </PricingCard>
            )}

            {userLicence?.licensePrice !== 10999 && (
              <PricingCard>
                <PricingCardInfo className="bg-white border-2 border-gray-100 px-7">
                  <PricingCardPlan className="font-semibold text-gray-800">
                    Premium
                  </PricingCardPlan>

                  <div className="flex flex-col my-1">
                    {/* <p className="text-lg font-bold text-gray-600">
                      A partir de
                    </p>{" "} */}
                    <p className="my-3 text-3xl font-extrabold text-gray-600 uppercase">
                      10 999 XOF
                    </p>
                    <p className="font-bold text-gray-600 text-md">/ mois</p>
                  </div>

                  <button
                    onClick={() =>
                      !login
                        ? handleSubscription(8, "Premium")
                        : handlePayment(8)
                    }
                    className="py-2 font-bold bg-orange-700 w-44"
                  >
                    {props?.resubscribe ? "Ré-abonnner" : "Souscrire"}
                  </button>

                  <div className="flex flex-col items-start w-full mt-2 pl-7">
                    <p className="my-1 text-sm font-normal text-gray-600">
                      Nombre de vente :{" "}
                      <span className="font-semibold">illimité</span>
                    </p>
                    <p className="text-sm font-semibold text-gray-600">
                      Méthodes de paiement
                    </p>
                    <ul className="flex flex-col font-normal text-left text-gray-500 ">
                      <li className="flex flex-row items-center space-x-2 text-sm">
                        <BsCheck color="gray" />
                        <p>gift cards (limité)</p>
                      </li>
                      <li className="flex flex-row items-center space-x-2 text-sm">
                        <BsCheck color="gray" />
                        <p>pas de credit</p>
                      </li>
                      <li className="flex flex-row items-center space-x-2 text-sm">
                        <BsCheck color="gray" />
                        <p>remise (limité)</p>
                      </li>
                    </ul>
                    <p className="mt-2 text-sm font-semibold text-gray-600">
                      Matériels au choix
                    </p>
                    <ul className="flex flex-col font-normal text-left text-gray-500">
                      <li className="flex flex-row items-center space-x-2 text-sm">
                        <p>E-ordinateur :</p>
                        <span className="font-semibold">01</span>
                      </li>
                      <li className="flex flex-row items-center space-x-2 text-sm">
                        <p>E-téléphone :</p>
                        <span className="font-semibold">01</span>
                      </li>
                    </ul>
                  </div>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des objectifs journaliers :{" "}
                    <span className="font-semibold">illimitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion et analyse des rapports et statistiques :{" "}
                    <span className="font-semibold ">illimitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des dépenses :{" "}
                    <span className="font-semibold">illimitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des rentabilités ou du profit :{" "}
                    <span className="font-semibold">illimitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des fournisseurs :{" "}
                    <span className="font-semibold">limitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des promotions :{" "}
                    <span className="font-semibold">illimitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des fidélisations :{" "}
                    <span className="font-semibold">illimitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des alertes :{" "}
                    <span className="font-semibold">illimitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des taxes :{" "}
                    <span className="font-semibold">limitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des clients :{" "}
                    <span className="font-semibold">limitée</span>
                  </p>
                </PricingCardInfo>
              </PricingCard>
            )}

            {userLicence?.licensePrice !== 14999 && (
              <PricingCard>
                <PricingCardInfo className="bg-white border-2 border-gray-100 px-7">
                  <PricingCardPlan className="font-semibold text-gray-800">
                    Business
                  </PricingCardPlan>

                  <div className="flex flex-col my-1">
                    {/* <p className="text-lg font-bold text-gray-600">
                      A partir de
                    </p>{" "} */}
                    <p className="my-3 text-3xl font-extrabold text-gray-600 uppercase">
                      14 999 XOF
                    </p>
                    <p className="font-bold text-gray-600 text-md">/ mois</p>
                  </div>

                  <button
                    className="py-2 font-bold bg-orange-700 w-44"
                    onClick={() =>
                      !login
                        ? handleSubscription(2, "Business")
                        : handlePayment(2)
                    }
                  >
                    {props?.resubscribe ? "Ré-abonnner" : "Souscrire"}
                  </button>

                  <div className="flex flex-col items-start w-full mt-2 pl-7">
                    <p className="my-1 text-sm font-normal text-gray-600">
                      Nombre de vente :{" "}
                      <span className="font-semibold">illimité</span>
                    </p>
                    <p className="text-sm font-semibold text-gray-600">
                      Méthodes de paiement
                    </p>
                    <ul className="flex flex-col font-normal text-left text-gray-500 ">
                      <li className="flex flex-row items-center space-x-2 text-sm">
                        <BsCheck color="gray" />
                        <p>gift cards</p>
                      </li>
                      <li className="flex flex-row items-center space-x-2 text-sm">
                        <BsCheck color="gray" />
                        <p>credit</p>
                      </li>
                      <li className="flex flex-row items-center space-x-2 text-sm">
                        <BsCheck color="gray" />
                        <p>remise</p>
                      </li>
                    </ul>
                    <p className="mt-2 text-sm font-semibold text-gray-600">
                      Matériels au choix
                    </p>
                    <ul className="flex flex-col font-normal text-left text-gray-500">
                      <li className="flex flex-row items-center space-x-2 text-sm">
                        <p>E-ordinateur :</p>
                        <span className="font-semibold">01</span>
                      </li>
                      <li className="flex flex-row items-center space-x-2 text-sm">
                        <p>E-téléphone :</p>
                        <span className="font-semibold">01</span>
                      </li>
                    </ul>
                  </div>

                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des objectifs journaliers :{" "}
                    <span className="font-semibold">illimitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion et analyse des rapports et statistiques :{" "}
                    <span className="font-semibold">illimitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des dépenses :{" "}
                    <span className="font-semibold">illimitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des rentabilités ou du profit :{" "}
                    <span className="font-semibold">illimitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des fournisseurs :{" "}
                    <span className="font-semibold">illimitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des promotions :{" "}
                    <span className="font-semibold">illimitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des fidélisations :{" "}
                    <span className="font-semibold">illimitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des alertes :{" "}
                    <span className="font-semibold">illimitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des taxes :{" "}
                    <span className="font-semibold">illimitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion des clients :{" "}
                    <span className="font-semibold">illimitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Prévisions des ventes :{" "}
                    <span className="font-semibold">illimitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Assistance à la fixation des prix :{" "}
                    <span className="font-semibold">illimitée</span>
                  </p>
                  <p className="mt-3 text-sm font-normal text-left text-gray-500">
                    Gestion et suggestion des techniques de vente :{" "}
                    <span className="font-semibold">illimitée</span>
                  </p>
                </PricingCardInfo>
              </PricingCard>
            )}
          </PricingContainer>
        </PricingWrapper>
      </PricingSection>

      <div className="text-center">
        <button
          onClick={() => setOpen(true)}
          className="p-4 mb-5 font-semibold text-gray-600 border-2 border-orange-600 rounded-full hover:underline"
        >
          Voir la comparaison
        </button>
      </div>
    </IconContext.Provider>
  );
};
export default PricingCloud;
