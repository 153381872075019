import './App.css';
import React from 'react';
import {
  BrowserRouter as Router, Link, Outlet
} from "react-router-dom";
import SiteRoutes from './navigation/SiteRoutes';
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import { PersistGate } from 'redux-persist/integration/react';
import { allReducers } from './redux/index';
import { persistor, store } from './redux/store';

import ReactGA from 'react-ga';
import { useEffect } from 'react';

//const store = createStore(allReducers, applyMiddleware(thunk));

function App() {
  const TRACKING_ID = "UA-211996035-3"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  
  console.log(window.location.pathname + window.location.search);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <div className=''>
            <SiteRoutes />
          </div>
        </Router>
      </PersistGate>
    </Provider>

  );
}

export default App;
