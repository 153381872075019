const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const GO_PAYMENT = "GO_PAYMENT";
const BACK_SOUS = "BACK_SOUS";
const SET_LICENCE = "SET_LICENCE";
const RESET_PASSWORD = "RESET_PASSWORD";
const MAIL_VERIF = "MAIL_VERIF";
const NO_MAIL_VERIF = "NO_MAIL_VERIF";
const IP = 'IP';

export { LOGIN, LOGOUT, IP, GO_PAYMENT, BACK_SOUS, SET_LICENCE, RESET_PASSWORD, MAIL_VERIF, NO_MAIL_VERIF };