import axios from "axios";

export  async function axiosFiles() {
    const headers = {
        'Content-Type': 'multipart/form-data; boundary=------WebKitFormBoundary2lZSUsxEA3X5jpYD',
    };
    const axiosInstance = axios.create();
    return axiosInstance;
}

export async function axiosBasic() {
    const axiosInstance = axios.create();
    return axiosInstance;
}

