import { Menu, Dialog, Transition } from "@headlessui/react";
import React, { useRef, useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { success } from "../../api/config";
import { findMyLicense, findMyStores } from "../../api/licenses/license";
import { getProfil } from "../../api/users/users";
import "./MyProfile.css";
import OtpInput from "react-otp-input";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { Alert } from "@mui/material";
import { checkEmailRequest, validateOtpRequest } from "../../api/utils/utils";
import Swal from "sweetalert2";

export default function MyProfile() {
  const [user, setUser] = useState();
  const role = useSelector((state) => state.login.role);
  const companyId = useSelector((state) => state.login.companyId);
  const [stores, setStores] = useState([]);
  const [license, setLicense] = useState();
  //console.log("Store", stores);
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();
  const [codeOtp, setCodeOtp] = useState("");
  const [active, setActive] = useState("OM");
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setActiveStep(0);
    setIsSuccess(false);
    setOpen(false);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [userError, setUserError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableRetry, setDisableRetry] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = async () => setActiveStep((prev) => prev + 1);
  const handleBack = async () =>
    setActiveStep((prev) => (prev > 0 ? prev - 1 : prev));

  const handleChange = (otp) => {
    setCodeOtp(otp);
    console.log(otp);
  };

  function handlePaymentMethod(choose) {
    setActive(choose);
    console.log(active);
  }
  async function fetchProfile() {
    let result = await getProfil();
    console.log("User", result);
    if (result) {
      setUser(result);
    }
  }

  async function fetchMyLicences(id) {
    let res = await findMyLicense(id);
    console.log("license", res);
    if (res?.status == success) {
      setLicense(res?.data);
    }
  }

  async function fetchMyStores(id) {
    let rs = await findMyStores(id);
    console.log("stores", rs);
    if (rs?.status == success) {
      setStores(rs?.data);
    } else {
      Swal.fire({
        icon: "error",
        title: "Echec de la vérification",
        text: `Cet otp n'est pas valide.`,
      });
    }
  }

  async function checkTheEmail() {
    setIsSuccess(false);
    setLoading(true);
    let result = await checkEmailRequest(user?.email);
    console.log("CHECK...", result);
    if (result?.status == success) {
      setLoading(false);
      setIsSuccess(true);
      retryFunction()
    }
    setLoading(false);
  }

  async function checkTheOtp() {
    setIsSuccess(false);
    setLoading(true);
    let result = await validateOtpRequest(user?.email, codeOtp);
    console.log("CHECK...", result);
    if (result?.status == success) {
      setLoading(false);
      Swal.fire({
        icon: "success",
        title: "Vérification réussie",
        text: `Mail confirmé.`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          handleClose()
          fetchProfile()
        } 
      })
    } else {
      // console.log(result?.data?.response);
      Swal.fire({
        icon: "error",
        title: "Echec de la vérification",
        text: `${result?.data?.response?.data}.`,
      });
    }
    setLoading(false);
  }

  async function retryFunction() {
    setDisableRetry(true);
    setTimeout(() => {
      setDisableRetry(false);
    }, 50000);
  }

  useEffect(() => {
    fetchProfile();
    fetchMyLicences(companyId);
    fetchMyStores(companyId);
  }, []);

  const renderStore = (item) => {
    return (
      <li class="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
        <div class="flex w-0 flex-1 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
            />
          </svg>
          <span class="ml-2 w-0 flex-1 truncate">
            {item?.storeName + ", " + item?.storeAddress}
          </span>
        </div>
        <div class="ml-4 flex-shrink-0">
          {item.storeStatus ? (
            <span class="bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded">
              Activé
            </span>
          ) : (
            <span class="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded">
              Désactivé
            </span>
          )}
        </div>
      </li>
    );
  };

  return (
    <div class="bg-white rounded-lg p-5 pt-24 sm:p-24 shadow-md">
      <div className="flex justify-end my-5 mr-2">
        <a
          href="https://admin.bangwelegre.com"
          className="flex items-center justify-center space-x-2 outline-none group"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 group-hover:text-blue-800"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z"
            />
          </svg>
          <span className="underline group-hover:text-blue-800">
            Configurer votre boutique
          </span>
        </a>
      </div>
      <div class="px-4 py-5 sm:px-6 bg-orange-500 rounded-t-lg flex justify-between">
        <div>
          <h3 class="text-lg font-semibold leading-6 text-gray-50">
            Informations sur le profile
          </h3>
          <p class="mt-1 max-w-2xl text-sm font-medium text-gray-200">
            Informations personnelles
          </p>
        </div>
        {/*<button
                    className="flex items-center justify-center px-3 py-1 space-x-2 text-white bg-blue-700 rounded-md shadow-md cursor-pointer"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                    </svg>
                    <p className="">Editer</p>
                </button>*/}
      </div>
      <div class="border-t border-gray-200">
        <dl>
          <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Nom complet</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {user?.lname + " " + user?.fname}
            </dd>
          </div>
          <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Role</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {role}
            </dd>
          </div>
          <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Email</dt>
            <dd
              class={` ${
                user?.validated ? " bg-emerald-50 " : " bg-red-50 "
              } rounded-xl px-2 mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex justify-between items-center `}
            >
              <span
                class={`flex items-center w-auto flex-1 truncate py-2 rounded-md`}
              >
                {user?.email}
                {user?.validated ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 ml-2 text-emerald-600"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 ml-2 text-red-600"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                )}
              </span>
              {!user?.validated ? (
                <button
                  className="text-normalray-500 font-n hover:underline hover:text-red-400"
                  onClick={handleOpen}
                >
                  Vérifier mon email
                </button>
              ) : null}
            </dd>
          </div>

          <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <div className="flex justify-between mr-2">
              <dt class="text-sm font-medium text-gray-500">Ma licence</dt>
            </div>

            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <div class="divide-y divide-gray-200 rounded-md border border-gray-200">
                <div class="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                  <div class="flex w-0 flex-1 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z"
                      />
                    </svg>
                    <span class="ml-2 w-0 flex-1 truncate">
                      {license?.licenseCategoryName +
                        ", " +
                        license?.licensePrice}
                    </span>
                  </div>
                  {
                    <div class="ml-4 flex-shrink-0">
                      <span
                        class={`${
                          license?.validityInDays > 30 ||
                          license?.validityInDays == -1
                            ? "bg-green-300"
                            : "bg-red-300"
                        } ml-2 w-0 flex-1 truncate py-1 px-2 shadow-md rounded-md`}
                      >
                        {license?.validityInDays == -1
                          ? "Illimité"
                          : `Expire dans ${license?.validityInDays} jours`}
                      </span>
                    </div>
                  }
                </div>
              </div>
            </dd>
          </div>

          <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <div className="flex justify-between mr-2">
              <dt class="text-sm font-medium text-gray-500">Mes boutiques</dt>
            </div>

            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <ul
                role="list"
                class="divide-y divide-gray-200 rounded-md border border-gray-200"
              >
                {stores && stores.length != 0 ? (
                  stores.map((item) => renderStore(item))
                ) : (
                  <p className="text-sm font-semibold uppercase">
                    Vous n'avez pas encore enrégistré de boutique. Cliquer{" "}
                    <a href="https://admin.bangwelegre.com">ici</a> pour en
                    créer
                  </p>
                )}
              </ul>
            </dd>
          </div>
        </dl>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 "
          initialFocus={cancelButtonRef}
          onClose={handleClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center h-full p-4 overflow-y-scroll text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative Popup transform rounded-sm bg-white text-left shadow-xl transition-all sm:my-8 w-[650px] h-auto">
                  <div className="fixed z-50 w-full px-5 py-4 text-center bg-blue-800 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 text-center text-white"
                    >
                      Vérifier mon e-mail
                    </Dialog.Title>
                    <button
                      className="float-right -mt-6 text-white"
                      onClick={handleClose}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  <div className="w-full h-auto px-20 pt-24 pb-10">
                    {activeStep === 0 ? (
                      <div>
                        <div className="mt-4 space-y-6 ">
                          <Alert severity="warning">
                            Les champs avec la mention (*) sont obligatoires
                          </Alert>
                          <div className="space-y-3 shadow-sm columns-1 ">
                            <div className="flex flex-col space-y-1 rounded-md">
                              <label className="px-2 font-normal text-left text-md">
                                Email <span className="text-red-500">*</span>
                              </label>
                              <input
                                name="userEmail"
                                type="email"
                                disabled={true}
                                className="relative w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none disabled:placeholder-gray-700 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="Email"
                                value={user?.email}
                              />
                            </div>
                          </div>

                          <button
                            disabled={disableRetry}
                            className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
                              loading
                                ? " bg-blue-700 hover:bg-blue-800 "
                                : " bg-blue-600 hover:bg-blue-700 "
                            }focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 `}
                            onClick={() => checkTheEmail()}
                          >
                            {!loading ? (
                              isSuccess ? (
                                <span>Renvoyer le mail</span>
                              ) : (
                                <span>Vérifier l'adresse électronique</span>
                              )
                            ) : (
                              <ButtonLoading />
                            )}
                          </button>
                        </div>

                        {isSuccess ? (
                          <div>
                            <div className="flex flex-col items-center justify-center">
                              <img
                                width={150}
                                src={require("../../assets/gif/58201-success-tick.gif")}
                                alt={"Verification reussie"}
                              />
                              <h4 className="px-5 font-semibold text-gray-600">
                                L'email a été envoyé avec succès, veuillez
                                vérifier votre boite mail.
                              </h4>
                            </div>

                            <div className="flex justify-end">
                              <button
                                type="button"
                                className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm hover:bg-blue-600 hover:text-white"
                                onClick={() => handleNext()}
                              >
                                Suivant
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div className="space-y-3 columns-1 md:space-y-2 sm:columns-2 md:columns-1 lg:columns-1 xl:columns-1 2xl:columns-1">
                        <Alert severity="warning">
                          Les champs avec la mention (*) sont obligatoires
                        </Alert>
                        <h4 className="font-semibold text-gray-500">
                          Vérifier votre adresse électronique pour le code otp.
                        </h4>
                        <div className="flex flex-col space-y-1">
                          <label className="px-5 text-sm font-normal text-left text-gray-500">
                            OTP <span className="text-red-500">*</span>
                          </label>
                          <OtpInput
                            value={codeOtp}
                            onChange={(e) => handleChange(e)}
                            numInputs={6}
                            separator={<span>-</span>}
                            shouldAutoFocus
                            isInputNum={false}
                            isInputSecure={false}
                            inputStyle="inputStyle"
                          />
                          {!codeOtp && (
                            <span className="ml-4 text-sm text-left text-red-500">
                              Ce champ est obligatoire
                            </span>
                          )}
                        </div>

                        <div className="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6">
                          <button
                            type="button"
                            className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={() => checkTheOtp()}
                          >
                            Valider
                          </button>
                          <button
                            type="button"
                            className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={() => handleBack()}
                          >
                            Retour
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <div className='flex flex-col items-center mt-20'>
                                                <img className='animate-pulse w-96' src={require("../../assets/img/undraw_Freelancer_re_irh4.png")} />
                                                <h4 className='text-3xl font-bold'>Coming soon <span className='animate-pulse'>...</span></h4>
                                            </div> */}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
