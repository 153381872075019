import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { allReducers } from ".";

const rootPersistConfig = {
    key: 'root',
    storage,
  }

const persistedReducer = persistReducer(rootPersistConfig, allReducers)


export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
    devTools: process.env.NODE_ENV !== 'production',
    //enhancers: [devToolsEnhancer({trace: true, traceLimit: 25,realtime: true, port: 8000 })],
    
});

export const persistor = persistStore(store)