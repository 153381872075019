import {
  BACK_SOUS,
  GO_PAYMENT,
  IP,
  LOGIN,
  LOGOUT,
  MAIL_VERIF,
  NO_MAIL_VERIF,
  RESET_PASSWORD,
  SET_LICENCE,
} from "./loginActions";

const initialState = {
  id: 0,
  userName: "",
  isLogged: false,
  role: "",
  storeName: "",
  storeId: "",
  token: "",
  expiresIn: "",
  refreshExpiresIn: "",
  refreshToken: "",
  tokenType: "",
  idToken: null,
  notBeforePolicy: 0,
  sessionState: "",
  scope: "",
  otherClaims: {},
  status: false,
  companyName: "",
  companyId: 0,
  licenseCategoryId: 0,
  stepSouscription: false,
  permissions: [],
  stores: [],
  userLicence: {},
  emailToReset: "",
  mailVerify: false,
  ip: "",
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      console.log(action?.payload);
      return { ...state, isLogged: true, ...action?.payload };

    case LOGOUT:
      return initialState;

    case IP:
      return { ...state, ip: action.payload };

    case GO_PAYMENT:
      return {
        ...state,
        stepSouscription: true,
        licenseCategoryId: action.payload.licenseCategoryId,
      };

    case BACK_SOUS:
      return { ...state, stepSouscription: false };

    case SET_LICENCE:
      console.log(action?.payload);
      return { ...state, userLicence: action?.payload };

    case RESET_PASSWORD:
      console.log(action?.payload);
      return { ...state, emailToReset: action?.payload };

    case MAIL_VERIF:
      return { ...state, mailVerify: true };

    case NO_MAIL_VERIF:
      return { ...state, mailVerify: false };

    default:
      return state;
  }
};
