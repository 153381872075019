import React from "react";
import './Presentation.css';

export default function Presentation() {

    return (
        <div className='w-full h-auto mt-20'>
            {/* Container */}
            {/*<div className='max-w-[1200px] mx-auto px-0 sm:px-8 flex flex-col justify-center h-full space-y-8'>
                <h1 className='text-5xl sm:text-5xl font-normal text-gray-800 pt-10'>
                    Présentation
                </h1>
                <div>
                    <iframe className="w-full h-md p-2 aspect-video" src="https://www.youtube.com/embed/_5_CVwHa2F4" title="Bãng welegre presentation" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>*/}
            <video loop autoplay='true' muted>
                <source src={require("../../assets/movie/bang-welegre.mp4")} type="video/mp4" />
            </video>
        </div>
    )
}