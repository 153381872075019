import React from 'react';
import './HomeScreen.css';
import Home from '../../components/Home/Home';
import Footer from '../../components/Footer/Footer';
import GlobalStyle from '../../globalStyles';
import Navbar from '../../components/NavBar/NavBar';
import Features from '../../components/Features/Features';
import { Content } from '../../components/Content/Content';
import { heroOne, heroTwo, heroThree } from '../../data/HeroData';
import Carousel from '../../components/Carousel/Carousel';
import TemoignageCarousel from '../../components/TemoignageCarousel/TemoignageCarousel';
import Presentation from '../../components/Presentation/Presentation';
import PresentationBang from '../../components/Presentation/PresentationBang';
import PresentationCarousel from '../../components/Presentation/PresentationCarousel';

const HomeScreen = () => {

  return (
   <div className='bg-gray-200 flex flex-col'>
      <GlobalStyle />
      <Navbar />
      {/*<PresentationCarousel />*/}
      <Presentation />
      {/*<PresentationBang />*/}
      <Home />
      <Features />
      <Content {...heroOne} />
      <Content {...heroTwo} />
      <Content {...heroThree} />
      {/*<Carousel />*/}
      {/*<TemoignageCarousel />*/}
      <Footer />
    </div>
  );
};

export default HomeScreen;
