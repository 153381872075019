import axios from "axios";


//const baseUrl = "http://3.19.107.158:65004";
// const baseUrl = "https://admin.bangwelegre.com:65004";
const baseUrl = "https://adminapi.bangwelegre.com";
//const baseUrl = "https://51d3-102-180-21-229.eu.ngrok.io"; 
const baseUrlEmail = "https://bangwelegre.com/reset-password?token="; //Update this path for send token to reset password
const userService = "users-ws/v1";
const productService = "product-ws/v1";
const deliveryService = "delivery-ws/v1";
const adminService = "admin-ws/v1"
const ITEM_PER_PAGE = 20;
const fail = "fail";
const success = "success";

function handleResponse({ data, status }) {
  let response = { data, status };
  console.log("HANDLE:::", response);
  return response;
};

async function getUserIp() {
  const res = await axios.get('https://ipapi.co/json/')
    .then(res => {
      return handleResponse({ data: res.data, status: success });
    }).catch(err => {
      return handleResponse({ data: err, status: fail });
    })
  return res;
}


export {
  baseUrl,
  baseUrlEmail,
  userService,
  productService,
  deliveryService,
  adminService,
  handleResponse,
  getUserIp,
  success,
  fail,
  ITEM_PER_PAGE
};
