import React from 'react';
import {
	FooterLinkItems,
	FooterLinkTitle,
	FooterLink,
	FooterLogo,
	SocialIcon,
	FooterRights,
	FooterWrapper,
	FooterAddress,
	FooterColumn,
	FooterGrid,
} from './FooterStyles';
import { footerData, footerSocialData } from '../../data/FooterData';
import { Row, Section } from '../../globalStyles';
import { Link } from 'react-router-dom';
import AppWebPolicies from "../../assets/docs/Bang nwélgé app and web site policies.pdf"
import AppTermsConditions from "../../assets/docs/Bang nwelgé app terms and conditions.pdf"
import WebTermsConditions from "../../assets/docs/Bang nwélgé app and web site policies.pdf"


export default function Footer() {
	function handleNav() {
		window.location.replace('https://www.nafann.com')
	}

	return (
		<Section padding="2rem 0 2rem 2rem">
			<FooterWrapper className=''>
				<FooterColumn id="footerLogo" className='ml-5'>
					<FooterLogo onClick={() => handleNav()}>
						<img className='bg-white w-16 rounded-sm p-1 border-1 border-white' src={require("../../assets/img/nafann.png")} />
						<span className='ml-2'>Nafann</span>
					</FooterLogo>
					<FooterAddress>
						Dassasgo, Ouagadougou, Burkina Faso
					</FooterAddress>
				</FooterColumn>
				<FooterGrid justify="space-between" className=''>

					{footerData.map((footerItem, index) => (
						<FooterLinkItems key={index}>
							<p className='text-white text-sm whitespace-nowrap'>{footerItem.title}</p>
							{footerItem.links.map((link, linkIndex) => (
								<Link className='text-md text-gray-300' key={linkIndex} to="/">
									{link}
								</Link>
							))}
						</FooterLinkItems>
					))}
				</FooterGrid>
				{/*<div className="flex justify-center">
					<ul class="flex flex-wrap items-center mt-10 text-md text-gray-100 sm:mt-0">
						<li>
							<a href={"Pdf"} target="_blank" className="mr-4 hover:underline md:mr-6 ">About</a>
						</li>
						<li>
							<a href={"Pdf"} target="_blank" className="mr-4 hover:underline md:mr-6 ">About</a>
						</li>
						<li>
							<a href={"Pdf"} target="_blank" className="mr-4 hover:underline md:mr-6 ">About</a>
						</li>
					</ul>
				</div>
				<FooterRights>Nafann SARL © 2022</FooterRights>	*/}
				<footer class="p-4 bg-blue-800 md:flex md:items-center md:justify-between md:p-6">
					<span class="text-sm text-white sm:text-center">© 2022 <a href="https://www.nafann.com/" className="hover:underline text-white">Nafann</a>. All Rights Reserved.
					</span>
					<ul class="flex flex-wrap items-center mt-3 text-sm text-gray-200 sm:mt-0">
						<li>
							<a href={WebTermsConditions} className="mr-4 hover:underline md:mr-6 text-white whitespace-nowrap">Website terms and conditions</a>
						</li>
						<li>
							<a href={AppTermsConditions} className="mr-4 hover:underline md:mr-6 text-white whitespace-nowrap">App terms and conditions</a>
						</li>
						<li>
							<a href={AppWebPolicies} className="mr-4 hover:underline md:mr-6 text-white whitespace-nowrap">App and website policies</a>
						</li>
					</ul>
				</footer>
			</FooterWrapper>
		</Section>
	);
}
