import { Menu, Dialog, Transition } from "@headlessui/react";
import React, { useRef, useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { success } from "../../api/config";
import {
  findLicenceById,
  findLicenseFunc,
  findMyLicense,
  findMyStores,
  updateLicense,
} from "../../api/licenses/license";
import { getProfil } from "../../api/users/users";
import PricingTabScreen from "../../pages/PricingTabScreen/PricingTabScreen";
import PricingTabUpgradeScreen from "../../pages/PricingTabScreen/PricingTabUpgradeScreen";
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";
import "./MyPlan.css";
import PaymentForm from "../../components/CreditCard/CreditCard";
import PhoneInput from "react-phone-number-input";
import OtpInput from "react-otp-input";
import { BACK_SOUS, SET_LICENCE } from "../../redux/login/loginActions";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function MyPlan() {
  const [user, setUser] = useState();
  const licenseTypes = useSelector((state) => state.pricing.licenseTypes);
  const companyId = useSelector((state) => state.login.companyId);
  const licenseCategoryId = useSelector(
    (state) => state.login.licenseCategoryId
  );
  const stepSouscription = useSelector((state) => state.login.stepSouscription);
  const [active, setActive] = useState("OM");
  const [telOtp, setTelOtp] = useState("");
  const [licence, setLicence] = useState();

  const [stores, setStores] = useState([]);
  const [funcs, setFuncs] = useState([]);
  const [license, setLicense] = useState();
  const [licenseCat, setLicenseCat] = useState();
  const gaEventTracker = useAnalyticsEventTracker("Upgrade plan");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const cancelButtonRef = useRef(null);
  //console.log("Store", stores);
  const dispatch = useDispatch();
  const [codeOtp, setCodeOtp] = useState("");

  const handleChange = (otp) => {
    setCodeOtp(otp);
    console.log(otp);
  };

  function handlePaymentMethod(choose) {
    setActive(choose);
    console.log(active);
  }
  async function fetchProfile() {
    let result = await getProfil();
    console.log("User", result);
    if (result) {
      setUser(result);
    }
  }
  //console.log("L-Types",licenseTypes);
  async function fetchMyLicences(id) {
    let res = await findMyLicense(id);
    console.log("license", res);
    if (res?.status == success) {
      setLicense(res?.data);
      dispatch({ type: SET_LICENCE, payload: res?.data });
      console.log("LicencesCatID", res?.data?.licenseCategoryId);

      let rs = await findLicenceById(res?.data?.licenseCategoryId);
      console.log("Licence-category", rs);
      if (rs?.status == success) {
        setLicenseCat(rs?.data);
      }

      let reslt = await findLicenseFunc(res?.data?.licenseCategoryId);
      console.log("functionalities", reslt);
      if (reslt?.status == success) {
        setFuncs(reslt?.data);
      }
    }
  }

  async function fetchMyStores(id) {
    let rs = await findMyStores(id);
    console.log("stores", rs);
    if (rs?.status == success) {
      setStores(rs?.data);
    }
  }

  async function handleUpgrade(name) {
    gaEventTracker(`${name}`);
  }

  useEffect(() => {
    fetchProfile();
    fetchMyLicences(companyId);
    fetchMyStores(companyId);
  }, []);

  async function handleUpgradeSubscription() {
    if (telOtp !== "" && codeOtp !== "") {
      let values = {
        companyId,
        phoneNumber: telOtp,
        otp: codeOtp,
        licenceCategoryId: licenseCategoryId,
      };

      let res = await updateLicense(values);
      console.log("SOUSCRIPTION", res);
      if (res.status === success) {
        //console.log(res.data);
        Swal.fire("Félicitations !", "Souiscription réussie", "success").then(
          (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                dispatch({ type: BACK_SOUS })
                window.location.replace("/my-package");
            }
          }
        );
      } else {
        console.log(res);
        Swal.fire({
            icon: 'error',
            title: 'Echec',
            text: 'La souscription a echouée!',
        })
      }
    }
  }

  const renderFunc = (item, index) => {
    console.log("ITEM", item.maxValue);
    return (
      <li class="flex items-start lg:col-span-1" key={index}>
        <div class="flex-shrink-0">
          <svg
            class="h-6 w-6 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            width="6"
            height="6"
            stroke="currentColor"
            fill="#10b981"
            viewBox="0 0 1792 1792"
          >
            <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
          </svg>
        </div>
        <p class="ml-3 text-sm leading-5 text-gray-700">
          {`${item.functionality?.functionalityName} ( ${
            item.maxValue === -1
              ? "Illimité"
              : item.maxValue === 0
              ? "Pas disponible"
              : item.maxValue + " jours"
          })`}
        </p>
      </li>
    );
  };

  return (
    <div class="relative pt-20 px-6 sm:px-6 lg:px-8 justify-center flex">
      <div class="pricing-box overflow-hidden lg:flex">
        <div class="bg-gray-50 py-8 lg:p-20 md:p-10 xl:p-20">
          <h3 class="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">
            Ma souscription actuelle : {license?.licenseCategoryName}
          </h3>
          <p class="mt-6 text-base leading-6 text-gray-500">
            {license?.licenseDesc}
          </p>
          <div class="mt-8">
            <div class="flex items-center">
              <h4 class="flex-shrink-0 pr-4 bg-white text-sm leading-5 tracking-wider font-semibold uppercase text-indigo-600">
                Ce qui est inclus
              </h4>
            </div>
            <ul class="mt-8 lg:grid lg:grid-cols-2 lg:col-gap-8 lg:row-gap-5">
              {funcs && funcs.map((item, index) => renderFunc(item, index))}
            </ul>
          </div>
          {/*<div class="mt-8">
                        <div class="flex items-center">
                            <h4 class="flex-shrink-0 pr-4 bg-white text-sm leading-5 tracking-wider font-semibold uppercase text-indigo-600">
                                Ce qui n'est pas inclus
                            </h4>
                        </div>
                        <ul class="mt-8 lg:grid lg:grid-cols-2 lg:col-gap-8 lg:row-gap-5">
                            <li class="flex items-start lg:col-span-1">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" class="h-6 w-6 mr-2" fill="red" viewBox="0 0 1792 1792">
                                        <path d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                        </path>
                                    </svg>
                                </div>
                                <p class="ml-3 text-sm leading-5 text-gray-700">
                                    orem ipsum dolor sit amet, consectetur adipiscing eli
                                </p>
                            </li>
                            <li class="flex items-start lg:col-span-1">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" class="h-6 w-6 mr-2" fill="red" viewBox="0 0 1792 1792">
                                        <path d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                        </path>
                                    </svg>
                                </div>
                                <p class="ml-3 text-sm leading-5 text-gray-700">
                                    orem ipsum dolor sit amet, consectetur adipiscing eli
                                </p>
                            </li>
                        </ul>
                    </div>*/}
        </div>
        <div class="py-8 px-6 text-center bg-blue-800 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
          <p class="text-lg leading-6 font-bold text-gray-300">
            Montant de la souscription
          </p>
          <div class="mt-4 flex items-center justify-center text-5xl leading-none font-extrabold text-gray-50">
            <span>
              {license?.validityInDays == -1
                ? "Gratuit et illimité"
                : `${license?.licensePrice} / ${license?.validityInDays} jours`}
            </span>
          </div>
          {/*<p class="mt-4 text-sm leading-5">
                        <span class="block font-medium text-gray-300">
                            Card payments:
                        </span>
                        <span class=" inline-block font-medium text-gray-300">
                            2.9% + 20p per transaction
                        </span>
                    </p>*/}
          {
            <div class="mt-6">
              <div class="rounded-md shadow">
                <button
                  onClick={handleOpen}
                  type="button"
                  class="py-2 px-4  bg-orange-600 hover:bg-orange-700 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md  rounded-lg "
                >
                  Changer de plan
                </button>
              </div>
            </div>
          }
          <Transition.Root show={open} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-50 "
              initialFocus={cancelButtonRef}
              onClose={handleClose}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 overflow-y-scroll">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative Popup transform rounded-sm bg-white text-left shadow-xl transition-all sm:my-8 w-[1000px] h-full">
                      <div className="text-center sm:mt-0 sm:text-left bg-blue-800 py-4 px-5 fixed z-50 w-full">
                        <Dialog.Title
                          as="h3"
                          className="text-xl text-white font-semibold leading-6 text-center"
                        >
                          Changer de souscription
                        </Dialog.Title>
                        <button
                          className="text-white float-right -mt-6"
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                      {!stepSouscription ? (
                        <div className="bg-white px-4 mt-5 pb-4 sm:p-6 sm:pb-4">
                          {licenseCat && (
                            <PricingTabUpgradeScreen
                              isResubscription={true}
                              licenseCat={licenseCat}
                              price={license?.licensePrice}
                            />
                          )}
                        </div>
                      ) : (
                        <div className="bg-white p-10 flex flex-col items-center">
                          <div className="space-y-4 mt-7 w-[500px]">
                            <h2 className="text-center text-xl font-bold text-gray-700">
                              Paiement de la souscription
                            </h2>

                            <div className="flex flex-wrap space-x-4 space-y-2 p-2 justify-center items-baseline">
                              <button
                                onClick={() => handlePaymentMethod("OM")}
                                className={`${
                                  active == "OM"
                                    ? "border-orange-700 border-4 "
                                    : "border-gray-200 "
                                } "w-40 h-28 group flex flex-col space-x-1 justify-center items-center border-2  p-2 cursor-pointer hover:bg-gray-200 rounded-md"`}
                              >
                                <img
                                  className="w-28 h-14"
                                  src={require("../../assets/img/orange-money.png")}
                                  alt="Orange money"
                                />
                                <p className="group-hover:font-semibold group-hover:underline">
                                  Orange money
                                </p>
                              </button>
                              <button
                                //onClick={() => handlePaymentMethod('MV')}
                                className={`${
                                  active == "MV"
                                    ? "border-orange-700 border-4 "
                                    : "border-gray-200 bg-gray-300 "
                                } "w-40 h-28 group flex flex-col space-x-1 justify-center items-center border-2 border-gray-200 p-2 cursor-not-allowed hover:bg-gray-200 rounded-md"`}
                              >
                                <img
                                  className="w-28 h-14 brightness-75"
                                  src={require("../../assets/img/moov-money.png")}
                                  alt="Orange money"
                                />
                                <p className="">Indisponible</p>
                              </button>
                              <button
                                //onClick={() => handlePaymentMethod('CARTE')}
                                className={`${
                                  active == "CARTE"
                                    ? "border-orange-700 border-4 "
                                    : "border-gray-200 bg-gray-300 "
                                }"w-40 h-28 group flex flex-col space-x-1 justify-center items-center border-2 border-gray-200 p-2 cursor-not-allowed hover:bg-gray-200 rounded-md"`}
                              >
                                <img
                                  className="w-28 h-14 brightness-75"
                                  src={require("../../assets/img/mastercard-visa.png")}
                                  alt="Orange money"
                                />
                                <p className="">Indisponible</p>
                              </button>
                            </div>
                            <div className="w-full h-auto p-2 ">
                              {(active == "OM" || active == "MV") && (
                                <div className="shadow-sm space-y-3 columns-1 md:space-y-2  sm:columns-2 md:columns-1 lg:columns-1 xl:columns-1 2xl:columns-1">
                                  {licence?.licensePrice && (
                                    <h5 className="text-sm">
                                      Veuillez composer{" "}
                                      <span className="font-semibold">
                                        *144*4*6*{licence?.licensePrice}#
                                      </span>{" "}
                                      pour générer le OTP
                                    </h5>
                                  )}
                                  <div className="rounded-md flex flex-col space-y-1">
                                    <label className="font-normal text-md text-left px-2">
                                      Téléphone{" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <PhoneInput
                                      className="appearance-none relative w-full px-2 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                      placeholder="Téléphone"
                                      value={telOtp}
                                      onChange={(e) => setTelOtp(e)}
                                    />
                                    {!telOtp && (
                                      <span className="text-red-500 text-sm text-left ml-4">
                                        Ce champ est obligatoire
                                      </span>
                                    )}
                                  </div>

                                  <div className="flex flex-col space-y-1">
                                    <label className="font-normal text-md text-left px-2">
                                      Code OTP{" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <OtpInput
                                      value={codeOtp}
                                      onChange={(e) => handleChange(e)}
                                      numInputs={6}
                                      separator={<span>-</span>}
                                      shouldAutoFocus
                                      isInputNum={true}
                                      isInputSecure={true}
                                      inputStyle="inputStyle"
                                    />
                                    {!codeOtp && (
                                      <span className="text-red-500 text-sm text-left ml-4">
                                        Ce champ est obligatoire
                                      </span>
                                    )}
                                  </div>
                                </div>
                              )}

                              {active == "CARTE" && (
                                <div className="shadow-sm space-y-3 columns-1 md:space-y-2  sm:columns-2 md:columns-1 lg:columns-1 xl:columns-1 2xl:columns-1">
                                  <PaymentForm />
                                </div>
                              )}
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                              <button
                                type="button"
                                className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                onClick={() => handleUpgradeSubscription()}
                              >
                                Enrégistrer
                              </button>
                              <button
                                type="button"
                                className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                onClick={() => {
                                  dispatch({ type: BACK_SOUS });
                                }}
                                ref={cancelButtonRef}
                              >
                                Retour
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* <div className='flex flex-col items-center mt-20'>
                                                <img className='animate-pulse w-96' src={require("../../assets/img/undraw_Freelancer_re_irh4.png")} />
                                                <h4 className='font-bold text-3xl'>Coming soon <span className='animate-pulse'>...</span></h4>
                                            </div> */}
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      </div>
    </div>
  );
}
