import React from "react";
import './PricingScreen.css';
import Footer from '../../components/Footer/Footer';
import GlobalStyle from '../../globalStyles';
import Navbar from '../../components/NavBar/NavBar';
import Pricing from "../../components/Pricing/Pricing";
import PricingTabScreen from "../PricingTabScreen/PricingTabScreen";
import PricingTab2 from "../PricingTabScreen/PricingTabScreen2";

export default function PricingScreen() {

    return (
        <>
            <GlobalStyle />
            <Navbar />
            <PricingTab2 />
            <Footer />
        </>
    )
}