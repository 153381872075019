import { FETCH, IP, LOGIN, LOGOUT } from "./pricingActions";

const initialState = {
    licenseTypes: []
};

export const pricingReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH:
            console.log(action?.payload);
            return { ...state, licenseTypes: action?.payload};
           
        default:
            return state;
    }
}