import Loader from "react-spinners/SyncLoader";

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

export default ({message = "", color='white'}) => (
    <div align="center" style={{}}>
        {message && <h3>{message}</h3>}
        <Loader color={color} loading={true} css={override} size={20} />
    </div>
)