export function loginAction() {
    return {
        type: "LOGIN",
        payload: true
    }
}
export function logoutAction() {
    return {
        type: "LOGOUT",
        payload: false
    }
}